import { getOutages } from '@ally/data-selectors-afg-accounts'
import { useLobs } from '..'
import { useHostServices } from '../../HostServices'

type OutageCheck = {
  isBankOutage: boolean
  isInvestOutage: boolean
  isWealthOutage: boolean
  isMortgageOutage: boolean
  isCreditCardOutage: boolean
  isAutoOutage: boolean
  isOutage: boolean
}

export const useOutageCheck = (): OutageCheck => {
  const { hostData, featureFlags } = useHostServices()

  const { bank } = useLobs()

  const isCreditCardEnabled = featureFlags.variation(
    'FF_credit-card-snapshot',
    false,
  )

  const isAutoEnabled = featureFlags.variation('FF_SPOG_WEB_UI', true)

  const isBankOutage = bank && getOutages(hostData).DEPOSIT === 'full'
  const isInvestOutage = getOutages(hostData).INVEST === 'full'
  const isWealthOutage = getOutages(hostData).WEALTH === 'full'
  const isMortgageOutage = getOutages(hostData).MORTGAGE === 'full'
  const isCreditCardOutage =
    isCreditCardEnabled && getOutages(hostData).CC === 'full'
  const isAutoOutage = isAutoEnabled && getOutages(hostData).AUTO === 'full'

  const isOutage =
    isBankOutage ||
    isInvestOutage ||
    isWealthOutage ||
    isMortgageOutage ||
    isCreditCardOutage ||
    isAutoOutage

  return {
    isBankOutage,
    isInvestOutage,
    isWealthOutage,
    isMortgageOutage,
    isCreditCardOutage,
    isAutoOutage,
    isOutage,
  }
}
