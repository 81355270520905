import { RemoteLogger } from '@ally/federated-types'
import { useEffect, useState } from 'react'
import { useHostServices } from '../../HostServices'

function callMbox(mboxName: string, log: RemoteLogger): Promise<unknown> {
  return new Promise(resolve => {
    const startTime = Date.now()
    if (!(window as any).adobe) resolve(null)
    ;(window as any).adobe.target.getOffer({
      mbox: mboxName,
      success: (successData: any) => {
        const endTime = Date.now()
        window.allytm.event('customEvent', {
          type: 'offerTiming',
          name: 'aosTargetTiming',
          attr: {
            mbox: mboxName,
            timeToLoadDecision: endTime - startTime,
            timeToLoadContent: 0,
            totalTime: endTime - startTime,
          },
        })
        resolve(successData)
      },

      error: (status: unknown, error: unknown) => {
        log.error({
          message: ['target.getOffer failure in useMboxCall', error, status],
        })
        resolve(null)
      },
    })
  })
}

export function useAuthenticatedMboxCall(
  mboxName: string,
  defaultValue: unknown,
): { mboxData: unknown } {
  const { session, log } = useHostServices()
  const { status } = session
  const [mboxData, setMboxData] = useState<unknown>(null)
  useEffect(() => {
    if (status === 'Authenticated') {
      callMbox(mboxName, log).then(v => setMboxData(v ?? defaultValue))
    }
  }, [status])
  return { mboxData }
}
