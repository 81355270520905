import React from 'react'
import styled from 'styled-components'
import { deprecated } from '@ally/metronome-icons'
import { Icon } from '@ally/metronome-ui'

const StyledIcon = styled(Icon)`
  display: inline-block;
`

const CallUsIcon: React.FC = () => (
  <StyledIcon
    icon={deprecated.muiPhoneOutline}
    fill="bluesuedeshoes"
    size="md"
  />
)

export { CallUsIcon }
