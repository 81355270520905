import { SessionData } from '@ally/federated-types'
import { FetchErrorPayload, handleResponse } from '@ally/fido'
import { DomainFetch } from '@ally/use-data-domain'
import { apigeeAPIKey } from '../constants'

type Headers = Record<string, string>
type AuthHeadersFn = (sessionData: SessionData | null) => Headers

export const defaultHeaders: Record<string, string> = {
  Accept: 'application/json',
  ApplicationId: 'ALLYUSBOLB',
  ApplicationName: 'AOB',
  ApplicationVersion: '1.0',
  'api-key': apigeeAPIKey,
  'Content-Type': 'application/json',
}

export function fetchWithAuthHeaders(getHeaders: AuthHeadersFn) {
  return <ResponseType extends FetchErrorPayload>(
    url: string,
    opts?: RequestInit,
  ) => {
    return async (
      sessionData: SessionData | null,
    ): Promise<DomainFetch<ResponseType>> => {
      const headers = {
        ...defaultHeaders,
        ...getHeaders(sessionData),
        ...(opts && opts?.headers),
      }

      const response = await fetch(url, {
        ...opts,
        headers,
      })
      const payload = await handleResponse<ResponseType>(response)

      return {
        response,
        payload,
      }
    }
  }
}

const getLegacyAuthHeaders: AuthHeadersFn = sessionData => ({
  ...(sessionData?.AwsAccessToken && {
    Authorization: `Bearer ${sessionData?.AwsAccessToken}`,
  }),
  ...(sessionData?.CSRFChallengeToken && {
    CSRFChallengeToken: sessionData?.CSRFChallengeToken,
  }),
})

export const fetchWithLegacyAuth = fetchWithAuthHeaders(getLegacyAuthHeaders)

const getTransmitAuthHeaders: AuthHeadersFn = sessionData => ({
  ...(sessionData?.access_token && {
    Authorization: `Bearer ${sessionData?.access_token}`,
  }),
})

export const fetchWithTransmitAuth = fetchWithAuthHeaders(
  getTransmitAuthHeaders,
)
