type DropdownLabelParams = {
  menuName: string
  unreadCount: number
  isOpen: boolean
}

export const dropdownTriggerAriaLabel = ({
  menuName,
  unreadCount,
  isOpen,
}: DropdownLabelParams): string => {
  if (isOpen) {
    return `Close ${menuName} Menu`
  }
  const baseString = `Open ${menuName} Menu`
  if (unreadCount === 0) return `${baseString}, there are no unread items`
  if (unreadCount === 1) return `${baseString}, there is one unread item`
  return `${baseString}, there are ${unreadCount} unread items`
}
