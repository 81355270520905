import { Account } from '@ally/data-types-afg-accounts'

export const trackCCAccount = (account: Account): void => {
  const { meta, status, type } = account
  const hasDaysDelinquent =
    meta?.delinquencyDays && parseInt(meta.delinquencyDays, 10) > 0

  ;(window as any).allytm.event('customEvent', {
    name: 'cardStatus',
    type: 'userAttribute',
    attr: {
      user: {
        cardType: type,
        ...(hasDaysDelinquent ? { daysDelinquent: meta?.delinquencyDays } : {}),
        status,
        ...(meta?.statusDescription
          ? { statusDescription: meta?.statusDescription }
          : {}),
      },
    },
  })
}
