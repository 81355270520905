import { useMemo } from 'react'
import { Account } from '@ally/data-types-afg-accounts'
import { deposit as depositS } from '@ally/data-selectors-afg-accounts'
import { getEtinStatusCode } from '@ally/data-selectors-afg-customer'
import { useHostServices } from '../../HostServices'

type EtinCertificationValues = {
  isEtinUncertified: boolean
}

export const useEtinCertification = (): EtinCertificationValues => {
  const { hostData } = useHostServices()

  const etinStatusCode = useMemo(() => getEtinStatusCode(hostData), [hostData])
  const hasInternalNonIraActiveAccounts = useMemo(() => {
    const depositAccounts: Account[] = depositS.getOpenInternal(hostData) || []
    return !!depositAccounts.filter(acct => !acct.indicator?.retirement).length
  }, [hostData])

  const isEtinUncertified = useMemo(
    () =>
      etinStatusCode?.toLowerCase() === 'non-certified' &&
      hasInternalNonIraActiveAccounts,
    [etinStatusCode, hasInternalNonIraActiveAccounts],
  )

  return { isEtinUncertified }
}
