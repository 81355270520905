import * as React from 'react'
import styled from 'styled-components'
import { deprecated } from '@ally/metronome-icons'
import { Icon, get } from '@ally/metronome-ui'
import { useShowNavTrigger } from './use-show-nav-trigger'

type TriggerProps = {
  onClick: () => void
  isMenuOpened: boolean
}

const StyledButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: none;
  display: flex;
  align-items: center;
  cursor: pointer;
  flex-direction: column;
`

const StyledLabel = styled.span`
  font-size: ${get.fontSizes('sm')};
  text-transform: uppercase;
  color: white;
  margin: 0;
  cursor: pointer;
`

const NavDrawerTrigger = React.forwardRef<HTMLButtonElement, TriggerProps>(
  ({ onClick, isMenuOpened }, ref) => {
    const showTrigger = useShowNavTrigger()

    return (
      <>
        {showTrigger && (
          <StyledButton
            ref={ref}
            className="navigation-menu-trigger"
            onClick={onClick}
            aria-expanded={isMenuOpened}
            data-testid="left-side-drawer-hamburger-button"
          >
            <Icon icon={deprecated.muiMenu} fill="white" size="xl" ariaHidden />
            <StyledLabel>menu</StyledLabel>
          </StyledButton>
        )}
      </>
    )
  },
)

export { NavDrawerTrigger }
