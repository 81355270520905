import React, { ReactElement } from 'react'
import {
  bankNumbers,
  creditCardNumbers,
  investNumbers,
  mortgageNumbers,
  autoNumbers,
} from '../../constants'
import { CallUsSchema, CallUsSchemaInput } from './types'

export const BANK_SCHEMA: CallUsSchema = {
  id: 'bank',
  shouldDisplay: ({
    isAuthenticated,
    isBankCustomer = false,
    isDebtPlanCustomer = false,
  }: CallUsSchemaInput): boolean =>
    !isAuthenticated || isBankCustomer || isDebtPlanCustomer,
  title: ({
    isBankCustomer = false,
    isDebtPlanCustomer = false,
  }: CallUsSchemaInput): string => {
    const isBothCustomer = isBankCustomer && isDebtPlanCustomer

    // Below logic makes the assumption that if it is being run then the customer
    // has either a Bank or Debt Plan relationship
    if (isDebtPlanCustomer && !isBankCustomer) return 'Plans'
    if (isBothCustomer) return 'Bank Accounts and Plans'
    return 'Bank Accounts'
  },
  allytm: `Bank Accounts ${bankNumbers.insideUS}`,
  phoneNumber: {
    display: bankNumbers.insideUS,
    link: bankNumbers.telLink,
  },
  hours: "We're here 24/7",
}

export const INVEST_SCHEMA: CallUsSchema = {
  id: 'invest',
  shouldDisplay: ({
    isAuthenticated,
    isInvestCustomer = false,
    isWealthCustomer = false,
  }: CallUsSchemaInput): boolean => {
    return !isAuthenticated || isInvestCustomer || isWealthCustomer
  },
  title: ({
    isAuthenticated,
    isInvestCustomer = false,
    isWealthCustomer = false,
    featureFlags,
  }: CallUsSchemaInput): string => {
    const isPersonalAdviceEnabled = featureFlags.variation(
      'FF_personal-advisor',
      false,
    )
    const isBothCustomer = isInvestCustomer && isWealthCustomer

    // Below logic makes the assumption that if it is being run then the customer
    // has either a Invest or Wealth relationship
    if (!isAuthenticated || isBothCustomer)
      return isPersonalAdviceEnabled
        ? 'Investments and Personal Advice'
        : 'Investments and Wealth Management'
    if (isInvestCustomer) return 'Investments'
    return isPersonalAdviceEnabled ? 'Personal Advice' : 'Wealth Management'
  },
  phoneNumber: {
    display: investNumbers.insideUS,
    link: investNumbers.telLink,
  },
}

export const CREDIT_CARD_SCHEMA: CallUsSchema = {
  id: 'creditCard',
  shouldDisplay: ({
    isAuthenticated,
    isCreditCardCustomer = false,
    featureFlags,
  }: CallUsSchemaInput): boolean => {
    const isCreditCardEnabled = featureFlags.variation(
      'FF_credit-card-snapshot',
      false,
    )
    return isCreditCardEnabled && (!isAuthenticated || isCreditCardCustomer)
  },
  title: 'Credit Cards',
  phoneNumber: {
    display: creditCardNumbers.insideUS,
    link: creditCardNumbers.telLink,
  },
  hours: "We're here 24/7",
}

export const MORTGAGE_SCHEMA: CallUsSchema = {
  id: 'mortgage',
  shouldDisplay: ({
    isAuthenticated,
    isMortgageCustomer = false,
  }: CallUsSchemaInput): boolean => {
    if (!isAuthenticated) return true
    return isMortgageCustomer
  },
  title: 'Home Loans',
  phoneNumber: {
    display: mortgageNumbers.insideUS,
    link: mortgageNumbers.telLink,
  },
  forceDropdown: true,
  hours: (): ReactElement => (
    <>
      We&apos;re here Mon - Fri, 8:30 am - 8 pm and
      <br />
      Sat, 8:30 am - 1 pm ET
    </>
  ),
}

export const AUTO_SCHEMA: CallUsSchema = {
  id: 'vehicles',
  shouldDisplay: ({
    isAuthenticated,
    isAutoCustomer = false,
    featureFlags,
  }: CallUsSchemaInput): boolean => {
    const isAutoEnabled = featureFlags.variation('FF_SPOG_WEB_UI', true)
    return !isAuthenticated || (isAutoEnabled && isAutoCustomer)
  },
  title: 'Vehicles',
  allytm: `Auto Accounts ${autoNumbers.insideUS}`,
  phoneNumber: {
    display: autoNumbers.insideUS,
    link: autoNumbers.telLink,
  },
  forceDropdown: true,
  hours: (): ReactElement => (
    <>
      Mon - Fri, 8 am - 11 pm ET <br /> Sat 9 am - 7 pm ET
    </>
  ),
}

// This is the order the items will show in the dropdown
export const schema: CallUsSchema[] = [
  INVEST_SCHEMA,
  BANK_SCHEMA,
  CREDIT_CARD_SCHEMA,
  AUTO_SCHEMA,
  MORTGAGE_SCHEMA,
]
