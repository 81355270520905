import { noop } from '@ally/utilitarian'
import React, { FC } from 'react'

import RemoteApp from '../ally-next-remote-header/App'
import { UserRegistrationStates } from '../hooks/use-register-user'
import { useHostServices } from './use-host-services'
import { getRemoteLogger } from './utils'

const logger = getRemoteLogger('ally-next-remote-header')

export interface InternalHeaderRemoteProps {
  userRegistrationStates: UserRegistrationStates
}

export const InternalHeaderRemote: FC<InternalHeaderRemoteProps> = ({
  userRegistrationStates,
}) => {
  const hostServices = useHostServices({
    logger,
    userRegistrationStates,
    done: noop,
  })

  if (hostServices === null) return null

  return (
    <header>
      <RemoteApp services={hostServices} />
    </header>
  )
}
