import React from 'react'
import styled from 'styled-components'

import { TextBody, Box, Link } from '@ally/metronome-ui'

import { CallUsIcon } from './CallUsIcon'
import { ProcessedCallUsSchema } from './types'

const PhoneLink = styled(Link)`
  ${({ theme: { colors, fontSizes } }): string => `
  font-size: ${fontSizes.sm};
  color: ${colors.bluesuedeshoes};
`}
`

const StyledTextBody = styled(TextBody)`
  ${({ theme: { space } }): string => `
  margin-left: ${space.xxs};
  margin-right: ${space.xxs};
`}
`

export const CallUsInline: React.FC<{ schema: ProcessedCallUsSchema }> = ({
  schema,
}) => {
  const { allytm, hours, phoneNumber, title, callWaitTime } = schema

  return (
    <Box display="inlineBlock" ml="xs">
      <CallUsIcon />
      <PhoneLink
        text={phoneNumber.display}
        href={phoneNumber.link}
        allytmln={allytm ?? `${title} ' ' ${phoneNumber.display}`}
      />
      {hours && (
        <StyledTextBody tag="span" size="sm">
          &bull; {hours}
        </StyledTextBody>
      )}
      {callWaitTime !== null && (
        <TextBody tag="span" size="sm">
          &bull; Wait Time: {Math.max(1, callWaitTime)} min
        </TextBody>
      )}
    </Box>
  )
}
