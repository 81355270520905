import React, { useMemo } from 'react'
import { useEtinCertification } from './use-etin-certification'
import { useManageableCds, ManageableCdValues } from './use-manageable-cds'
import {
  useNotificationsDropdown,
  NotificationsDropdownValues,
} from './use-notifications-dropdown'

import { RemoteConfig } from './utils'
import { useUnreadMessageCount } from '../../hooks'
import { useHostServices } from '../../HostServices'

export interface ActionBarValues
  extends NotificationsDropdownValues,
    Pick<ManageableCdValues, 'submitHasAcknowledgedCds'> {
  setRemoteNotifications(remoteNotifications: RemoteConfig[]): void
}

export const ActionBarContext = React.createContext<ActionBarValues>(
  {} as ActionBarValues,
)

const ActionBarProvider: React.FC = ({ children }) => {
  const { hasBankUnreadMessages } = useUnreadMessageCount()
  const { isEtinUncertified } = useEtinCertification()
  const { cdNotifications, submitHasAcknowledgedCds } = useManageableCds()
  const { actionBar } = useHostServices()
  const { notifications } = actionBar
  const setRemoteNotifications = notifications.set
  const remoteNotifications = notifications.get()

  const notificationTypeMap = useMemo(
    () => ({
      ETIN: isEtinUncertified,
    }),
    [isEtinUncertified],
  )

  const notificationsDropdownValues = useNotificationsDropdown({
    types: notificationTypeMap,
    manageableCds: cdNotifications,
    hasBankMessages: hasBankUnreadMessages,
    remoteNotifications,
  })

  const contextValues = {
    submitHasAcknowledgedCds,
    setRemoteNotifications,
    ...notificationsDropdownValues,
  }

  return (
    <ActionBarContext.Provider value={contextValues}>
      {children}
    </ActionBarContext.Provider>
  )
}

export { ActionBarProvider }
