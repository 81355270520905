import * as React from 'react'
import styled from 'styled-components'
import { deprecated } from '@ally/metronome-icons'
import {
  Icon,
  TextBody,
  Box,
  Media,
  Space,
  get,
  useMediaSize,
  muiBankTheme,
} from '@ally/metronome-ui'
import { navHeight } from '@ally/next-layout'
import { useHostServices } from '../../HostServices'

type ProfileMenuTriggerProps = {
  onClick: () => void
  isMenuOpening: boolean
  isMenuOpened: boolean
}

const StyledWrapper = styled(Box)<{ isMenuActive: boolean }>`
  ${({ isMenuActive, theme: { colors, media, space } }): string => `
    position: relative;
    margin-right: ${space.none};
    &::after {
      content: '';
      position: absolute;
      transition: all 100ms ease-in;
      height: ${isMenuActive ? space.xxs : '0'};
      background-color: ${colors.white};
      bottom: 0;
      right: 0;
      left: 0;
    }

    ${media.up('lg')} {
      margin-right: ${space.md};
    }
  `}
`

const StyledButton = styled.button`
  background-color: transparent;
  padding: 0;
  border: none;
  display: flex;
  cursor: pointer;
  white-space: nowrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  ${get.media.up('lg')} {
    flex-direction: row;
  }
`

const WhiteTextBody = styled(TextBody)`
  color: ${get.colors('white')};
`

const ProfileMenuTrigger = React.forwardRef<
  HTMLButtonElement,
  ProfileMenuTriggerProps
>(({ onClick, isMenuOpening, isMenuOpened }, ref) => {
  const isLgUp = useMediaSize('LgUp')
  const { session, featureFlags } = useHostServices()
  const isGuestCustomer = !!session?.data?.allyUserRole?.guest
  const isGuestEnabled = featureFlags.variation('FF_guest-experience', false)
  const triggerText =
    isGuestCustomer && isGuestEnabled ? 'Profile' : 'Profile and Settings'

  return (
    <StyledWrapper
      display="flex"
      alignItems="center"
      height={navHeight.px}
      isMenuActive={isMenuOpening || isMenuOpened}
    >
      <StyledButton
        ref={ref}
        onClick={onClick}
        className="profile-menu-trigger"
        aria-label={`Open ${triggerText.toLowerCase()} menu`}
        aria-expanded={isMenuOpened}
      >
        <Icon
          icon={deprecated.muiProfile}
          fill="white"
          data-testid="top-nav-user-icon"
          className="profile-menu-trigger"
          size={isLgUp ? 'md' : 'xl'}
          mr={isLgUp ? muiBankTheme.space.xxs : 0}
          ariaHidden
        />
        <Media.LgUp>
          <WhiteTextBody tag="p" size="md">
            {triggerText}
          </WhiteTextBody>
          <Space mt="xxs">
            <Icon
              icon={deprecated.muiChevronDown}
              fill="white"
              size="xs"
              ml="xxs"
              ariaHidden
            />
          </Space>
        </Media.LgUp>
        <Media.MdDown>
          <WhiteTextBody tag="p" size="sm">
            PROFILE
          </WhiteTextBody>
        </Media.MdDown>
      </StyledButton>
    </StyledWrapper>
  )
})

export { ProfileMenuTrigger }
