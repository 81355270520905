import React, { useContext } from 'react'
import { HostServices } from '@ally/federated-types'

export const HostServicesContext = React.createContext<HostServices>(
  {} as HostServices,
)

export function useHostServices(): HostServices {
  return useContext(HostServicesContext)
}
