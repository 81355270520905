import { LoadState } from '@ally/use-data-domain'
import { Account } from '@ally/data-types-afg-accounts'
import { AllyUserRole, LOB } from '@ally/federated-types'

type AccountType = Account['type']

export function isDomainReady(state: LoadState): boolean {
  return state === LoadState.LOADED || state === LoadState.FAILED
}

export function getLOBGroups(roles: Partial<AllyUserRole> = {}): LOB[] {
  return (Object.keys(roles) as LOB[]).filter(lob => roles[lob])
}

export function getAccountTypes(accounts: Account[]): AccountType[] {
  return Array.from(new Set([...accounts?.map(account => account.type)]))
}
