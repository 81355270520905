import React from 'react'
import { Box, Icon, TextBody } from '@ally/metronome-ui'
import { deprecated } from '@ally/metronome-icons'
import styled from 'styled-components'
import { MenuItem } from 'react-aria-menubutton'

const WarningIcon = styled(Icon)`
  ${({ theme: { colors } }): string => `
  margin-right: 8px;
  vertical-align: text-bottom;
  &&& path {
    fill: ${colors.warning};
  `}
`
interface StaleDataNoticeProps {
  inNavDrawer?: boolean
  isCreditCardMigrationMessageEnabled?: boolean
}

const StyledStaleDataBox = styled(Box)`
  line-height: 1.5;
  display: flex;
  padding: 8px;
  white-space: normal;
  border-top: 1px solid #dddddd;
`
const StaleDataNotice: React.FC<StaleDataNoticeProps> = ({
  inNavDrawer,
  isCreditCardMigrationMessageEnabled,
}) => {
  return (
    <StyledStaleDataBox as={inNavDrawer ? Box : MenuItem}>
      <Box display="inline">
        <WarningIcon
          icon={deprecated.muiWarningFill}
          fill="warning"
          size="md"
          aria-hidden
        />
      </Box>
      <TextBody tag="p" size="sm">
        {isCreditCardMigrationMessageEnabled
          ? "We can't show your account information right now. A fix is in the works. Check back later."
          : 'We’ve hit a snag when trying to update your account information. A fix is in the works.'}
      </TextBody>
    </StyledStaleDataBox>
  )
}

export default StaleDataNotice
