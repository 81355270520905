import React, { Fragment, ReactElement, ComponentType } from 'react'
import { useSession, isAuthenticated } from './SessionProvider'

/**
 * Creates an HOC that will render <Component /> if the given criteria is
 * met based on existence of a user session. If not <Fallback /> will be
 * rendered.
 *
 * This is an !XOR operation, where A is the existence (or non-existence) of a
 * session and B is the value of displayWithSession:
 *
 * -  A &&  B => Component
 * - !A && !B => Component
 * - !A &&  B => Fallback
 * -  A && !B => Fallback
 */
function withSessionMaybe(displayWithSession: boolean) {
  return <P extends JSX.IntrinsicAttributes>(
    Component: ComponentType<P>,
    Fallback: ComponentType = (): ReactElement => <></>,
  ) => <T,>(props: T & P): ReactElement => {
    const hasAuth = isAuthenticated(useSession())
    const Renderable = hasAuth === displayWithSession ? Component : Fallback
    return <Renderable {...props} />
  }
}

export const withAuthenticatedSession = withSessionMaybe(true)
export const withUnauthenticatedSession = withSessionMaybe(false)

/**
 * Declarative mechanism for defining visible content for either authenticated
 * or unauthenticated users.
 *
 * @usage
 * <>
 *   <Session.Authenticated>
 *     <MyProfile />
 *     <LogoutButton />
 *   </Session.Authenticated>
 *   <Session.Unauthenticated>
 *     <LoginInButton />
 *   </Session.Unauthenticated>
 * </>
 */
export const Session = {
  Authenticated: withAuthenticatedSession(Fragment),
  Unauthenticated: withUnauthenticatedSession(Fragment),
}
