import { AllyLaunchDarkly } from '@ally/aardvarkly'

type NavItem = {
  title: string
  url: string
}
type GetVariationType = AllyLaunchDarkly['client']['variation']

export const EXPLORE_MORE_ROUTES = (
  getVariation: GetVariationType | undefined,
): NavItem[] => {
  const isDebtLinkOnEnabled = getVariation?.('FF_credit-health-remote', false)
  const isRoboRebrandEnabled = getVariation?.(
    'FF_robo-portfolio-rebrand',
    false,
  )

  const navItems = [
    { title: 'Our Services', url: '/products-and-rates' },
    {
      title: 'Ally Bank Rates Today',
      url: '/products-and-rates/our-rates-today/0/0/0/',
    },
    {
      title: 'Self-directed Trading',
      url: '/products-and-rates/self-directed-trading/',
    },
    {
      title: isRoboRebrandEnabled ? 'Automated Investing' : 'Robo Portfolios',
      url: '/products-and-rates/robo-portfolios',
    },
    {
      title: 'CDs',
      url: '/products-and-rates/cd-products/0/3/0/',
    },
    {
      title: 'Savings and Checking',
      url: '/products-and-rates/savings-and-checking/0/0/0/',
    },
    {
      title: 'IRAs',
      url: '/products-and-rates/ira-products/0/0/0/',
    },
    {
      title: 'Buy a Home',
      url: '/products-and-rates/buy-a-home/',
    },
    {
      title: 'Refinance',
      url: '/products-and-rates/refinance-a-home/',
    },
    ...(isDebtLinkOnEnabled
      ? [{ title: 'Credit Health', url: '/credit-health' }]
      : []),
  ]

  return navItems
}
