import { useCallback } from 'react'
import { isError } from '@ally/utilitarian'
import { updateExistingCoachmarks, Coachmark } from './utils'
import { updateCoachmarkFromNotifications } from '.'
import { useHostServices } from '../../HostServices'

type FetchParams = {
  initialCoachmarkState: Coachmark[]
  coachmarkToUpdate: Coachmark
  guid?: string | null
}

type FetchReturn = (params: FetchParams) => Promise<Coachmark[]>

export const useCoachmarkUpdater = (): FetchReturn => {
  const { session, log } = useHostServices()

  const updateCoachmark = useCallback(
    async ({
      initialCoachmarkState,
      coachmarkToUpdate,
    }: FetchParams): Promise<Coachmark[]> => {
      const { data } = coachmarkToUpdate

      try {
        await updateCoachmarkFromNotifications(session.data, data)

        const updatedCoachmarks = updateExistingCoachmarks(
          initialCoachmarkState,
          coachmarkToUpdate,
          data,
        )

        return updatedCoachmarks
      } catch (e) {
        log.error({
          message: [
            'Update Coachmark Failed:',
            ...(isError(e) ? [e.stack] : []),
          ],
        })
        return initialCoachmarkState
      }
    },
    [fetch],
  )

  return updateCoachmark
}
