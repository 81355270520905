import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Link as MuiLink, Media } from '@ally/metronome-ui'
import { useHostServices } from '../HostServices'
import { useAccountStatus } from '../hooks'

const StatusTrackerLink = styled(MuiLink)(
  ({ theme: { space } }) => css`
    a {
      color: white;
      margin-left: ${space.md};
      line-height: 21px;
      white-space: nowrap;
    }
  `,
)

export const StatusTracker: React.FC = () => {
  const { hasActiveDepositAccounts } = useAccountStatus()
  const { session, globalnav } = useHostServices()
  const { isHidden } = globalnav
  const isAuthenticated = session?.status === 'Authenticated'
  const isGuestCustomer = !!session?.data?.allyUserRole?.guest

  if (
    hasActiveDepositAccounts &&
    !isGuestCustomer &&
    !isHidden &&
    isAuthenticated
  ) {
    return (
      <Media.MdUp>
        <StatusTrackerLink
          text="Status Tracker"
          variant="link"
          allytmln="statustracker"
        >
          <ReactRouterLink to="/status-tracker" />
        </StatusTrackerLink>
      </Media.MdUp>
    )
  }
  return null
}
