import { SessionData } from '@ally/federated-types'
import { FetchErrorPayload } from '@ally/fido'
import { DomainFetch } from '@ally/use-data-domain'
import { AcknowledgedAccount } from '../providers'
import { fetchWithTransmitAuth } from './fetch'

export const fetchUpdateAcknowledgedCds = (
  sessionData: SessionData | null,
): Promise<DomainFetch<{ accounts: AcknowledgedAccount[] }>> => {
  const url = `/acs/v1/bank-accounts/attributes/ManageCDReminderAcknowledged`
  const fetcher = fetchWithTransmitAuth<
    { accounts: AcknowledgedAccount[] } & FetchErrorPayload
  >(url)

  return fetcher(sessionData)
}

export const postSubmitHasAcknowledgedCds = (
  sessionData: SessionData | null,
  accountIds: string[],
): Promise<DomainFetch<FetchErrorPayload>> => {
  const url = '/acs/v1/bank-accounts/event/ManageCDReminderAcknowledged'
  const fetcher = fetchWithTransmitAuth<FetchErrorPayload>(url, {
    method: 'POST',
    body: JSON.stringify({ accountIds }),
  })

  return fetcher(sessionData)
}

export function getBankruptcyAcceptedSessionProp(): boolean {
  const interruptsStatus = sessionStorage.getItem('interruptsStatus')
  return interruptsStatus && JSON.parse(interruptsStatus).bankruptcyAccepted
}
