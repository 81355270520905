import { useMemo } from 'react'
import {
  getDataLoadState,
  getDomainData,
} from '@ally/data-selectors-customization'
import { LoadState } from '@ally/use-data-domain'
import { useHostServices } from '../../HostServices'

export type AccountOrderInfo = {
  name: string
  order: number
}

type CustomizationValues = {
  loaded: boolean
  failed: boolean
  investOrder: number
  depositOrder: number
  mortgageOrder: number
  autoOrder: number
  creditCardOrder: number
}

type SortOrderValues = Pick<
  CustomizationValues,
  | 'investOrder'
  | 'depositOrder'
  | 'mortgageOrder'
  | 'autoOrder'
  | 'creditCardOrder'
>

const useCustomization = (): CustomizationValues => {
  const { hostData } = useHostServices()
  const customizationLoadState = getDataLoadState(hostData)

  const loaded = customizationLoadState === LoadState.LOADED
  const failed = customizationLoadState === LoadState.FAILED

  const sortOrder = useMemo((): SortOrderValues | null => {
    if (!loaded) return null

    let investOrder = 0
    let depositOrder = 0
    let mortgageOrder = 0
    let creditCardOrder = 0
    let autoOrder = 0

    const data = getDomainData(hostData)

    data?.forEach(({ lobs }): void => {
      lobs.forEach(({ name, order }): void => {
        if (name === 'INVEST') investOrder = order
        if (name === 'DEPOSIT') depositOrder = order
        if (name === 'MORTGAGE') mortgageOrder = order
        if (name === 'CC') creditCardOrder = order
        if (name === 'AUTO') autoOrder = order
      })
    })

    return {
      investOrder,
      depositOrder,
      mortgageOrder,
      autoOrder,
      creditCardOrder,
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [loaded])

  return {
    loaded,
    failed,
    investOrder: sortOrder?.investOrder || 0,
    depositOrder: sortOrder?.depositOrder || 0,
    mortgageOrder: sortOrder?.mortgageOrder || 0,
    autoOrder: sortOrder?.autoOrder || 0,
    creditCardOrder: sortOrder?.creditCardOrder || 0,
  }
}

export { useCustomization }
