import React, { useEffect } from 'react'
import styled from 'styled-components'

import { Box, Media } from '@ally/metronome-ui'
import { MaxAppWidth } from '@ally/next-layout'
import { useGlobalLocation } from '@ally/federator'
import { SharedHelpModal } from '../SharedHelpModal'

import { useLobs } from '../hooks'
import { useActionBar } from '../providers'

import Messages from './Messages'
import { NotificationsDropdown, NotificationsTrigger } from './Notifications'
import { Print } from './Print'

import CallUs from './CallUs'
import ChatBubble from './ChatBubble'
import { ActionBarContext } from './ActionBarContext'
import { ActionBarProvider } from '../providers/action-bar/ActionBarProvider'
import { useHostServices } from '../HostServices'

const StyledNav = styled.nav`
  ${({ theme: { fontSizes } }): string => `
    display: flex;
    height: 51px;
    width: 100%;
    padding: 0;
    font-size: ${fontSizes.sm};
  `}
`

const ActionBarContent: React.FC = () => {
  const { featureFlags, globalnav, session } = useHostServices()
  const isAuthenticated = session.status === 'Authenticated'

  const {
    investment: isInvestCustomer,
    bank: isBankCustomer,
    mortgage: isMortgageCustomer,
    creditcard: isCreditCustomer,
    auto: isAutoUser,
  } = useLobs()
  const {
    isNotificationsOpen,
    unreadNotifications,
    hasNoNotifications,
    toggleNotifications,
  } = useActionBar()
  const { isHidden } = globalnav

  const wealth = session?.data?.relationships?.invest?.wealth
  const isWealthCustomer =
    wealth?.indicator === 'YES' &&
    (wealth?.status === 'CLIENT_UNFUNDED' || wealth?.status === 'CLIENT_FUNDED')

  const isDebtPlanEnabled = featureFlags.variation(
    'FF_debt-plan-snapshot',
    false,
  )
  const isDebtPlanCustomer =
    (isDebtPlanEnabled &&
      session?.data?.relationships?.tools?.debtReduction?.status ===
        'ACTIVE') ||
    false

  const isCreditCardEnabled = featureFlags.variation(
    'FF_credit-card-snapshot',
    false,
  )
  const isCreditCardCustomer = isCreditCardEnabled && isCreditCustomer

  const isAutoEnabled = featureFlags.variation('FF_SPOG_WEB_UI', true)
  const isAutoCustomer = isAutoEnabled && isAutoUser

  return (
    <ActionBarContext.Provider
      value={{
        isBankCustomer,
        isDebtPlanCustomer,
        isInvestCustomer,
        isMortgageCustomer,
        isWealthCustomer,
        isCreditCardCustomer,
        isAutoCustomer,
      }}
    >
      {/* The chat team script handles the logic to display a chat bubble, do not add LOB guards here */}
      <ChatBubble />
      <CallUs />
      {!isHidden && isAuthenticated && (
        <>
          {!hasNoNotifications && (
            <NotificationsTrigger
              toggleNotifications={toggleNotifications}
              unreadNotifications={unreadNotifications}
              isOpen={isNotificationsOpen}
            />
          )}
          <Messages />
          <Box ml="xs">
            <SharedHelpModal />
          </Box>
          <Print />
        </>
      )}
    </ActionBarContext.Provider>
  )
}

const ActionBar: React.FC = () => {
  const { globalnav, session } = useHostServices()
  const { pathname } = useGlobalLocation()
  const { isHidden } = globalnav
  const {
    notifications,
    isNotificationsOpen,
    setNotificationsOpen,
    toggleNotifications,
    hasDismissedNotifications,
    unreadNotifications,
    hasNoNotifications,
  } = useActionBar()
  const isAuthenticated = session.status === 'Authenticated'

  // Force notifications open if they have not been dismissed and
  // there are unread notifications
  const forceNotificationsOpen =
    unreadNotifications > 0 && !hasDismissedNotifications
  useEffect(() => {
    if (forceNotificationsOpen && !isNotificationsOpen && !isHidden)
      setNotificationsOpen(true)
  }, [forceNotificationsOpen, isNotificationsOpen, isHidden])

  // Close notifications on navigation (unless forced open)
  useEffect(() => {
    if (!forceNotificationsOpen) setNotificationsOpen(false)
  }, [pathname, setNotificationsOpen])

  const showDropdown =
    isNotificationsOpen && !isHidden && isAuthenticated && !hasNoNotifications

  return (
    <Media.MdUp>
      <MaxAppWidth bg="page-grey" pb={isNotificationsOpen ? 'xl' : 'none'}>
        <StyledNav aria-label="Contact Us">
          <Box
            data-testid="action-bar"
            mx="auto"
            display="flex"
            width="100%"
            alignItems="center"
            justifyContent="flex-end"
          >
            <ActionBarContent />
          </Box>
        </StyledNav>
        {showDropdown && (
          <NotificationsDropdown
            notifications={notifications}
            toggleNotifications={toggleNotifications}
          />
        )}
      </MaxAppWidth>
    </Media.MdUp>
  )
}

const ActionBarWithProvider: React.FC = () => {
  return (
    <ActionBarProvider>
      <ActionBar />
    </ActionBarProvider>
  )
}

export default ActionBarWithProvider
