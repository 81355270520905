import { ResolvedValue } from '@ally/transmitigator'
import { AnyFunc, tapsAsync } from '@ally/utilitarian'
import * as LogRocket from 'logrocket'

import { logRocket } from '../log-rocket'
import { Routes } from '../providers/bootstrap/types'
import log from '../whisper'
import { TrackingEvent } from './events'

type Resolved<F extends AnyFunc> = ResolvedValue<ReturnType<F>>

export type TrackingEvents = [string] | [string, string]

const getShortRemoteName = (x: string): string => {
  return x.replace(/^ally-next-remote-/, '')
}

const delta = {
  init: Date.now(),
  last: Date.now(),
}

function logTrackingMessage(...args: LRTrack): void {
  const [eventName, attributes] = args
  const now = Date.now()
  const msg = `[TRACKING] ${now - delta.init} +${
    now - delta.last
  } - ${eventName}`
  delta.last = now
  log.info({ message: attributes ? [msg, attributes] : msg })
}

type LRTrack = Parameters<typeof LogRocket['track']>

/**
 * Sends a tracking event to log rocket.
 * If log rocket is disabled, logs it to the console for debugging purposes.
 * Don't use `lockRocket.track` directly... please use this function!
 */
export function track(...args: LRTrack): void {
  logRocket.track(...args)
  logTrackingMessage(...args)
}

export const tracks = (...args: LRTrack) => (): void => track(...args)

/**
 * Given a function, will wrap it with a function that tracks `events[0]`,
 * executes the function, then tracks `events[1]` once the function resolves.
 * The "after" event is optional.
 */
export function withTrackingEventsAsync<F extends AnyFunc>(
  f: F,
  events: TrackingEvents,
) {
  return (...args: Parameters<F>): Promise<Resolved<F>> => {
    const [before, after] = events
    track(before)

    return Promise.resolve()
      .then(() => f(...args))
      .then(tapsAsync((_: Resolved<F>) => after && track(after)))
  }
}

/**
 * Tracks when a remote is loaded using the build tag from it's source code.
 * Allows us to determine the release versions of remotes used in log rocket.
 */
export function trackRemoteAppUsage({
  name = 'unknown',
  release = 'unknown',
}: AppDetails): void {
  track(`ally-next-remote-loaded-${getShortRemoteName(name)}-${release}`)
}

export const trackRemoteEvent = (eventName: string) => {
  return (
    { id }: { id: string },
    routes: Routes,
    additionalAtrributes?: LRTrack[1],
  ): void => {
    const { branch, commit } = {
      branch: 'unknown',
      commit: 'unknown',
      ...(routes[id] ?? {}),
    }
    track(eventName, {
      ...additionalAtrributes,
      id,
      release: `${branch}-${commit}`,
    })
  }
}

export const trackRemoteError = trackRemoteEvent(TrackingEvent.RemoteError)
export const trackRemoteMount = trackRemoteEvent(TrackingEvent.RemoteMount)
export const trackRemoteMounted = trackRemoteEvent(TrackingEvent.RemoteMounted)
export const trackRemoteUnmount = trackRemoteEvent(TrackingEvent.RemoteUnmount)
export const trackRemoteUnmounted = trackRemoteEvent(
  TrackingEvent.RemoteUnmounted,
)
