import React from 'react'
import { useWindowWidth } from '@ally/use-window-width'

// TODO: maybe add a ref to get the real deal
const ESTIMATED_DROPDOWN_MENU_WIDTH = 70

// Every value in array becomes sum of previous values plus current value
const cumulativeSum = (a: number[], b: number, i: number): number[] => [
  ...a,
  (i > 0 ? a[i - 1] : 0) + b,
]

interface UseTotalElementsToHideProps {
  elementWidths: number[]
  containerWidth: number | undefined
}

/**
 * Custom hook that takes in an array of element widths as well as a containerWidth.
 * It then determines how many elements need to be hidden based off of the difference of
 * the container width, the sum of what's currently visible, and an estimated dropdown menu
 * width.
 */
const useTotalElementsToHide = ({
  elementWidths,
  containerWidth,
}: UseTotalElementsToHideProps): number => {
  const width = useWindowWidth()

  const [totalElementsToHide, setTotalElementsToHide] = React.useState(0)

  React.useLayoutEffect(() => {
    if (!containerWidth) {
      return
    }

    const visibleWidths = elementWidths.slice(
      0,
      elementWidths.length - totalElementsToHide,
    )

    const visibleChildrenWidth = visibleWidths.reduce((a, b) => a + b, 0)

    const widthDifference =
      containerWidth - visibleChildrenWidth - ESTIMATED_DROPDOWN_MENU_WIDTH

    // Removing elements
    if (widthDifference < 0) {
      const rollingAccumulatorWidths = [...visibleWidths]
        .reverse()
        .reduce(cumulativeSum, [])

      const endIndexToHide = rollingAccumulatorWidths.findIndex(
        a => a > Math.abs(widthDifference),
      )

      if (endIndexToHide !== -1) {
        setTotalElementsToHide(totalElementsToHide + endIndexToHide + 1)
      }
    }

    // Adding elements
    if (widthDifference >= 0 && totalElementsToHide > 0) {
      // Count the elements that can fit and subtract that value from elementsToHide
      const numberOfElementsThatCanFit = elementWidths
        .slice(-totalElementsToHide)
        .reduce(cumulativeSum, [])
        .reduce((a, b) => (b < widthDifference ? a + 1 : a), 0)

      if (numberOfElementsThatCanFit > 0) {
        setTotalElementsToHide(totalElementsToHide - numberOfElementsThatCanFit)
      }
    }
  }, [width, elementWidths, containerWidth])

  return totalElementsToHide
}

export { useTotalElementsToHide }
