import React from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useGlobalLocation } from '@ally/federator'
import { MuiIconMap } from '@ally/metronome-icons'
import { Link as MuiLink, Icon, Space, Box } from '@ally/metronome-ui'
import { useHostServices } from '../HostServices'

const customAllyLogo: MuiIconMap = {
  base: {
    paths: [
      {
        path:
          'M22.32 0h5.619v25.049h-5.619v-25.049zM30.623 0h5.619v25.049h-5.619v-25.049zM57.152 6.951l-9.41 25.049h-5.958l2.979-7.21-6.973-17.839h5.991l3.757 10.899h0.068l3.588-10.899h5.958zM19.758 16.085c0-4.237-3.022-9.19-9.315-9.19s-9.315 4.953-9.315 9.19c0 4.032 2.738 8.714 8.428 9.156l4.811-5.211h-3.828c-2.191 0-4.047-1.662-4.047-3.945s1.856-3.945 4.047-3.945 4.047 1.662 4.047 3.945c0 2.12 0 6.941 0 8.845h5.171c0-2.346 0-6.814 0-8.845z',
        fill: '#fff',
      },
    ],
    height: 32,
    width: 58,
    title: 'Ally Logo',
  },
}

const AllyLogo: React.FC = () => {
  const { session, featureFlags, globalnav } = useHostServices()
  const isGuestCustomer = session?.data?.allyUserRole?.guest
  const isGuestEnabled = featureFlags.variation('FF_guest-experience', false)
  const isAuthenticated = session?.status === 'Authenticated'
  const { pathname } = useGlobalLocation()
  const isClickPrevented = globalnav.isHidden

  const authenticatedURL =
    isGuestEnabled && isGuestCustomer ? '/guest-dashboard' : '/dashboard'

  const icon = (
    <Icon
      icon={customAllyLogo}
      fill="white"
      size="xl"
      unsupportedCustomWidth={58}
      unsupportedCustomHeight={58}
      ariaHidden
    />
  )

  if (
    isClickPrevented ||
    (isAuthenticated && pathname === authenticatedURL) ||
    (!isAuthenticated && pathname === '/')
  ) {
    return (
      <Box
        data-testid="top-nav-ally-icon"
        aria-label="Ally logo"
        id="top-nav-ally-icon" // Required to set focus programmatically
        tabIndex={-1}
        role="img"
      >
        {icon}
      </Box>
    )
  }

  return (
    <Space mr="xs">
      <MuiLink unsupportedContent={icon} data-testid="top-nav-ally-icon">
        <ReactRouterLink
          to={isAuthenticated ? authenticatedURL : '/'}
          aria-label={
            isAuthenticated ? 'Go to Snapshot of accounts' : 'Go to Login'
          }
          id="top-nav-ally-icon"
        />
      </MuiLink>
    </Space>
  )
}

export { AllyLogo }
