import * as React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { AccountForDropdown } from '@ally/data-selectors-afg-accounts'
import {
  AccountMeta,
  AccountStatus,
  AccountLOB,
} from '@ally/data-types-afg-accounts'
import { TextBody, Box, ScreenReaderOnly } from '@ally/metronome-ui'
import { getLastFour } from '@ally/utilitarian'
import { getValueHeading } from './utils'
import { useAccountStatus } from '../../hooks'

interface DetailProps {
  title: React.ReactNode
  value: React.ReactNode | null
  alignItems: 'flex-start' | 'flex-end'
}

export const CardContainer = styled(Box)<{
  accountColor: keyof DefaultTheme['colors']
}>(
  ({ accountColor, theme: { colors, space } }): string => `
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 75px;
    width: 100%;
    padding-left: ${space.xs};
    padding-right: ${space.xs};
    border-left: 7px solid ${colors[accountColor]};
    border-top: 1px solid ${colors['slate-2']};
  `,
)

export const Detail: React.FC<DetailProps> = ({ title, value, alignItems }) => {
  return (
    <Box display="flex" flexDirection="column" alignItems={alignItems} ml="sm">
      <Box>{title}</Box>
      {value !== null && (
        <TextBody tag="span" size="sm">
          {value}
        </TextBody>
      )}
    </Box>
  )
}

export const ClosedAccount: React.FC<{ accountClosingDate: string }> = ({
  accountClosingDate,
}) => {
  const options = {
    month: 'short',
    day: 'numeric',
    year: 'numeric',
  } as Intl.DateTimeFormatOptions

  return (
    <TextBody tag="p" size="sm" type="error">
      {new Date(accountClosingDate).toLocaleDateString('en-US', options)}
    </TextBody>
  )
}

interface GetAccountValueProps {
  account: AccountForDropdown
  staleData?: boolean
  statusReason?: AccountMeta['statusReason']
}

const showZeroBalance = (
  statusReason: AccountMeta['statusReason'],
): boolean => {
  switch (statusReason) {
    case 'PAID_IN_FULL':
    case 'TRANSFERRED':
    case 'FORECLOSED':
    case 'CLAIMS_CLOSE_OUT':
      return true
    default:
      return false
  }
}

const CurrencyText = styled(TextBody)<{
  balance: number
  ignoreNegativeError?: boolean
}>(({ balance, ignoreNegativeError, theme: { colors } }): string =>
  balance < 0 && !ignoreNegativeError ? `color: ${colors.error};` : '',
)

export const FormattedCurrency: React.FC<{
  balance: number
  ignoreNegativeError?: boolean
}> = ({ balance, ignoreNegativeError }) => (
  <CurrencyText
    tag="p"
    size="sm"
    balance={balance}
    ignoreNegativeError={ignoreNegativeError}
  >
    {Intl.NumberFormat('en-us', {
      style: 'currency',
      currency: 'USD',
    }).format(balance)}
  </CurrencyText>
)

export const getAccountValue = ({
  account,
  staleData,
  statusReason,
}: GetAccountValueProps): React.ReactNode | null => {
  const {
    closeDate: accountClosingDate,
    accountBalanceToShow,
    status: accountStatus,
  } = account

  const isAuto = account.lob === AccountLOB.AUTO
  const hasUnavailablePaymentDue = [
    AccountStatus.TERMINATED,
    AccountStatus.MATURED,
  ].includes(accountStatus)

  // auto only
  if (hasUnavailablePaymentDue && isAuto) {
    return 'Unavailable'
  }

  if (showZeroBalance(statusReason)) {
    return '$0.00'
  }

  if (accountClosingDate) {
    return <ClosedAccount accountClosingDate={accountClosingDate} />
  }

  if (accountStatus.toLowerCase() === 'closed') {
    return null
  }

  if (staleData) {
    return (
      <TextBody tag="p" size="md">
        <span aria-hidden="true">--</span>
        <ScreenReaderOnly>
          We can&apos;t update your account information right now, but
          we&apos;re working on it.
        </ScreenReaderOnly>
      </TextBody>
    )
  }

  if (account.meta?.paidInFullOrZeroBalance) {
    return null
  }

  return <FormattedCurrency balance={accountBalanceToShow} />
}

const StyledAccountNickname = styled(TextBody)<{
  inNavDrawer?: boolean
  isRestricted?: boolean
}>(
  ({ inNavDrawer, isRestricted, theme: { colors } }): string => `
  text-overflow: ellipsis;
  max-width: ${inNavDrawer ? '150px' : '60vw'};
  overflow: hidden;
  white-space: nowrap;
  text-transform: capitalize;
  color: ${isRestricted ? 'initial' : colors.bluesuedeshoes};
`,
)

export const AccountLink: React.FC<{
  nickname?: string
  accountNumber: string
  inNavDrawer?: boolean
  isRestricted?: boolean
}> = ({ nickname, accountNumber, inNavDrawer }) => (
  <Box display="flex">
    {nickname && (
      <StyledAccountNickname
        tag="p"
        size="sm"
        weight="bold"
        inNavDrawer={inNavDrawer}
      >
        {nickname}
      </StyledAccountNickname>
    )}
    {accountNumber && (
      <TextBody tag="p" size="sm">
        &nbsp;&bull;&bull;
        {getLastFour(accountNumber)}
      </TextBody>
    )}
  </Box>
)

const DetailBar: React.FC<{
  account: AccountForDropdown
  accountColor: keyof DefaultTheme['colors']
  inNavDrawer?: boolean
  invest?: boolean
}> = ({ account, accountColor, inNavDrawer, invest }) => {
  const { accountTypeLabel, nickName, accountNumber, status, lob } = account
  const { meta } = account
  const { hasStaleInvestAccounts } = useAccountStatus()
  const paidInFullOrZeroBalance = meta?.paidInFullOrZeroBalance

  return (
    <CardContainer accountColor={accountColor}>
      <Detail
        title={
          <TextBody tag="p" weight="bold" size="xs">
            {accountTypeLabel}
          </TextBody>
        }
        value={
          <AccountLink
            nickname={nickName}
            accountNumber={accountNumber}
            inNavDrawer={inNavDrawer}
          />
        }
        alignItems="flex-start"
      />
      <Detail
        title={getValueHeading({
          accountTypeLabel,
          status,
          lob,
          // auto only
          paidInFullOrZeroBalance,
        })}
        value={getAccountValue({
          account,
          // This is only looking at invest because it is the only LOB that shows `--` during stale data
          staleData: invest && hasStaleInvestAccounts,
          statusReason: meta?.statusReason,
        })}
        alignItems="flex-end"
      />
    </CardContainer>
  )
}

export default DetailBar
