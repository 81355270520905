import React, {
  useState,
  useEffect,
  useLayoutEffect,
  useRef,
  useCallback,
} from 'react'
import { SubNavDropdownSchema } from '@ally/federated-types'
import { DropdownMenuDEPRECATED, useMediaSize } from '@ally/metronome-ui'
import { useOverlay } from '../../../providers'

type DropdownProps = Pick<
  React.ComponentProps<typeof DropdownMenuDEPRECATED>,
  'caretOffsetX' | 'menuOffsetX'
>

type SubNavDropdownParams = {
  dropdownSchema?: SubNavDropdownSchema
  hideOverflow?: boolean
}

type SubNavDropdownValues = {
  triggerBoxRef: React.RefObject<HTMLDivElement> | null
  triggerIconRef: React.RefObject<SVGSVGElement> | null
  dropdownProps: DropdownProps
  onMenuToggle: (open: boolean) => void
}

const useSubNavDropdown = ({
  dropdownSchema,
  hideOverflow = true,
}: SubNavDropdownParams): SubNavDropdownValues => {
  const overlay = useOverlay()
  const isSmDown = useMediaSize('SmDown')
  const [isDropdownOpen, setDropdownOpen] = useState(false)

  // Hide the overlay if the dropdown unmounts.
  // e.g. The more menu may unmount when the window width changes while the menu
  // is open. We need to hide the overlay when this happens.
  useEffect(() => {
    return (): void => {
      if (isDropdownOpen) overlay.hide()
    }
  }, [overlay, isDropdownOpen])

  const onMenuToggle = useCallback(
    (open: boolean) => {
      if (open) {
        setDropdownOpen(true)
        overlay.show({ elevation: 1, hideOverflow })
      } else {
        setDropdownOpen(false)
        overlay.hide()
      }
    },
    [overlay, hideOverflow],
  )

  const triggerBoxRef = useRef<HTMLDivElement>(null)
  const triggerIconRef = useRef<SVGSVGElement>(null)

  const [caretOffsetX, setCaretOffsetX] = useState(0)
  const [menuOffsetX, setMenuOffsetX] = useState(0)

  useLayoutEffect(() => {
    const triggerRect = triggerBoxRef.current?.getBoundingClientRect()
    const triggerCenter = triggerRect
      ? triggerRect.x + triggerRect.width / 2
      : 0

    const iconRect = triggerIconRef.current?.getBoundingClientRect()
    const iconCenter = iconRect ? iconRect.x + iconRect.width / 2 : 0

    const isRightAligned = triggerCenter > window.innerWidth / 2
    const localMenuOffsetX = isRightAligned && !isSmDown ? 16 : 0

    setCaretOffsetX(
      triggerCenter && iconCenter
        ? iconCenter - triggerCenter - localMenuOffsetX
        : 0,
    )

    setMenuOffsetX(localMenuOffsetX)
  }, [dropdownSchema, isSmDown])

  return {
    triggerBoxRef,
    triggerIconRef,
    dropdownProps: { caretOffsetX, menuOffsetX },
    onMenuToggle,
  }
}

export { useSubNavDropdown }
