import { AutoLoginService } from '@ally/federated-types'
import React, { createContext, FC, useContext, useMemo } from 'react'
import { useFeatureFlag } from '../hooks'
import { useSession$ } from '../providers/session/useSession$'
import getAutoLoginService from './autoLogin'

export type Services = {
  autoLogin: AutoLoginService
}

const ServicesContext = createContext<Services>({} as any)

// "Disabled" Auto Login Service to use when the Feature Flag is disabled
const disabledAutoLoginService: AutoLoginService = {
  getProfile: async () => {
    throw new Error('AUTO_LOGIN_SERVICE_DISABLED')
  },
  logout: Promise.resolve.bind(Promise),
}

export const useServices = (): Services => useContext(ServicesContext)

export const ServicesProvider: FC = ({ children }) => {
  const session$ = useSession$()
  const autoLoginServiceEnabled = useFeatureFlag(
    'FF_ally-next-auto-login-service',
    false,
  )

  const autoLogin = useMemo(
    () =>
      autoLoginServiceEnabled
        ? getAutoLoginService({ session$ })
        : disabledAutoLoginService,
    [autoLoginServiceEnabled],
  )

  return (
    <ServicesContext.Provider value={{ autoLogin }}>
      {children}
    </ServicesContext.Provider>
  )
}
