import { useState, useEffect, useMemo, useCallback } from 'react'
import { Account } from '@ally/data-types-afg-accounts'
import { deposit as depositS } from '@ally/data-selectors-afg-accounts'
import { useHostServices } from '../../HostServices'
import {
  AcknowledgedAccount,
  AccountWithAcknowledgement,
  mapManageableCds,
} from './utils'
import {
  fetchUpdateAcknowledgedCds,
  postSubmitHasAcknowledgedCds,
} from '../../utils'

export interface ManageableCdValues {
  cdNotifications: AccountWithAcknowledgement[]
  submitHasAcknowledgedCds: (accountIds: string[]) => Promise<void>
}

export const useManageableCds = (): ManageableCdValues => {
  const { hostData, log, session } = useHostServices()
  const isAuthenticated = session.status === 'Authenticated'

  const [acknowledgedCds, setAcknowledgedCds] = useState<AcknowledgedAccount[]>(
    [],
  )
  const [hasCalledUpdate, setHasCalledUpdate] = useState(false)

  const manageableCds = useMemo(
    (): Account[] => depositS.getManageableCDs(hostData) || [],
    [hostData],
  )

  const updateAcknowledgedCds = useCallback(async () => {
    try {
      setHasCalledUpdate(true)
      const data = await fetchUpdateAcknowledgedCds(session.data)
      setAcknowledgedCds(data.payload?.accounts || [])
    } catch (e) {
      log.error({
        message: ['Get acknowledged CDs failed:', (e as Error).stack],
      })
      setAcknowledgedCds([])
    }
  }, [setAcknowledgedCds, session.data])

  const submitHasAcknowledgedCds = useCallback(
    async (accountIds: string[]) => {
      try {
        await postSubmitHasAcknowledgedCds(session.data, accountIds)
      } catch (e) {
        log.error({
          message: ['Submit acknowledged CDs has failed:', (e as Error).stack],
        })
      }
    },
    [postSubmitHasAcknowledgedCds, session.data],
  )

  useEffect(() => {
    if (!hasCalledUpdate && isAuthenticated && manageableCds.length) {
      updateAcknowledgedCds()
    }
  }, [hasCalledUpdate, isAuthenticated, manageableCds, updateAcknowledgedCds])

  const cdNotifications = useMemo(
    () => mapManageableCds(manageableCds, acknowledgedCds),
    [manageableCds, acknowledgedCds],
  )

  return { cdNotifications, submitHasAcknowledgedCds }
}
