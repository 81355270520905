import { deprecated, MuiIconMap } from '@ally/metronome-icons'

export const ADOBE_ICON_MAP: Record<string, MuiIconMap> = {
  'bank-pig': deprecated.muiPiggyBank,
  'car-front': deprecated.muiAuto,
  'credit-card': deprecated.muiCreditCard,
  'home-outline': deprecated.muiHouseOutline,
  laptop: deprecated.muiLaptop,
  lightbulb: deprecated.muiLightbulb,
  'line-chart': deprecated.muiLineChart,
}
