import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import { Box, Link as MuiLink, Icon, TextBody } from '@ally/metronome-ui'
import { MboxContent } from '../../../providers/action-bar'
import { ADOBE_ICON_MAP } from './constants'

const InlineLink = styled(MuiLink)(
  () => css`
    a {
      display: inline-flex;
      white-space: normal;
    }
  `,
)

export const AdobeNotification: FC<{
  content: MboxContent
  className?: string
}> = ({ content, className }) => {
  const {
    ctaHref,
    ctaLabel,
    ctaNewTab,
    description,
    iconColor,
    iconName,
  } = content
  const adobeIcon = ADOBE_ICON_MAP[iconName]

  return (
    <Box display="flex" alignItems="center" className={className}>
      {adobeIcon && (
        <Icon
          icon={adobeIcon}
          unsupportedCustomWidth={18}
          unsupportedCustomHeight={18}
          fill={(iconColor as any) || 'plum'}
          ariaHidden
          mr="xs"
        />
      )}
      <TextBody tag="span" size="md">
        {description}
      </TextBody>
      &nbsp;
      <InlineLink text={ctaLabel}>
        <a
          href={ctaHref}
          target={ctaNewTab ? '_blank' : '_self'}
          rel="noreferrer"
        >
          content replaced by metronome
        </a>
      </InlineLink>
    </Box>
  )
}
