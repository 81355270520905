/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'
import styled from 'styled-components'

export type SubNavListItemProps = {
  $key?: string
  children: React.ReactNode
}

const StyledSubNavListItem = styled.li`
  ${({ theme: { space } }): string => `
    padding-right: ${space.md};
    display: 'flex';
  `}
`

export const SubNavListItem = React.forwardRef<
  HTMLLIElement,
  SubNavListItemProps
>(({ $key, children }, ref) => (
  <StyledSubNavListItem key={$key} ref={ref}>
    {children}
  </StyledSubNavListItem>
))
