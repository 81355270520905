import usePortal from '@ally/use-portal'
import React, { useEffect, useRef } from 'react'
import { useHistory } from 'react-router-dom'

import { UserRegistrationStates } from '../hooks'
import {
  Route,
  isRehydrating,
  useBootstrap,
  useSession,
  useGlobalNav,
  useSubNav,
  useUtils,
  useRemoteNotifications,
} from '../providers'
import { useContentFederation } from './use-federation'
import { RemoteAppContainer } from './RemoteAppContainer'
import { resetDocumentTitle } from './utils'
import { SkeletonLoader } from '../components'
import InternalRoutes from '../routes'
import { track, TrackingEvent } from '../tracking'

export const isContentRemote = ({ slot }: Route): boolean =>
  !slot || slot === 'main'

export interface FederatedContentProps {
  userRegistrationStates: UserRegistrationStates
}

export const FederatedContent: React.FC<FederatedContentProps> = ({
  userRegistrationStates,
}) => {
  const { setRemoteNotifications } = useRemoteNotifications()
  const globalnav = useGlobalNav()
  const history = useHistory()
  const { handleRemoveChildren } = usePortal()
  const subnav = useSubNav()
  const utils = useUtils()

  const onMount = (): void => {
    globalnav.setIsHidden(false)
  }

  const onUnmount = (): void => {
    utils.setHelpModalState({
      status: 'Closed',
      selectedCategory: null,
    })
    subnav.set([])
    setRemoteNotifications([])
    resetDocumentTitle()
  }

  const onUnmounted = (): void => {
    handleRemoveChildren()
  }

  const onRouteChangeError = (): void => {
    history.replace('/error')
  }

  const {
    federatedRef,
    isFederatedRoute,
    isTransitioning,
  } = useContentFederation({
    userRegistrationStates,
    remoteFilter: isContentRemote,
    onMount,
    onUnmount,
    onUnmounted,
    onRouteChangeError,
  })
  const session = useSession()
  const { isBootstrapping } = useBootstrap()
  const isLoading = isRehydrating(session) || isBootstrapping || isTransitioning
  const isInternalRoute = !isLoading && !isFederatedRoute

  const isReadyRef = useRef(false)

  // Tracks the `ally-next-host-ready` event once.
  // This is triggered after the first remote app has rendered and indicates
  // that the app is "ready" (fully loaded).
  useEffect(() => {
    if (!isLoading && !isReadyRef.current) {
      isReadyRef.current = true
      track(TrackingEvent.HostReady)
    }
  }, [isLoading])

  return (
    <>
      <RemoteAppContainer
        ref={federatedRef}
        isLoading={isLoading}
        data-testid="content-remote-app-container"
      />
      {isInternalRoute && <InternalRoutes />}
      {isLoading && <SkeletonLoader />}
    </>
  )
}
