import React from 'react'
import styled from 'styled-components'
import { Button } from '@ally/metronome-ui'

const SkipLinkButton = styled(Button)`
  &:not(:focus) {
    border: 0;
    height: 0px;
    width: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
  }

  &:focus {
    position: fixed;
    top: 10px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 10;
  }
`

const focusOnClick = (): void => {
  const mainEle = document.querySelector<HTMLElement>('#main')

  if (mainEle) {
    const skipTarget = mainEle.querySelector<HTMLElement>('#skip-target')
    const tabIndexOne = mainEle.querySelector<HTMLElement>('[tabindex="-1"]')
    if (skipTarget) skipTarget.focus()
    else if (tabIndexOne) tabIndexOne.focus()
    else mainEle.focus()
  }
}

const SkipToButton: React.FC = () => (
  <SkipLinkButton
    variant="primary"
    text="Skip To Main Content"
    onClick={focusOnClick}
    onKeyDown={(e): void => {
      if (e.key === 'Enter') {
        e.preventDefault()
        focusOnClick()
      }
    }}
    disableFullWidthSmDown
  />
)

export { SkipToButton }
