import { useMemo } from 'react'
import { AllyUserRole } from '@ally/federated-types'
import { useHostServices } from '../../HostServices'

export function getDefaultUserRole(defaultValue = false): AllyUserRole {
  return {
    creditcard: defaultValue,
    auto: defaultValue,
    bank: defaultValue,
    mortgage: defaultValue,
    investment: defaultValue,
    wealth: defaultValue,
    guest: defaultValue,
  }
}

export function useLobs(defaultValue = false): AllyUserRole {
  const { session } = useHostServices()

  const sessionData = session

  const allyUserRole = useMemo(() => {
    const defaultUserRole = getDefaultUserRole(defaultValue)
    const sessionUserRole = sessionData?.data?.allyUserRole ?? {}
    return {
      ...defaultUserRole,
      ...sessionUserRole,
    }
  }, [defaultValue, sessionData?.data])

  return allyUserRole
}

export function useIsOnly(lobKey: keyof AllyUserRole): boolean {
  const { [lobKey]: val, ...userRoles } = useLobs()
  return val && Object.values(userRoles).every(r => !r)
}
