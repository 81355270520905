import React from 'react'
import styled from 'styled-components'
import { get, useMediaSize } from '@ally/metronome-ui'
import { MaxAppWidth, navHeight } from '@ally/next-layout'
import { SharedHelpModal } from '../SharedHelpModal'
import { Accounts } from './AccountsDropdown'
import { AllyLogo } from './AllyLogo'
import { ProfileMenu } from './ProfileMenu'
import { NavDrawerMenu } from './NavDrawerMenu'
import { StatusTracker } from './StatusTracker'
import { SkipToButton } from './SkipToButton'
import { ExploreDropdown } from './ExploreDropdown'
import { OpenAccount } from './OpenAccount'
import { LogOut } from './LogOut'
import { useHostServices } from '../HostServices'

const TopNavWrapper = styled.div`
  background-color: ${get.colors('plum')};
  position: relative;
  width: 100%;
  z-index: 3;
`

const TopNavContent = styled.nav`
  align-items: center;
  display: flex;
  height: ${navHeight.px};
  justify-content: space-between;
  margin: auto;
  width: 100%;
`

const TopNavSection = styled.div`
  align-items: center;
  display: flex;
  flex: 1;
`

/**
 * On sm screens, the Ally Logo, which is rendered in TopNavLeft, moves to the center;
 * however, it still must receive focus first, hence the need to change order here.
 */
const TopNavLeft = styled(TopNavSection)`
  justify-content: center;
  order: 2;

  ${get.media.up('md')} {
    justify-content: flex-start;
    order: 1;
  }
`

const TopNavCenter = styled(TopNavSection)`
  justify-content: flex-start;
  order: 1;

  ${get.media.up('md')} {
    justify-content: center;
    order: 2;
  }
`

const TopNavRight = styled(TopNavSection)`
  justify-content: flex-end;
  order: 3;
`

const TopNav: React.FC = () => {
  const { session } = useHostServices()
  const isAuthenticated = session?.status === 'Authenticated'

  const smIcon = useMediaSize('SmDown')

  return (
    <TopNavWrapper>
      <MaxAppWidth>
        <TopNavContent aria-label="main" data-testid="global-nav">
          <TopNavSection>
            <TopNavLeft>
              <AllyLogo />
              <SkipToButton />
              <Accounts />
              <StatusTracker />
              <ExploreDropdown />
              <OpenAccount />
            </TopNavLeft>
            <TopNavCenter>
              <NavDrawerMenu />
            </TopNavCenter>
            <TopNavRight>
              <ProfileMenu />
              <LogOut />
              {!isAuthenticated && (
                <SharedHelpModal variant="light" asIcon={smIcon} />
              )}
            </TopNavRight>
          </TopNavSection>
        </TopNavContent>
      </MaxAppWidth>
    </TopNavWrapper>
  )
}

export default TopNav
