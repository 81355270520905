/* eslint-disable react/jsx-props-no-spreading */

import React, { useMemo } from 'react'
import { Button } from '@ally/metronome-ui'
import { SubNavButtonSchema } from '@ally/federated-types'
import styled from 'styled-components'
import { SubNavListItem, SubNavListItemProps } from './SubNavListItem'
import { onlyText } from '../../utils/react'

interface SubNavButtonProps extends Pick<SubNavListItemProps, '$key'> {
  buttonSchema: SubNavButtonSchema
}

const WhiteSubNavButton = styled(Button)`
  color: white;
`

export const SubNavButton = React.forwardRef<HTMLLIElement, SubNavButtonProps>(
  ({ buttonSchema: { content, metadata, onClick }, ...others }, ref) => {
    const text = useMemo(() => onlyText(content), [content])
    return (
      <SubNavListItem ref={ref} {...others}>
        <WhiteSubNavButton
          variant="link"
          text={text}
          onClick={onClick}
          allytmln={`sub-nav-menu-${text}`}
          {...(metadata || {})}
        />
      </SubNavListItem>
    )
  },
)
