import React, { FC } from 'react'
import styled from 'styled-components'
import {
  Box,
  DropdownMenuDEPRECATED,
  get,
  TextBody,
  useMediaSize,
} from '@ally/metronome-ui'
import { useActionBar } from '../../providers'
import { DropdownTrigger } from '../DropdownTrigger'
import { CallUsIcon } from './CallUsIcon'
import { ProcessedCallUsSchema } from './types'

const LinkText = styled(TextBody)`
  color: ${get.colors('bluesuedeshoes')};
`

const TinyText = styled(TextBody)`
  text-transform: none;
`

export const MenuItemContent: FC<{ item: ProcessedCallUsSchema }> = ({
  item,
}) => {
  const { hours, phoneNumber, title, callWaitTime } = item

  return (
    <Box>
      <TextBody tag="span" size="sm" weight="bold">
        {title}
      </TextBody>
      <Box>
        <LinkText tag="span" size="sm" weight="bold">
          {phoneNumber.display}
        </LinkText>
      </Box>
      <Box>
        <TinyText tag="span" size="xs">
          {hours}{' '}
          {callWaitTime !== null && (
            <>&nbsp;&bull; Wait Time: {Math.max(1, callWaitTime)} min</>
          )}
        </TinyText>
      </Box>
    </Box>
  )
}

export const MenuItem: FC<{ item: ProcessedCallUsSchema }> = ({ item }) => {
  const { allytm, phoneNumber, title } = item

  return (
    <DropdownMenuDEPRECATED.MenuItemLink
      href={phoneNumber.link}
      withAllyTM={allytm ?? `${title} ' ' ${phoneNumber.display}`}
      content={<MenuItemContent item={item} />}
    />
  )
}

export const getAriaLabel = (isOpen: boolean): string =>
  isOpen ? 'Close Call Us menu' : 'Open Call Us menu'

export const CallUsDropdown: FC<{
  schema: ProcessedCallUsSchema[]
}> = ({ schema }) => {
  const isXlUp = useMediaSize('XlOnly')
  const { closeNotifications } = useActionBar()

  return (
    <div data-testid="call-us">
      <DropdownMenuDEPRECATED
        onMenuToggle={(isOpen: boolean): void => {
          if (isOpen) closeNotifications()
        }}
        topPos={36}
        caretOffsetX={isXlUp ? 28 : -8}
        menuOffsetX={14}
      >
        <DropdownMenuDEPRECATED.Trigger
          content={
            <DropdownTrigger copy="Call Us">
              <CallUsIcon />
            </DropdownTrigger>
          }
          ariaLabel={getAriaLabel}
        />
        <DropdownMenuDEPRECATED.MenuContainer>
          {schema.map(item => (
            <MenuItem key={item.id} item={item} />
          ))}
        </DropdownMenuDEPRECATED.MenuContainer>
      </DropdownMenuDEPRECATED>
    </div>
  )
}
