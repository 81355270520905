import React, { useMemo } from 'react'

import { useHostServices } from '../HostServices'
import { useActionBar } from '../providers'
import { useIsOnly, useLobs } from '../hooks'
import MessagesDropdown from './MessagesDropdown'

const Messages: React.FC = () => {
  const { closeNotifications } = useActionBar()
  const { investment, bank, creditcard, mortgage, auto } = useLobs()
  const { session, featureFlags } = useHostServices()

  const isMortgageOnly = useIsOnly('mortgage')

  const isDebtPlanEnabled = featureFlags.variation(
    'FF_debt-plan-snapshot',
    false,
  )
  const debtPlan =
    (isDebtPlanEnabled &&
      session?.data?.relationships?.tools?.debtReduction?.status ===
        'ACTIVE') ||
    false

  const isAutoEnabled = featureFlags.variation('FF_SPOG_WEB_UI', true)
  const isAuto = isAutoEnabled && auto

  const isCreditCardEnabled = featureFlags.variation(
    'FF_credit-card-snapshot',
    false,
  )
  const creditCard = isCreditCardEnabled && creditcard

  const showMessages = useMemo(() => {
    if (isMortgageOnly) {
      return false
    }
    return bank || debtPlan || investment || creditCard || mortgage || isAuto
  }, [isMortgageOnly, bank, investment, creditCard, mortgage, debtPlan, isAuto])

  if (!showMessages) return null
  return (
    <MessagesDropdown
      showHomeLoans={mortgage}
      closeNotifications={closeNotifications}
    />
  )
}

export default Messages
