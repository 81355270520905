import { useHostServices } from '../../HostServices'

interface UnreadMessageCountValues {
  bankUnreadMessageCount: number
  hasBankUnreadMessages: boolean
  vehicleUnreadMessageCount: number
  hasVehicleUnreadMessages: boolean
  investUnreadMessageCount: number
}

export const useUnreadMessageCount = (): UnreadMessageCountValues => {
  const { hostData } = useHostServices()
  const { notifications } = hostData

  const vehicleUnreadMessageCount =
    (notifications?.data?.notifications.find(
      n => n.type === 'AUTO_SECURE_UNREAD_MESSAGE_COUNT',
    )?.metadata?.unreadMsgCount as number | undefined) ?? 0

  const bankUnreadMessageCount =
    (notifications?.data?.notifications.find(
      n => n.type === 'BANK_SECURE_UNREAD_MESSAGE_COUNT',
    )?.metadata?.unreadMsgCount as number | undefined) ?? 0

  const investUnreadMessageCount =
    (notifications?.data?.notifications.find(
      n => n.type === 'INVEST_SECURE_UNREAD_MESSAGE_COUNT',
    )?.metadata?.unreadMsgCount as number | undefined) ?? 0

  return {
    bankUnreadMessageCount,
    hasBankUnreadMessages: bankUnreadMessageCount > 0,
    vehicleUnreadMessageCount,
    hasVehicleUnreadMessages: vehicleUnreadMessageCount > 0,
    investUnreadMessageCount,
  }
}
