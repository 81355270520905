import React from 'react'
import styled from 'styled-components'
import { Box } from '@ally/metronome-ui'
import { useHostServices } from '../HostServices'

const ChatBox = styled(Box)`
  ${({ theme: { space, colors } }): string => `
color: ${colors.bluesuedeshoes};
cursor: pointer;
display: flex;
align-items: center;
justify-content: center;
margin-left: ${space.xs};
`}
`

const ChatBubble: React.FC = () => {
  const { globalnav, session } = useHostServices()
  const { isHidden } = globalnav
  const isAuthenticated = session?.status === 'Authenticated'
  if (!isHidden && isAuthenticated) {
    return <ChatBox data-testid="chat-bubble" id="chatlinktop" />
  }
  return null
}

export default ChatBubble
