import { useMemo } from 'react'
import {
  deposit as afgDeposit,
  invest as afgInvest,
  mortgage as afgMortgage,
  creditCard,
  auto,
} from '@ally/data-selectors-afg-accounts'

import { useHostServices } from '../../HostServices'
import { getBankruptcyAcceptedSessionProp } from '../../utils'

export const useShowAccounts = (): boolean => {
  const { hostData, featureFlags } = useHostServices()
  const isAutoEnabled = featureFlags.variation('FF_SPOG_WEB_UI', true)

  const investAccountsForDropdown = afgInvest.getAllForDropdown(hostData)

  const depositAccountsForDropdown = afgDeposit.getAllForDropdown(hostData)

  const mortgageAccountsForDropdown = afgMortgage.getAllForDropdown(hostData)

  const creditCardAccountsForDropdown = creditCard.getAllForDropdown(hostData)

  const isBankruptcyAccepted = getBankruptcyAcceptedSessionProp()

  const autoAccountsForDropdown = useMemo(() => {
    return isAutoEnabled
      ? auto.getAllForDropdown(hostData, isBankruptcyAccepted)
      : []
  }, [hostData, isBankruptcyAccepted, isAutoEnabled])

  const showAccounts =
    [
      investAccountsForDropdown,
      depositAccountsForDropdown,
      mortgageAccountsForDropdown,
      creditCardAccountsForDropdown,
      autoAccountsForDropdown,
    ].flat().length > 0

  return showAccounts
}
