import React, { FC } from 'react'
import styled from 'styled-components'
import { FederatedContent } from './FederatedContent'
import { FederatedHeader } from './FederatedHeader'
import { useRemotes } from './use-remotes'
import {
  DevEnvironmentAwareness,
  DevRouteAwareness,
  MRGatewayNotification,
} from '../components'
import {
  FeatureFlag,
  useEmberPrefetch,
  useFeatureFlag,
  useIsWebWrapped,
  useRegisterUser,
  UserRegistrationStates,
} from '../hooks'
import { isAuthenticated, useSession } from '../providers'
import { InternalHeaderRemote } from './InternalHeaderRemote'

/**
 * NOTE: min-height changes based on whether or not the SubNav is shown. That
 * is, when the user is authenticated vs. unauthenticated.
 */
const MainContent = styled.main(
  ({ $hasAuth }: { $hasAuth: boolean }) => `
    flex: 0;
    margin: auto;
    width: 100%;
    flex: 1;
    min-height: calc(100vh - ${$hasAuth ? 265 : 225}px);
  `,
)

const Header: FC<{ userRegistrationStates: UserRegistrationStates }> = ({
  userRegistrationStates,
}) => {
  const useFederatedHeader = useFeatureFlag(
    FeatureFlag.FederatedHeaderEnabled,
    false,
  )

  if (useFederatedHeader)
    return <FederatedHeader userRegistrationStates={userRegistrationStates} />

  return (
    <InternalHeaderRemote userRegistrationStates={userRegistrationStates} />
  )
}

export const FederatedApp: FC = () => {
  // This needs to be hoisted into a single location so that
  // the user does not get registered multiple times
  const userRegistrationStates = useRegisterUser()
  const allRemotes = useRemotes()

  useEmberPrefetch()

  const isWebWrapped = useIsWebWrapped()

  return (
    <>
      {!isWebWrapped && (
        <Header userRegistrationStates={userRegistrationStates} />
      )}
      <MainContent
        id="main"
        $hasAuth={isAuthenticated(useSession())}
        tabIndex={-1}
      >
        <DevRouteAwareness remotes={allRemotes} isLoading={false} />
        <MRGatewayNotification />
        <DevEnvironmentAwareness />
        <FederatedContent userRegistrationStates={userRegistrationStates} />
      </MainContent>
    </>
  )
}
