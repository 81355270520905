import { NotificationsDomain } from '@ally/data-types-host'
import { SessionData } from '@ally/federated-types'
import { DomainFetch } from '@ally/use-data-domain'
import { CoachmarkData } from '@ally/whats-new-content'
import { fetchWithTransmitAuth } from '../../utils'

export const updateCoachmarkFromNotifications = (
  sessionData: SessionData | null,
  data: CoachmarkData,
): Promise<DomainFetch<NotificationsDomain>> => {
  const { guid, tpn } = sessionData ?? {}

  const queryObj = {
    ...(guid && { customerId: guid }),
    ...(tpn && { tpn }),
  }

  const queryParams = new URLSearchParams(queryObj)
  const url = `/acs/v1/notifications/${
    data.id
  }/customer-data?${queryParams.toString()}`
  const fetcher = fetchWithTransmitAuth<NotificationsDomain>(url, {
    method: 'PATCH',
    body: JSON.stringify({
      metadata: {
        completed: data.completed,
        ignored: data.ignored,
      },
    }),
  })

  return fetcher(sessionData)
}
