import * as React from 'react'
import styled from 'styled-components'
import { Box, TextBody, Icon } from '@ally/metronome-ui'
import { deprecated } from '@ally/metronome-icons'

// Overriding the fill here instead of with a prop to ensure that
// the colorModes object takes effect.
const StyledChevronIcon = styled(Icon)`
  ${({ theme: { colors, media, space } }): string => `
    margin-left: ${space.xxs};
    margin-bottom: 1px;
    display: none;

    &&& path {
      fill: ${colors.bluesuedeshoes};
    }

    ${media.up('xl')} {
      display: inline-block;
    }
  `}
`

const ChevronIcon: React.FC = () => (
  <StyledChevronIcon icon={deprecated.muiChevronDown} size="xs" />
)

const StyledTextBody = styled(TextBody)`
  ${({ theme: { colors, media, space } }): string => `
    margin-left: ${space.xxs};
    display: none;
    color: ${colors.bluesuedeshoes};

    ${media.up('xl')} {
      display: inline-flex;
    }
  `};
`

const StyledBox = styled(Box)`
  cursor: pointer;
`

const DropdownTrigger: React.FC<{ copy: string; className?: string }> = ({
  className,
  copy,
  children,
}) => {
  return (
    <StyledBox className={className} display="flex" alignItems="center" ml="xs">
      <Box display="flex" alignItems="center" justifyContent="center">
        {children}
        <StyledTextBody tag="span" size="sm" weight="bold">
          {copy}
        </StyledTextBody>
      </Box>
      <ChevronIcon />
    </StyledBox>
  )
}

export { DropdownTrigger }
