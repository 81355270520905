import './global-types'
import './styles/global.css'

import React from 'react'

import { render } from 'react-dom'
import App from './App'

import initLogRocket from './utils/log-rocket-init'
import initDynatrace from './utils/dynatrace-init'

initLogRocket()
initDynatrace()

// Conditionally requiring this module since it includes mirage/lodash
// and these are substantially large modules. Also, this should *never* be
// included in production. This condition is necessary since we ignore
// `@ally/apparition` during the build when the `ENABLE_MOCKS` environment
// variable isn't set and a direct `import` statement would blow up otherwise...
// ***IMPORTANT***: If this env variable is unset, this code will be hit!
if (process.env.ENABLE_MOCKS === 'TRUE') {
  // eslint-disable-next-line global-require, @typescript-eslint/no-var-requires
  require('@ally/apparition').default()
}

const app = document.getElementById('app') as HTMLDivElement

render((<App />) as any, app)
