import React from 'react'
import { Button } from '@ally/metronome-ui'
import { deprecated } from '@ally/metronome-icons'

const handlePrint = (): void => window.print()
export const Print: React.FC = () => {
  return (
    <Button
      aria-label="print page"
      data-testid="print"
      ml="xs"
      variant="link"
      onClick={handlePrint}
      icon={{
        map: deprecated.muiPrinterOutline,
      }}
    />
  )
}
