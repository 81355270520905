import React, { useState, useEffect, useRef } from 'react'
import styled from 'styled-components'
import { deprecated } from '@ally/metronome-icons'
import { Box, Icon } from '@ally/metronome-ui'
import { DropdownTrigger } from '../DropdownTrigger'
import { dropdownTriggerAriaLabel } from '../../utils'

interface StyledNotificationsWrapperProps {
  unreadNotifications: number
}

interface NotificationsTriggerProps extends StyledNotificationsWrapperProps {
  toggleNotifications: () => void
  isOpen: boolean
}

const NotificationsButton = styled.button<{ isOpen: boolean }>`
  position: relative;
  border: none;
  background: none;
  padding: 0;
  margin: 0;

  ${({ isOpen, theme: { colors, media, space } }): string => {
    if (isOpen) {
      return `
      ::before,
      ::after {
        top: 58%;
        right: -3px;
        border: solid transparent;
        content: ' ';
        height: ${space.none};
        width: ${space.none};
        position: absolute;
        pointer-events: none;
        z-index: 1;

        ${media.up('xl')} {
          right: -6px;
        }
      }

      :after {
        top: 63%;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: ${colors.white};
        border-width: 12px;
        margin-left: -10px;
      }

      :before {
        border-color: rgba(133, 155, 168, 0);
        border-bottom-color: ${colors['slate-4']};
        border-width: 12px;
        margin-left: -12px;
      }
    `
    }
    return ``
  }}
`

const StyledNotificationsWrapper = styled(Box)<{ $showNotification: boolean }>`
  ${({ $showNotification, theme: { colors, fontSizes } }): string => {
    if ($showNotification) {
      return `
        display: inline-block;
        ::before {
          color: ${colors.error};
          font-size: ${fontSizes.xs};
          content: '';
          background-color: ${colors.error};
          position: absolute;
          display: inline-block;
          right: -1px;
          width: 8px;
          border-radius: 50%;
          height: 8px;
          z-index: 1;
        }
      `
    }
    return `
      display: inline-block;
    `
  }}
`

const NotificationsIcon: React.FC<StyledNotificationsWrapperProps> = ({
  unreadNotifications,
}) => {
  return (
    <StyledNotificationsWrapper $showNotification={unreadNotifications > 0}>
      <Icon
        icon={deprecated.muiNotification}
        size="md"
        fill="bluesuedeshoes"
        ariaHidden
      />
    </StyledNotificationsWrapper>
  )
}

const NotificationsTrigger: React.FC<NotificationsTriggerProps> = ({
  unreadNotifications,
  toggleNotifications,
  isOpen,
}) => {
  const triggerRef = useRef<HTMLButtonElement>(null)

  const [isMounted, setMounted] = useState(false)

  useEffect(() => {
    if (!isMounted) setMounted(true)
    else if (!isOpen) triggerRef.current?.focus()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  return (
    <NotificationsButton
      ref={triggerRef}
      onClick={toggleNotifications}
      tabIndex={0}
      isOpen={isOpen}
      data-testid="notifications-trigger"
      aria-label={dropdownTriggerAriaLabel({
        menuName: 'Notifications',
        unreadCount: unreadNotifications,
        isOpen,
      })}
    >
      <DropdownTrigger copy="Notifications">
        <Box position="relative">
          <NotificationsIcon unreadNotifications={unreadNotifications} />
        </Box>
      </DropdownTrigger>
    </NotificationsButton>
  )
}

export { NotificationsTrigger }
