import React, { FC } from 'react'
import { CallUsDropdown } from './CallUsDropdown'
import { CallUsInline } from './CallUsInline'
import { CallUsSchema } from './types'
import { useProcessedSchema } from './useProcessedSchema'

export const CallUs: FC<{
  schema: CallUsSchema[]
}> = ({ schema }) => {
  const processed = useProcessedSchema(schema)

  if (processed.length === 0) return null

  if (processed.length === 1 && !processed[0].forceDropdown)
    return <CallUsInline schema={processed[0]} />

  return <CallUsDropdown schema={processed} />
}
