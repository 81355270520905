/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'
import styled from 'styled-components'
import toHTML from 'html-react-parser'
import { parseContent } from '@ally/ally-md-parser'
import {
  Box,
  Space,
  Button,
  Icon,
  TextHeading,
  TextBody,
  ScreenReaderOnly,
  useFocusOnMount,
} from '@ally/metronome-ui'
import { Coachmark, CoachmarkContent } from '../../../../providers'

type CoachmarkListProps = {
  coachmarks: Coachmark[]
  onCtaLinkClick: (coachmark: Coachmark) => void
}

type ListItemProps = {
  id: number | string
  content: CoachmarkContent
  onCtaLinkClick: () => void
  isCompleted?: boolean
  allCoachmarksCompleted?: boolean
}

const StyledList = styled.ul`
  ${({ theme: { space } }): string => `
    padding: 0;
    margin: 0;

    li:not(:first-of-type) {
      padding-top: ${space.md};
      border-top: 1px solid rgba(200, 200, 200, 0.8);
    }

    li:not(:last-of-type) {
      padding-bottom: ${space.md};
    }
  `}
`

const StyledListItem = styled.li`
  list-style: none;
`

const StyledListItemContent = styled.div<{
  isCompleted: boolean
  allCompleted: boolean
}>`
  ${({ isCompleted, allCompleted, theme: { colors, space } }): string => `
    position: relative;
    padding-left: ${allCompleted ? space.none : space.md};

    ${
      allCompleted
        ? ''
        : `
      ::before {
        content: '';
        position: absolute;
        background-color: ${isCompleted ? 'transparent' : colors.error};
        border-radius: 50%;
        height: 8px;
        width: 8px;
        top: 10px;
        left: 0;
      }
    `
    }
  `}
`

const StyleItemHeading = styled(TextHeading)`
  font-size: 16px;
`

const StyledItemBody = styled(TextBody)`
  white-space: normal;
`

const StyledCoachmarkListItem: React.FC<ListItemProps> = ({
  id,
  content,
  onCtaLinkClick,
  isCompleted,
  allCoachmarksCompleted,
}) => {
  const {
    summary: { headline, description, ctaLink },
  } = content

  return (
    <StyledListItem
      {...(isCompleted ? {} : { 'aria-label': 'unread' })}
      data-testid={`coachmark-list-item-${id}`}
    >
      <StyledListItemContent
        isCompleted={!!isCompleted}
        allCompleted={!!allCoachmarksCompleted}
      >
        {!!headline && (
          <Box display="flex" alignItems="center" mb="xxs">
            <StyleItemHeading tag="h3" size="xs">
              {headline.text}
            </StyleItemHeading>
            {!!headline.icon && (
              <Space ml="xxs">
                <Icon
                  icon={headline.icon}
                  size="sm"
                  fill="slate-5"
                  ariaHidden
                />
              </Space>
            )}
          </Box>
        )}
        <StyledItemBody
          tag="span"
          size="sm"
          weight={headline ? 'normal' : 'bold'}
        >
          {description.markdown && headline
            ? toHTML(parseContent(description.markdown))
            : description.text}
          &nbsp;
        </StyledItemBody>
        <wbr />
        <Button
          text={ctaLink.text}
          ariaLabel={ctaLink.ariaLabel}
          variant="link"
          onClick={(): void => {
            onCtaLinkClick()
          }}
        />
      </StyledListItemContent>
    </StyledListItem>
  )
}

export const HEADING_ID = 'coachmark-list-heading'

const CoachmarkList: React.FC<CoachmarkListProps> = ({
  coachmarks,
  onCtaLinkClick,
}) => {
  const headingRef = useFocusOnMount<HTMLHeadingElement>()

  const isAnyCoachmarkIncomplete = !!coachmarks.find(
    coachmark => !coachmark.data.completed,
  )

  return (
    <>
      <ScreenReaderOnly as="h2" id={HEADING_ID} ref={headingRef} tabIndex={-1}>
        What&apos;s New with Ally?
      </ScreenReaderOnly>
      <StyledList aria-labelledby={HEADING_ID} data-testid="coachmarks-list">
        {coachmarks.map(coachmark => {
          const { content, data } = coachmark

          return (
            <StyledCoachmarkListItem
              key={content.name}
              id={data.id}
              content={content}
              onCtaLinkClick={(): void => {
                onCtaLinkClick(coachmark)
              }}
              isCompleted={data.completed}
              allCoachmarksCompleted={!isAnyCoachmarkIncomplete}
            />
          )
        })}
      </StyledList>
    </>
  )
}

export { CoachmarkList }
