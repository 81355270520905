import * as React from 'react'
import styled, { DefaultTheme } from 'styled-components'
import { AccountForDropdown } from '@ally/data-selectors-afg-accounts'
import { Account, AccountStatus } from '@ally/data-types-afg-accounts'
import { maskAccountNumber } from '@ally/aos-utils'
import { TextBody, Box, Icon } from '@ally/metronome-ui'
import { deprecated } from '@ally/metronome-icons'

import {
  getCreditCardName,
  getDashDash,
  getStatus,
  getStatusDescription,
  getValueHeading,
  isRestrictedStatus,
} from './utils'
import {
  CardContainer,
  Detail,
  AccountLink,
  FormattedCurrency,
} from './DetailBar'
import { useHostServices } from '../../HostServices'
import { useAccountStatus } from '../../hooks'

interface GetAccountValueProps {
  account: AccountForDropdown
  hasStaleCCAccounts: boolean
}

const LowercaseTextBody = styled(TextBody)`
  text-transform: none;
`

// The fill gets overridden on mobile without this
const StyledIcon = styled(Icon)(
  ({ theme: { colors } }): string => `
  && path {
    fill: ${colors.warning}
  }
`,
)

const StyledDetailFooter = styled.div<{
  accountColor: keyof DefaultTheme['colors']
}>(
  ({ accountColor, theme: { colors, space } }): string => `
    display: flex;
    border-top: 1px solid ${colors['slate-2']};
    padding-left: ${space.md};
    padding-right: ${space.xs};
    padding-top: ${space.sm};
    padding-bottom: ${space.sm};

    ::before {
      content: '';
      position: absolute;
      background-color: ${colors[accountColor]};
      top: 0;
      left: 0;
      width: 7px;
      height: 100%;
      border-top: 1px solid ${colors['slate-2']};
  }
`,
)

const DetailFooter: React.FC<{
  ccName?: string
  accountColor: keyof DefaultTheme['colors']
  account: Account
}> = ({ ccName, accountColor, account }) => {
  const { status, meta } = account
  const message =
    getStatus(status) || getStatusDescription(meta?.statusDescription, ccName)

  if (message) {
    return (
      <StyledDetailFooter
        accountColor={accountColor}
        data-testid="accountsDropdown-detail-footer"
      >
        <StyledIcon
          icon={deprecated.muiWarningFill}
          fill="warning"
          size="md"
          ml="sm"
          mr="xs"
          ariaHidden
        />
        <LowercaseTextBody tag="p" weight="bold" size="xs">
          {message}
        </LowercaseTextBody>
      </StyledDetailFooter>
    )
  }
  return null
}

export const getAccountValue = ({
  account,
  hasStaleCCAccounts,
}: GetAccountValueProps): React.ReactNode | null => {
  const { balance, status: accountStatus, meta } = account

  const assistiveText = hasStaleCCAccounts
    ? "We can't update your account information right now, but we're working on it."
    : getStatus(accountStatus) || '- -'

  const shouldDisplayCurrentBalance = !!(
    getStatus(accountStatus) ||
    getStatusDescription(meta?.statusDescription) ||
    meta?.statusDescription === 'NOT_ACTIVATED'
  )

  const valueToDisplay = shouldDisplayCurrentBalance
    ? balance?.current
    : meta?.paymentDue

  if (accountStatus.toLowerCase() === 'closed') {
    return balance?.current === undefined || balance?.current === null ? (
      getDashDash(assistiveText)
    ) : (
      <FormattedCurrency balance={balance.current} />
    )
  }

  if (isRestrictedStatus(accountStatus)) {
    return getDashDash(assistiveText)
  }

  if (typeof valueToDisplay === 'number') {
    return (
      <FormattedCurrency
        balance={valueToDisplay}
        ignoreNegativeError={shouldDisplayCurrentBalance}
      />
    )
  }

  return getDashDash(assistiveText)
}

const CreditCardDetailBar: React.FC<{
  account: AccountForDropdown
  accountColor: keyof DefaultTheme['colors']
  inNavDrawer?: boolean
}> = ({ account, accountColor, inNavDrawer }) => {
  const { accountTypeLabel, nickName, accountNumber, status, lob } = account
  const { meta } = account
  const { hasStaleCCAccounts } = useAccountStatus()

  const { featureFlags } = useHostServices()
  const isCreditCardEnabled = featureFlags.variation(
    'FF_credit-card-snapshot',
    false,
  )

  const isClosedWithValue =
    account.status === AccountStatus.CLOSED &&
    typeof account.balance?.current === 'number'

  const isRestricted = isRestrictedStatus(status) && !isClosedWithValue

  const ccAccountName = `${getCreditCardName(account)} ${maskAccountNumber(
    accountNumber,
  )}`

  return (
    <Box display="flex" flexDirection="column" position="relative">
      <CardContainer accountColor={accountColor}>
        <Detail
          title={
            <TextBody tag="p" weight="bold" size="xs">
              {accountTypeLabel}
            </TextBody>
          }
          value={
            <AccountLink
              nickname={nickName}
              accountNumber={accountNumber}
              inNavDrawer={inNavDrawer}
              isRestricted={isRestricted}
            />
          }
          alignItems="flex-start"
        />
        <Detail
          title={getValueHeading({
            accountTypeLabel,
            status,
            statusDescription: meta?.statusDescription,
            lob,
            isCreditCardEnabled,
          })}
          value={getAccountValue({ account, hasStaleCCAccounts })}
          alignItems="flex-end"
        />
      </CardContainer>
      <DetailFooter
        ccName={ccAccountName}
        accountColor={accountColor}
        account={account}
      />
    </Box>
  )
}

export default CreditCardDetailBar
