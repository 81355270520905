import React from 'react'
import styled from 'styled-components'
import { Button, Media } from '@ally/metronome-ui'
import { useHostServices } from '../HostServices'
import { getLogout } from '../utils'

const LogOutButton = styled(Button)`
  color: white;
  line-height: 21px;
`

export const LogOut: React.FC = () => {
  const hostServices = useHostServices()
  const isAuthenticated = hostServices.session?.status === 'Authenticated'
  const logout = getLogout(hostServices)

  if (isAuthenticated) {
    return (
      <Media.LgUp>
        <LogOutButton
          variant="link"
          text="Log Out"
          onClick={logout}
          disableFullWidthSmDown
          allytmln="logout"
        />
      </Media.LgUp>
    )
  }
  return null
}
