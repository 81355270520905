import { createContext, useContext } from 'react'

export interface ActionBarContextValues {
  isBankCustomer?: boolean
  isDebtPlanCustomer?: boolean
  isInvestCustomer?: boolean
  isWealthCustomer?: boolean
  isMortgageCustomer?: boolean
  isCreditCardCustomer?: boolean
  isAutoCustomer?: boolean
}

export const ActionBarContext = createContext<ActionBarContextValues>({})

export const useActionBarContext = (): ActionBarContextValues =>
  useContext(ActionBarContext)
