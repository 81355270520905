import { useAccountStatus, useOutageCheck } from '../../hooks'

export const useShowNavTrigger = (): boolean => {
  const {
    hasOpenAccounts,
    hasActiveDepositAccounts,
    hasInvestAccount,
    hasDepositAccount,
    isBankOnly,
    isInvestOnly,
    hasMortgageAccount,
    isMortgageOnly,
    isCreditCardOnly,
    hasCreditCardAccount,
    hasAutoAccount,
  } = useAccountStatus()
  const {
    isBankOutage,
    isInvestOutage,
    isMortgageOutage,
    isCreditCardOutage,
  } = useOutageCheck()

  const showTrigger = (): boolean => {
    if (isBankOnly && isBankOutage) return false
    if (isInvestOnly && isInvestOutage) return false
    if (isMortgageOnly && isMortgageOutage) return false
    if (isCreditCardOnly && isCreditCardOutage) return false
    if (
      hasDepositAccount &&
      hasInvestAccount &&
      hasMortgageAccount &&
      hasCreditCardAccount &&
      isBankOutage &&
      isInvestOutage &&
      isMortgageOutage &&
      isCreditCardOutage
    ) {
      return false
    }
    return (
      hasInvestAccount ||
      hasOpenAccounts ||
      hasActiveDepositAccounts ||
      hasMortgageAccount ||
      hasCreditCardAccount ||
      hasAutoAccount
    )
  }

  return showTrigger()
}
