import React, { useState, useEffect, useCallback } from 'react'
import styled from 'styled-components'
import { deprecated } from '@ally/metronome-icons'
import {
  Box,
  Icon,
  TextBody,
  DropdownMenuDEPRECATED,
  Space,
  get,
  useFocus,
  useMediaSize,
} from '@ally/metronome-ui'
import {
  getAllAccounts,
  withAccountsLoadState,
} from '@ally/data-selectors-afg-accounts'
import { useHistory } from 'react-router-dom'
import { AccountsDetails } from './AccountDetails'
import { useOverlay } from '../../providers'
import { useHostServices } from '../../HostServices'
import { useShowAccounts } from '../../hooks'

const StyledBox = styled(Box)`
  cursor: pointer;
`

const WhiteTextBody = styled(TextBody)`
  color: ${get.colors('white')};
`

const LinkTextBody = styled(TextBody)`
  color: ${get.colors('bluesuedeshoes')};
`

const AccountsButton: React.FC = () => {
  return (
    <StyledBox display="flex" alignItems="center">
      <WhiteTextBody tag="p" size="md" weight="bold">
        Accounts
      </WhiteTextBody>
      <Space mt="xxs">
        <Icon
          icon={deprecated.muiChevronDown}
          fill="white"
          size="xs"
          ml="xxs"
          ariaHidden
        />
      </Space>
    </StyledBox>
  )
}

const JumpToButton: React.FC = () => {
  return (
    <StyledBox display="flex" alignItems="center">
      <WhiteTextBody tag="p" size="md" weight="bold">
        Jump to
      </WhiteTextBody>
      <Space mt="xxs">
        <Icon
          icon={deprecated.muiChevronDown}
          fill="white"
          size="xs"
          ml="xxs"
          ariaHidden
        />
      </Space>
    </StyledBox>
  )
}

const MenuLink: React.FC = ({ children }) => (
  <Box p="xxs">
    <LinkTextBody tag="span" size="md">
      {children}
    </LinkTextBody>
  </Box>
)

const AccountsDetailsWrapper = styled(Box)`
  border-bottom: 1px solid ${get.colors('slate-3')};
  white-space: nowrap;
`

const getAccountsLabel = (isOpen: boolean): string =>
  isOpen ? 'Close Accounts menu' : 'Open Accounts menu'

const getJumpToLabel = (isOpen: boolean): string =>
  isOpen ? 'Close Jump to menu' : 'Open Jump to menu'

/**
 * The <AccountsDropdown /> component is the button on the left side of the
 * <Header /> that includes the user's account summaries and a few links to
 * navigate around the site.
 */
const AccountsDropdown: React.FC = () => {
  const overlay = useOverlay()
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const [triggerRef, focusTrigger] = useFocus()
  const history = useHistory()

  const { featureFlags, redirectToMortgage, session } = useHostServices()
  const isGuestEnabled = featureFlags.variation('FF_guest-experience', false)
  const isGuestCustomer = session?.data?.allyUserRole?.guest
  const isBankCustomer = session?.data?.allyUserRole?.bank
  const isInvestCustomer = session?.data?.allyUserRole?.investment

  const showAccounts = useShowAccounts()

  // useCallback to keep the redirectToMortgage memoized behavior
  const handleMortgageRedirect = useCallback(
    (accountNumber?: string): void => {
      redirectToMortgage(accountNumber, {
        onCancel: () => {
          // Focus trigger when modal closes since dropdown closes on item click
          // HACK: There is some focus conflict between the closing mortgage modal
          // and trying to focus the dropdown trigger.
          setTimeout(focusTrigger, 0)
        },
      })
    },
    [redirectToMortgage, focusTrigger],
  )

  // Hide the overlay if the accounts menu unmounts and was open. This will
  // happen when the window width changes from sm to a larger viewport.
  useEffect(() => {
    return (): void => {
      if (isDropdownOpen) overlay.hide()
    }
  }, [overlay, isDropdownOpen])

  const handleMenuToggle = (isOpen: boolean): void => {
    if (isOpen) {
      overlay.show({ elevation: 2 })
      setDropdownOpen(true)
    } else {
      overlay.hide()
      setDropdownOpen(false)
    }
  }

  if (isGuestEnabled && isGuestCustomer) {
    return (
      <Box ml="sm" width="90px">
        <DropdownMenuDEPRECATED
          isFlush
          topPos={48}
          smallCaret
          caretOffsetX={34}
          onMenuToggle={handleMenuToggle}
        >
          <DropdownMenuDEPRECATED.Trigger
            ref={triggerRef}
            content={<JumpToButton />}
            ariaLabel={getJumpToLabel}
          />
          <DropdownMenuDEPRECATED.MenuContainer>
            <Box minWidth="468px" maxWidth="100vw">
              <DropdownMenuDEPRECATED.MenuItemLink
                href="/guest-dashboard"
                content={<MenuLink>Snapshot</MenuLink>}
                useRouterLink
                onSelection={(): void => history.push('/guest-dashboard')}
              />
            </Box>
          </DropdownMenuDEPRECATED.MenuContainer>
        </DropdownMenuDEPRECATED>
      </Box>
    )
  }

  if (!showAccounts) return null

  return (
    <Box ml="sm" width="90px">
      <DropdownMenuDEPRECATED
        isFlush
        topPos={48}
        smallCaret
        caretOffsetX={34}
        onMenuToggle={handleMenuToggle}
      >
        <DropdownMenuDEPRECATED.Trigger
          ref={triggerRef}
          content={<AccountsButton />}
          ariaLabel={getAccountsLabel}
        />
        <DropdownMenuDEPRECATED.MenuContainer>
          <Box minWidth="468px" maxWidth="100vw">
            <DropdownMenuDEPRECATED.MenuItemLink
              href="/dashboard"
              content={<MenuLink>View Snapshot</MenuLink>}
              useRouterLink
              onSelection={(): void => history.push('/dashboard')}
              withAllyTM="viewsnapshot"
            />
            <AccountsDetailsWrapper>
              <AccountsDetails redirectToMortgage={handleMortgageRedirect} />
            </AccountsDetailsWrapper>
            {(isBankCustomer || isInvestCustomer) && (
              <DropdownMenuDEPRECATED.MenuItemLink
                href="/payments/manage-non-ally-accounts"
                content={<MenuLink>Manage Linked Accounts</MenuLink>}
                useRouterLink
                onSelection={(): void =>
                  history.push('/payments/manage-non-ally-accounts')
                }
                withAllyTM="managelinkedaccounts"
              />
            )}
            <DropdownMenuDEPRECATED.MenuItemLink
              href="/products-and-rates"
              content={<MenuLink>Explore Products and Rates</MenuLink>}
              useRouterLink
              onSelection={(): void => history.push('/products-and-rates')}
              withAllyTM="exploreproductsandrates"
            />
            <DropdownMenuDEPRECATED.MenuItemLink
              href="/products-and-rates"
              content={<MenuLink>Open Account</MenuLink>}
              useRouterLink
              onSelection={(): void => history.push('/products-and-rates')}
              withAllyTM="openanaccount"
            />
          </Box>
        </DropdownMenuDEPRECATED.MenuContainer>
      </DropdownMenuDEPRECATED>
    </Box>
  )
}

const Accounts: React.FC = () => {
  const { hostData, session, globalnav } = useHostServices()
  const depositAndInvestCata = withAccountsLoadState(getAllAccounts)(hostData)
  const isAuthenticated = session?.status === 'Authenticated'
  const isHidden = globalnav?.isHidden
  const isMdUp = useMediaSize('MdUp')

  if (isAuthenticated && !isHidden && isMdUp) {
    return depositAndInvestCata({
      unloaded: () => null,
      loading: () => null,
      loaded: () => <AccountsDropdown />,
      failed: () => null,
    })
  }
  return null
}

export { Accounts }
