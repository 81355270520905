import React, { FC } from 'react'
import { Trigger } from '@ally/shared-help-modal'
import { useHostServices } from '../HostServices'

/**
 * Shared HelpModal modal component to use throughout Host application.
 *
 * Example usage
 * ```tsx
 * import { SharedHelpModal } from './SharedHelpModal'
 * <SharedHelpModal />
 * <SharedHelpModal variant="dark" />
 * <SharedHelpModal asIcon={true} />
 * ```
 */
export const SharedHelpModal: FC<{
  variant?: 'dark' | 'light'
  asIcon?: boolean
}> = ({ variant = 'dark', asIcon = false }) => {
  const {
    utils: { helpModalState, setHelpModalState },
  } = useHostServices()

  return (
    <Trigger
      variant={variant}
      asIcon={asIcon}
      onClick={(): void =>
        setHelpModalState({
          ...helpModalState,
          status: helpModalState.status === 'Closed' ? 'Open' : 'Closed',
        })
      }
    />
  )
}
