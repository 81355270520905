/**
 * LogRocket tracking event names.
 * All custom events sent to LogRocket should be listed in the enum below rather
 * than using a string (if the event name is static).
 */

export enum TrackingEvent {
  // ------------------------------------------------------------------------ //
  // Bootstrapping Events
  // ------------------------------------------------------------------------ //
  BootstrapInit = 'ally-next-host-bootstrap-init',
  LDAutoInit = 'ally-next-host-bootstrap-ld-auto-init',
  LDAutoDone = 'ally-next-host-bootstrap-ld-auto-done',
  LDBankInit = 'ally-next-host-bootstrap-ld-bank-init',
  LDBankDone = 'ally-next-host-bootstrap-ld-bank-done',
  LDRoutingInit = 'ally-next-host-bootstrap-ld-routing-init',
  LDRoutingDone = 'ally-next-host-bootstrap-ld-routing-done',
  RehydrateInit = 'ally-next-host-bootstrap-rehydrate-init',
  RehydrateDone = 'ally-next-host-bootstrap-rehydrate-done',
  BootstrapDone = 'ally-next-host-bootstrap-done',

  // ------------------------------------------------------------------------ //
  // Route Schema Loading
  // ------------------------------------------------------------------------ //
  LDRouteFailure = 'ally-next-host-ld-route-failure',
  FallbackRoutesInit = 'ally-next-host-fallback-routes-init',
  FallbackRoutesDone = 'ally-next-host-fallback-routes-done',
  FallbackRoutesError = 'ally-next-host-fallback-routes-error',

  // ------------------------------------------------------------------------ //
  // Generic Remote Transition Events
  // ------------------------------------------------------------------------ //
  RemoteError = 'ally-next-remote-error',
  RemoteMount = 'ally-next-remote-mount',
  RemoteMounted = 'ally-next-remote-mounted',
  RemoteUnmount = 'ally-next-remote-unmount',
  RemoteUnmounted = 'ally-next-remote-unmounted',

  // ------------------------------------------------------------------------ //
  // Host Events
  // ------------------------------------------------------------------------ //
  HostInit = 'ally-next-host-init',
  HostSideC = 'ally-next-host-side-c',
  HostSideD = 'ally-next-host-side-d',
  HostReady = 'ally-next-host-ready',

  // ------------------------------------------------------------------------ //
  // Host Services
  // ------------------------------------------------------------------------ //
  MortgageRedirect = 'ally-next-host-mortgage-redirect',

  // ------------------------------------------------------------------------ //
  // Debug Events
  // ------------------------------------------------------------------------ //
  KeycodesEnabled = 'ally-next-host-keycodes-enabled',
  DebugPanelOpened = 'ally-next-host-debug-panel-opened',
  FeatureFlagPanelOpened = 'ally-next-host-ff-panel-opened',

  // ------------------------------------------------------------------------ //
  // Transmit Events
  // ------------------------------------------------------------------------ //
  AddAllyIdDone = 'ally-next-host-add-ally-id-done',
  AddAllyIdError = 'ally-next-host-add-ally-id-error',
  AddAllyIdInit = 'ally-next-host-add-ally-id-init',
  LegacySessionDone = 'ally-next-host-legacy-session-done',
  LegacySessionInit = 'ally-next-host-legacy-session-init',
  RefreshAccessTokenDone = 'ally-next-host-refresh-access-token-done',
  RefreshAccessTokenError = 'ally-next-host-refresh-access-token-error',
  TransferTicketDone = 'ally-next-host-transfer-ticket-done',
  TransferTicketError = 'ally-next-host-transfer-ticket-error',
  // ------------------------------------------------------------------------ //
  // AAOS Login Events
  // ------------------------------------------------------------------------ //
  AAOSKeepAliveDone = 'ally-next-host-aaos-keep-alive-done',
  AAOSKeepAliveError = 'ally-next-host-aaos-keep-alive-error',
  AAOSKeepAliveInit = 'ally-next-host-aaos-keep-alive-init',
  AAOSLoginDone = 'ally-next-host-aaos-login-done',
  AAOSLoginError = 'ally-next-host-aaos-login-error',
  AAOSLoginInit = 'ally-next-host-aaos-login-init',
  AAOSLogoutDone = 'ally-next-host-aaos-logout-done',
  AAOSLogoutError = 'ally-next-host-aaos-logout-error',
  AAOSLogoutInit = 'ally-next-host-aaos-logout-init',
}
