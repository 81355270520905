import { useState, useMemo, useCallback } from 'react'
import {
  getNotifications,
  NotificationsConfig,
  NotificationTypes,
  RemoteConfig,
  AccountWithAcknowledgement,
  MboxType,
} from './utils'
import { useAuthenticatedMboxCall } from './useAuthenticatedMboxCall'
import { useAutoPendingExtensions } from './use-auto-pending-extensions'

export interface NotificationsDropdownValues {
  notifications: NotificationsConfig[]
  hasNoNotifications: boolean
  unreadNotifications: number
  isNotificationsOpen: boolean
  hasDismissedNotifications: boolean
  closeNotifications: () => void
  toggleNotifications: () => void
  setNotificationsOpen: (isOpen: boolean) => void
  setNotificationsDismissed: (isDismissed: boolean) => void
}

type NotificationsDropdownParams = {
  types: NotificationTypes
  manageableCds: AccountWithAcknowledgement[]
  hasBankMessages: boolean
  remoteNotifications: RemoteConfig[]
}

export const useNotificationsDropdown = ({
  types,
  manageableCds,
  hasBankMessages,
  remoteNotifications,
}: NotificationsDropdownParams): NotificationsDropdownValues => {
  const [isNotificationsOpen, setNotificationsOpen] = useState(false)
  const [hasDismissedNotifications, setNotificationsDismissed] = useState(false)

  const adobeNotifications = useAuthenticatedMboxCall(
    'ally-dashboard-notifications',
    {},
  ).mboxData as MboxType[]

  const autoPendingExtensions = useAutoPendingExtensions()

  const closeNotifications = (): void => {
    setNotificationsDismissed(true)
    setNotificationsOpen(false)
  }

  const toggleNotifications = useCallback(() => {
    setNotificationsDismissed(true)
    setNotificationsOpen(!isNotificationsOpen)
  }, [isNotificationsOpen, setNotificationsOpen, setNotificationsDismissed])

  const notifications = useMemo(
    () =>
      getNotifications(
        types,
        adobeNotifications,
        manageableCds,
        hasBankMessages,
        remoteNotifications,
        autoPendingExtensions,
      ),
    [
      types,
      adobeNotifications,
      manageableCds,
      hasBankMessages,
      remoteNotifications,
      autoPendingExtensions,
    ],
  )

  const hasNoNotifications = useMemo(() => {
    const hasNoRemoteNotifications = !remoteNotifications.length
    return (
      hasNoRemoteNotifications &&
      !!notifications.find(({ type }) => type === 'NO_NOTIFICATIONS')
    )
  }, [notifications, remoteNotifications])

  const unreadNotifications = useMemo(() => {
    return hasDismissedNotifications
      ? 0
      : notifications.filter(({ type }) => type !== 'NO_NOTIFICATIONS')
          ?.length || 0
  }, [notifications, hasDismissedNotifications])

  return {
    notifications,
    hasNoNotifications,
    unreadNotifications,
    isNotificationsOpen,
    hasDismissedNotifications,
    closeNotifications,
    toggleNotifications,
    setNotificationsOpen,
    setNotificationsDismissed,
  }
}
