import {
  deposit as afgDeposit,
  invest as afgInvest,
  mortgage as afgMortgage,
  auto,
  creditCard,
} from '@ally/data-selectors-afg-accounts'
import { useIsOnly, useLobs } from '..'
import { useHostServices } from '../../HostServices'

type HostAccountStatus = {
  hasOpenAccounts: boolean
  hasOpenDepositAccounts: boolean
  hasActiveDepositAccounts: boolean
  hasDebitCardEligibleAccounts: boolean
  hasInvestAccount: boolean
  hasDepositAccount: boolean
  hasMortgageAccount: boolean
  hasCreditCardAccount: boolean
  hasTrusteeOnlyAccounts: boolean
  hasNoAccounts: boolean
  isBankOnly: boolean
  isInvestOnly: boolean
  isCreditCardOnly: boolean
  isMortgageOnly: boolean
  hasStaleBankAccounts: boolean
  hasStaleInvestAccounts: boolean
  hasStaleCCAccounts: boolean
  hasStaleAutoAccounts: boolean
  hasAutoAccount: boolean
  isAutoScheduledMaintenance: boolean
  hasOnlyCDorIRA: boolean
}

export const useAccountStatus = (): HostAccountStatus => {
  const { hostData } = useHostServices()
  const { notifications } = hostData
  const lobRoles = useLobs()

  const openDepositAccounts = afgDeposit.getOpenInternal(hostData)
  const openInvestAccounts = afgInvest.getOpen(hostData)
  const openMortgageAccounts = afgMortgage.getOpen(hostData)

  const hasActiveDepositAccounts = afgDeposit.getIsActive(hostData)
  const hasTrusteeOnlyAccounts = afgDeposit.getHasTrusteeOnly(hostData)
  const openDebitAccounts = afgDeposit.getDebitCardEligible(hostData) ?? []

  // all accounts whether open, closed or retired
  const allInternalDepositAccounts = afgDeposit
    .getAll(hostData)
    .filter(acct => !acct.indicator?.external)
  const allInvestAccounts = afgInvest
    .getAll(hostData)
    .filter(acct => !acct.indicator?.external)
  const allCCAccounts = creditCard.getAll(hostData)
  const allAutoAccounts = auto.getAll(hostData)

  const hasOpenDepositAccounts = !!(
    openDepositAccounts && openDepositAccounts.length > 0
  )

  const hasOpenInvestAccounts = !!(
    openInvestAccounts && openInvestAccounts.length > 0
  )

  const hasDebitCardEligibleAccounts = !!(
    openDebitAccounts && openDebitAccounts.length > 0
  )

  const hasOpenMortgageAccounts = !!(
    openMortgageAccounts && openMortgageAccounts.length > 0
  )

  const hasInvestAccount = lobRoles.investment
  const hasDepositAccount = lobRoles.bank
  const hasMortgageAccount = lobRoles.mortgage
  const hasAutoAccount = lobRoles.auto
  const hasCreditCardAccount = lobRoles.creditcard

  const hasOpenAccounts =
    hasOpenDepositAccounts || hasOpenInvestAccounts || hasOpenMortgageAccounts

  const isBankOnly = useIsOnly('bank')
  const isInvestOnly = useIsOnly('investment')
  const isMortgageOnly = useIsOnly('mortgage')
  const isCreditCardOnly = useIsOnly('creditcard')

  const hasNoAccounts = !hasInvestAccount && !hasDepositAccount

  const hasStaleBankAccounts = allInternalDepositAccounts.some(
    acct => !!acct.lastUpdated,
  )
  const hasStaleInvestAccounts = allInvestAccounts.some(
    acct => !!acct.lastUpdated,
  )
  const hasStaleCCAccounts = allCCAccounts.some(acct => !!acct.lastUpdated)

  const hasStaleAutoAccounts = allAutoAccounts.some(acct => !!acct.lastUpdated)

  const isAutoScheduledMaintenance =
    hasAutoAccount &&
    Boolean(
      notifications?.data?.notifications.some(
        notification =>
          notification.type === 'ADVANTAGE_ENDOFDAY' &&
          notification.targetLob.includes('AUTO') &&
          notification.isActive,
      ),
    )

  const hasOnlyCDorIRA = openDepositAccounts?.every?.(depositAccount => {
    return (
      depositAccount.type === 'CDA' ||
      (depositAccount.indicator?.retirement ?? false)
    )
  })

  return {
    hasOpenAccounts,
    hasOpenDepositAccounts,
    hasDebitCardEligibleAccounts,
    hasActiveDepositAccounts,
    hasInvestAccount,
    hasDepositAccount,
    hasMortgageAccount,
    hasTrusteeOnlyAccounts,
    hasNoAccounts,
    isBankOnly,
    isInvestOnly,
    isMortgageOnly,
    isCreditCardOnly,
    hasCreditCardAccount,
    hasStaleBankAccounts,
    hasStaleInvestAccounts,
    hasStaleCCAccounts,
    hasStaleAutoAccounts,
    hasAutoAccount,
    isAutoScheduledMaintenance,
    hasOnlyCDorIRA,
  }
}
