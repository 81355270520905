import React, { useContext, useMemo } from 'react'
import cookies from 'js-cookie'
import { SessionContext } from '../../providers'
import {
  fetchPrivacyPreferences,
  updateCpraPreference,
} from '../../utils/fetch'
import { useGetFeatureFlag, FeatureFlag } from '../../hooks'
import { logRocket } from '../../log-rocket'

/**
 * Attempts to persist CPRA privacy preferences from storefront.
 * Persists the value of a user selection stored in a 'CPRACHOICE' cookie
 * if it's different than their existing settings.
 *
 * Alerts ETM either way about the retrieval so that the CPRA
 * tracking cookie can be set on the browser
 */
const CPRAPreference: React.FC = () => {
  const session = useContext(SessionContext)
  const getFeatureFlag = useGetFeatureFlag()

  useMemo(async () => {
    if (!getFeatureFlag(FeatureFlag.CPRAEnabled, false)) return

    if (session.status === 'Authenticated') {
      const cpraChoice = cookies.get('CPRACHOICE')

      // query param is required by the API to target
      // our internal data store. it returns an invalid
      // sessionId, however, so if we need to PUT,
      // we first have to issue another GET.
      fetchPrivacyPreferences(session.data, { cpraDataSource: 'ally' })
        .then(pref => {
          if (
            cpraChoice !== undefined &&
            pref.payload?.cpra.optIn !== cpraChoice
          ) {
            return fetchPrivacyPreferences(session.data).then(val =>
              updateCpraPreference(
                session.data,
                cpraChoice === 'Y' ? 'Y' : 'N',
                val.payload?.sessionId as string,
              ),
            )
          }

          return pref
        })
        .then(val => {
          window.allytm.event('customEvent', {
            name: 'CPRA Existing Preferences',
            attr: {
              deviceIds: ['unused'],
              optIn: val.payload?.cpra.optIn,
            },
          })
        })
        .catch(e => {
          window.allytm.event(
            'customError',
            `failed to persist user CPRA preference: ${e}`,
          )
          logRocket.captureException(e as Error)
        })
    }
  }, [session.status])

  // there is no presentation in this component
  return null
}

export default CPRAPreference
