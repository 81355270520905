import { useEffect, useState } from 'react'
import { Coachmark } from '.'
import { useHostServices } from '../../HostServices'

interface UseWhatsNewImpressionOpts {
  automaticOpen: boolean
  displayedCoachmarks: Coachmark[] | []
}

interface WhatsNewImpressionItem {
  name: 'Whats New Impression'
  type: 'whatsNew'
  attributes: {
    automaticOpen: boolean
    componentID: string
    componentName: string
    cta: string
    description: string
  }
}

export const formatName = (name: string): string => {
  return name
    .split(/[^a-zA-Z0-9]/g)
    .join(' ')
    .toUpperCase()
}

const mapCoachmarks = (
  coachmarks: Coachmark[],
  automaticOpen: boolean,
): WhatsNewImpressionItem[] => {
  return coachmarks.map(
    ({ content }: Coachmark): WhatsNewImpressionItem => {
      const {
        name,
        summary: { ctaLink, description },
      } = content

      return {
        name: 'Whats New Impression',
        type: 'whatsNew',
        attributes: {
          automaticOpen,
          componentID: name,
          componentName: formatName(name),
          cta: ctaLink.text,
          description: description.text,
        },
      }
    },
  )
}

export const useWhatsNewImpression = ({
  automaticOpen,
  displayedCoachmarks,
}: UseWhatsNewImpressionOpts): void => {
  const { analytics } = useHostServices()
  const { trackImpression } = analytics
  const [hasBeenCalled, setHasBeenCalled] = useState(false)
  const trackWhatsNew = (): void =>
    trackImpression({
      eventType: 'impressionEvent',
      impressions: {
        name: 'dashboardImpressionEvent',
        contents: mapCoachmarks(displayedCoachmarks, automaticOpen),
      },
    })

  useEffect(() => {
    if (displayedCoachmarks.length && !hasBeenCalled) {
      trackWhatsNew()
      setHasBeenCalled(true)
    }
  }, [displayedCoachmarks.length, hasBeenCalled])
}
