import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { deprecated } from '@ally/metronome-icons'
import {
  Box,
  DropdownMenuDEPRECATED,
  get,
  Icon,
  Space,
  TextBody,
  useMediaSize,
} from '@ally/metronome-ui'
import { useHistory } from 'react-router-dom'
import { useOverlay } from '../../providers'
import { useHostServices } from '../../HostServices'

const StyledBox = styled(Box)`
  cursor: pointer;
`

const WhiteTextBody = styled(TextBody)`
  color: ${get.colors('white')};
`

const LinkTextBody = styled(TextBody)`
  color: ${get.colors('bluesuedeshoes')};
`

const ExploreButton: React.FC = () => {
  return (
    <StyledBox display="flex" alignItems="center">
      <WhiteTextBody tag="p" size="md" weight="bold">
        Explore
      </WhiteTextBody>
      <Space mt="xxs">
        <Icon
          icon={deprecated.muiChevronDown}
          fill="white"
          size="xs"
          ml="xxs"
          ariaHidden
        />
      </Space>
    </StyledBox>
  )
}

const MenuLink: React.FC = ({ children }) => (
  <Box p="xxs">
    <LinkTextBody tag="span" size="md">
      {children}
    </LinkTextBody>
  </Box>
)

const ExploreDropdown: React.FC = () => {
  const { globalnav, session, featureFlags } = useHostServices()
  const history = useHistory()
  const overlay = useOverlay()
  const isGuestCustomer = !!session?.data?.allyUserRole?.guest
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const isAuthenticated = session?.status === 'Authenticated'
  const isGuestEnabled = featureFlags.variation('FF_guest-experience', false)
  const isMdUp = useMediaSize('MdUp')
  useEffect(() => {
    return (): void => {
      if (isDropdownOpen) overlay.hide()
    }
  }, [overlay, isDropdownOpen])

  const handleMenuToggle = (isOpen: boolean): void => {
    if (isOpen) {
      overlay.show({ elevation: 2 })
      setDropdownOpen(true)
    } else {
      overlay.hide()
      setDropdownOpen(false)
    }
  }

  const getAriaLabel = (isOpen: boolean): string =>
    isOpen ? 'Close Explore menu' : 'Open Explore menu'

  if (
    !globalnav?.isHidden &&
    isAuthenticated &&
    isGuestEnabled &&
    isGuestCustomer &&
    isMdUp
  )
    return (
      <Box ml="sm" width="90px">
        <DropdownMenuDEPRECATED
          isFlush
          topPos={48}
          smallCaret
          caretOffsetX={34}
          onMenuToggle={handleMenuToggle}
        >
          <DropdownMenuDEPRECATED.Trigger
            content={<ExploreButton />}
            ariaLabel={getAriaLabel}
          />
          <DropdownMenuDEPRECATED.MenuContainer>
            <Box minWidth="468px" maxWidth="100vw">
              <DropdownMenuDEPRECATED.MenuItemLink
                href="/products-and-rates"
                content={<MenuLink>Investments</MenuLink>}
                useRouterLink
                onSelection={(): void => history.push('/products-and-rates')}
              />
              <DropdownMenuDEPRECATED.MenuItemLink
                href="/products-and-rates"
                content={<MenuLink>Bank Accounts</MenuLink>}
                useRouterLink
                onSelection={(): void => history.push('/products-and-rates')}
              />
              <DropdownMenuDEPRECATED.MenuItemLink
                href="/products-and-rates"
                content={<MenuLink>Home Loans</MenuLink>}
                useRouterLink
                onSelection={(): void => history.push('/products-and-rates')}
              />
              <DropdownMenuDEPRECATED.MenuItemLink
                href="/products-and-rates"
                content={<MenuLink>Plans</MenuLink>}
                useRouterLink
                onSelection={(): void => history.push('/products-and-rates')}
              />
              <DropdownMenuDEPRECATED.MenuItemLink
                href="/products-and-rates"
                content={<MenuLink>View All</MenuLink>}
                useRouterLink
                onSelection={(): void => history.push('/products-and-rates')}
              />
            </Box>
          </DropdownMenuDEPRECATED.MenuContainer>
        </DropdownMenuDEPRECATED>
      </Box>
    )
  return null
}

export default ExploreDropdown
