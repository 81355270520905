export const getInvestTitle = (
  isInvestCustomer?: boolean,
  isWealthCustomer?: boolean,
  isPersonalAdviceEnabled = false,
): string => {
  const isBoth = isWealthCustomer && isInvestCustomer
  const investTitle = 'Investments'
  const wealthTitle = isPersonalAdviceEnabled
    ? 'Personal Advice'
    : 'Wealth Management'
  const getCombinedTitle = (): string => {
    if (isBoth) return `${investTitle} and ${wealthTitle}`
    if (isWealthCustomer) return wealthTitle
    return investTitle
  }

  return getCombinedTitle()
}
