import React, { useState, useMemo } from 'react'
import styled, { ThemeProvider, css } from 'styled-components'
import {
  Box,
  Link as MuiLink,
  Button,
  CloseButton,
  CloseButtonSpacer,
  ScreenReaderOnly,
  useFocusOnMount,
  TextBody,
  Space,
  get,
  muiBankTheme,
} from '@ally/metronome-ui'
import useKeyPress from '@ally/use-key-press'
import { v4 as uuid } from 'uuid'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useActionBar, NotificationsConfig } from '../../providers'
import { AdobeNotification } from './Adobe'
import { useHostServices } from '../../HostServices'

interface NotificationsProps {
  notifications: NotificationsConfig[]
  toggleNotifications: () => void
}

const StyledNotifications = styled(Box)`
  ${({ theme: { colors } }): string => `
    background-color: ${colors.white};
    border: 1px solid ${colors['slate-3']};
    position: relative;
    width: 100%
  `}
`

const StyledInnerNotificationContainer = styled(Box)`
  ${({ theme: { space } }): string => `
    padding: ${space.md};
  `}
`

const StyledAdobeNotification = styled.div`
  ${({ theme: { colors, fontSizes, fonts, space } }): string => `
    font-family: ${fonts.regular};
    font-size: ${fontSizes.md};
    color: ${colors['slate-5']};
    line-height: 1.4;
    list-style-type: none;
    outline: none;

    a {
      background-color: transparent;
      border-color: transparent;
      font-size: ${fontSizes.md};
      font-weight: bold;
      color: ${colors.bluesuedeshoes};
      cursor: pointer;
      border: none;
      border-style: none;
      text-decoration: none;
    }

    li {
      padding-bottom: ${space.md};
    }

    li:last-of-type {
      padding-bottom: 0;
    }
  `}
`

const StyledLink = styled(MuiLink)(
  () => css`
    a {
      font-size: ${get.fontSizes('md')};
    }
  `,
)

const RemainingNotificationsButton = styled(Button)`
  width: 100%;
  margin-top: ${get.space('md')};
  border-top: 1px solid ${get.colors('bluesuedeshoes')};
  height: 37px;
  justify-content: flex-start;
  padding-top: ${get.space('md')};
`

const NotificationLink = (props: {
  content: NotificationsConfig
  className?: string
}): React.ReactElement => {
  const { content, className } = props
  const { submitHasAcknowledgedCds } = useActionBar()
  const { featureFlags } = useHostServices()
  const isReactEtinEnabled = featureFlags.variation('FF_react-etin', false)

  switch (content.type) {
    case 'AUTO_PENDING_EXTENSION': {
      return (
        <TextBody className={className} tag="p" size="md">
          <TextBody tag="span" size="md">
            Your Pending Extension for {content.nickname} &bull;&bull;
            {content.lastFour} needs your electronic signature by{' '}
            {content.formattedDate}.
          </TextBody>
          &nbsp;
          <StyledLink text="Review and Sign">
            <ReactRouterLink
              to={`/sso/auto/document-center/${content.accountId}/all-documents`}
            />
          </StyledLink>
        </TextBody>
      )
    }
    case 'CD_MULTIPLE': {
      const handleAcknowledgeCds = (): void => {
        if (content.unAcknowledgedCds.length > 0) {
          // We have to acknowledge ALL CDs if the user has ANY that are unacknowledged.
          // not sure why we wouldn't want to just send the unacknowledged ones...
          submitHasAcknowledgedCds(content.cdId)
        }
      }

      return (
        <TextBody className={className} tag="p" size="md">
          <TextBody tag="span" size="md">
            {content.prefix}
          </TextBody>
          &nbsp;
          <StyledLink text="Manage CDs" allytmln="manage cds">
            <ReactRouterLink
              to="/bank/manage-cds"
              onClick={handleAcknowledgeCds}
            />
          </StyledLink>
        </TextBody>
      )
    }
    case 'CD': {
      const handleAcknowledgeCd = (): void => {
        if (!content.isAcknowledged) {
          submitHasAcknowledgedCds(content.cdId || '')
        }
      }

      return (
        <TextBody className={className} tag="p" size="md">
          <TextBody tag="span" size="md" weight="bold">
            {content.accountData}
          </TextBody>
          <TextBody tag="span" size="md">
            {content.prefix}
          </TextBody>
          &nbsp;
          <StyledLink text="Manage CD" allytmln="manage cd">
            <ReactRouterLink
              to="/bank/manage-cds"
              onClick={handleAcknowledgeCd}
            />
          </StyledLink>
        </TextBody>
      )
    }
    case 'ADOBE': {
      if (content.contentType === 'html') {
        return (
          <StyledAdobeNotification
            className={className}
            dangerouslySetInnerHTML={{ __html: content.html }}
            tabIndex={-1}
          />
        )
      }
      return <AdobeNotification content={content.json} className={className} />
    }
    case 'ETIN':
      return (
        <TextBody className={className} tag="p" size="md">
          <TextBody tag="span" size="md">
            {content.prefix}
          </TextBody>
          &nbsp;
          <StyledLink
            text="certify the Taxpayer Identification Number "
            allytmln="certifyYourTIN"
          >
            <ReactRouterLink
              to={
                isReactEtinEnabled
                  ? '/interrupts/certify-tin'
                  : '/bank/certify-tin'
              }
            />
          </StyledLink>
          &nbsp;
          <TextBody tag="span" size="md">
            {content.affix}
          </TextBody>
        </TextBody>
      )

    case 'REMOTE':
      return content.innerContent
    case 'BANK_MESSAGE':
      return (
        <TextBody className={className} tag="p" size="md">
          <TextBody tag="span" size="md">
            {content.prefix}
          </TextBody>
          &nbsp;
          <StyledLink text={content.link.content}>
            <ReactRouterLink to={content.link.path} />
          </StyledLink>
        </TextBody>
      )
    case 'NO_NOTIFICATIONS':
    default:
      return (
        <TextBody className={className} tag="p" size="md">
          <TextBody tag="span" size="md">
            {content.prefix}
          </TextBody>
        </TextBody>
      )
  }
}

const INITIAL_NOTIFICATIONS_AMOUNT = 3
const NotificationsDropdown: React.FC<NotificationsProps> = ({
  notifications: unidentifiedNotifications,
  toggleNotifications,
}) => {
  const srHeadingRef = useFocusOnMount()

  /**
   * Adds 'allyWebId' key and unique value to each notification object
   */
  const notifications = useMemo(
    () =>
      (unidentifiedNotifications as NotificationsConfig[]).map(i => ({
        ...i,
        allyWebId: uuid(),
      })),
    [unidentifiedNotifications],
  )
  // this will only be mounted when the notifications are showing (will always toggle close)
  useKeyPress(['Escape'], toggleNotifications)

  const [numNotificationsToShow, setNumNotificationsToShow] = useState<number>(
    INITIAL_NOTIFICATIONS_AMOUNT,
  )
  const remainingNotifications = notifications.length - numNotificationsToShow

  const renderedNotifications = useMemo(
    () => notifications.slice(0, numNotificationsToShow),
    [numNotificationsToShow, notifications],
  )

  const showRemainingNotifications = (): void => {
    setNumNotificationsToShow(notifications.length)
  }
  const isRemainingNotificationsButtonVisible =
    numNotificationsToShow < notifications.length

  return (
    <ThemeProvider theme={muiBankTheme}>
      <StyledNotifications data-testid="notifications-container">
        <CloseButtonSpacer />
        <ScreenReaderOnly ref={srHeadingRef} as="h2" tabIndex={-1}>
          Notifications
        </ScreenReaderOnly>
        <StyledInnerNotificationContainer>
          {renderedNotifications?.map(
            (item, i): React.ReactElement => (
              <Space
                pb={i === renderedNotifications.length - 1 ? '' : 'md'}
                key={item.allyWebId}
              >
                <NotificationLink content={item} />
              </Space>
            ),
          )}

          {isRemainingNotificationsButtonVisible && (
            <RemainingNotificationsButton
              variant="link"
              text={`View ${remainingNotifications} More Notification${
                remainingNotifications > 1 ? 's' : ''
              }.`}
              onClick={showRemainingNotifications}
            />
          )}
          <CloseButton
            onClick={toggleNotifications}
            data-testid="notifications-close"
          />
        </StyledInnerNotificationContainer>
      </StyledNotifications>
    </ThemeProvider>
  )
}

export { NotificationsDropdown }
