import React, { useEffect, useMemo } from 'react'
import { useHistory, Link as ReactRouterLink } from 'react-router-dom'
import styled, { css, ThemeProvider } from 'styled-components'
import { MenuItem } from 'react-aria-menubutton'
import { Box, Link as MuiLink, muiBankTheme } from '@ally/metronome-ui'
import {
  AccountLOB,
  AccountStatus,
  AccountType,
} from '@ally/data-types-afg-accounts'
import {
  deposit,
  invest,
  mortgage,
  auto,
  creditCard,
  AccountForDropdown,
  AccountTypeLabel,
} from '@ally/data-selectors-afg-accounts'
import { RelationshipStatusTypes } from '@ally/transmitigator'

import { trackCCAccount, getBankruptcyAcceptedSessionProp } from '../../utils'
import DetailBar from './DetailBar'
import CreditCardDetailBar from './CreditCardDetailBar'
import Error from './Error'
import { useCustomization } from './use-customization'
import { isRestrictedStatus, mapColors, accountCardAllyTM } from './utils'
import StaleDataNotice from './StaleDataNotice'
import { useHostServices } from '../../HostServices'
import { useAccountStatus, useOutageCheck } from '../../hooks'

const mapInvestAccount = (
  account: AccountForDropdown,
  inNavDrawer?: boolean,
): React.ReactElement => {
  const { id, type } = account
  const accountColor = mapColors(type)
  return (
    <DetailBar
      account={account}
      accountColor={accountColor}
      key={`detail-${id}`}
      inNavDrawer={inNavDrawer}
      invest
    />
  )
}

const mapDepositAccount = (
  account: AccountForDropdown,
  inNavDrawer?: boolean,
): React.ReactElement => {
  const { type, indicator, id } = account
  const accountColor = mapColors(type, indicator?.retirement)

  return (
    <DetailBar
      account={account}
      accountColor={accountColor}
      key={`detail-${id}`}
      inNavDrawer={inNavDrawer}
    />
  )
}

const linkStyles = css`
  text-decoration: none;
  a {
    width: 100%;
    justify-content: flex-start;
    text-decoration: none;
  }
`

const StyledLink = styled(MuiLink)`
  ${linkStyles}
`

const StyledMenuItem = styled(MenuItem)`
  ${linkStyles}
`

const MortgageNavMenuButton = styled.button`
  ${linkStyles}

  background-color: transparent;
  padding: 0;
  border: none;
  display: flex;
  cursor: pointer;
  margin: 0;
  width: 100%;
  white-space: nowrap;
  > div {
    width: 100%;
  }
`

const mapAccountCards = <T extends AccountForDropdown>(
  accounts: T[] | null,
  getAccountUrl: (account: T) => string,
  mapper: (account: T, inNavDrawer?: boolean) => React.ReactElement,
  history: ReturnType<typeof useHistory>,
  inNavDrawer?: boolean,
  redirectToMortgage?: (accountNumber?: string) => void,
): React.ReactElement[] | undefined => {
  return accounts?.map?.(account => {
    const accountUrl = getAccountUrl(account)
    const content = mapper(account, inNavDrawer)

    const isMortgage = !!redirectToMortgage

    const { featureFlags } = useHostServices()

    const isCreditCardEnabled = featureFlags.variation(
      'FF_credit-card-snapshot',
      false,
    )

    const isCCAccount = isCreditCardEnabled && account.lob === AccountLOB.CC

    const onRouterLinkClick = (
      event: React.MouseEvent<HTMLAnchorElement>,
    ): void => {
      event.preventDefault()
      history.push(accountUrl)
    }

    const accountId = account.id

    if (isCreditCardEnabled) {
      // We don't want to treat a CLOSED account as restricted if it has a balance
      const isClosedWithValue =
        account.status === AccountStatus.CLOSED &&
        typeof account.balance?.current === 'number'

      const isRestrictedCCStatus =
        account.lob === AccountLOB.CC &&
        isRestrictedStatus(account.status) &&
        !isClosedWithValue

      if (isRestrictedCCStatus) {
        if (inNavDrawer) {
          return (
            <Box
              key={accountId}
              tabIndex={0}
              data-allytmln={accountCardAllyTM(account)}
            >
              {content}
            </Box>
          )
        }
        return (
          <MenuItem
            key={accountId}
            tabIndex={0}
            data-testid={`account-dropdown-${account.accountTypeLabel}`}
            onClick={(): void => trackCCAccount(account)}
            data-allytmln={accountCardAllyTM(account)}
          >
            {content}
          </MenuItem>
        )
      }
    }

    if (inNavDrawer) {
      if (isMortgage) {
        // ? [REVISIT] INVALID HTML - requires refactor of <DetailBar> for valid HTML
        // ? this cannot be a button because a button cannot have any div as a descendant
        return (
          <MortgageNavMenuButton
            onClick={(): void => redirectToMortgage?.()}
            key={accountId}
            data-allytmln={accountCardAllyTM(account)}
          >
            {content}
          </MortgageNavMenuButton>
        )
      }
      return (
        <StyledLink unsupportedContent={content}>
          <ReactRouterLink
            to={accountUrl}
            key={accountId}
            tabIndex={0}
            data-allytmln={accountCardAllyTM(account)}
          />
        </StyledLink>
      )
    }

    if (isMortgage) {
      return (
        /* This mortgage trigger is staying an anchor since the role ends up 'menuitem' anyway.
           Also, this MenuItem's onClick does not work unless it's an anchor tag due to limitations with
           `react-aria-menubutton`
        */
        <StyledMenuItem
          tag="a"
          href="#"
          onClick={(e): void => {
            e.preventDefault()
            redirectToMortgage?.(account.accountNumber)
          }}
          data-testid={`account-dropdown-${account.accountTypeLabel}`}
          key={accountId}
          tabIndex={0}
          data-allytmln={accountCardAllyTM(account)}
        >
          {content}
        </StyledMenuItem>
      )
    }

    return (
      <StyledMenuItem
        tag="a"
        href={accountUrl}
        onClick={(e: React.MouseEvent<HTMLAnchorElement>): void => {
          if (isCCAccount) {
            trackCCAccount(account)
          }
          onRouterLinkClick(e)
        }}
        data-testid={`account-dropdown-${account.accountTypeLabel}`}
        key={accountId}
        tabIndex={0}
        data-allytmln={accountCardAllyTM(account)}
      >
        {content}
      </StyledMenuItem>
    )
  })
}

const MaybeInvestAccountCards: React.FC<{ inNavDrawer?: boolean }> = ({
  inNavDrawer,
}) => {
  const history = useHistory()
  const { featureFlags, hostData, session } = useHostServices()
  const { data } = session
  const { isInvestOutage, isWealthOutage } = useOutageCheck()
  const { hasStaleInvestAccounts } = useAccountStatus()

  const isPersonalAdviceEnabled = featureFlags.variation(
    'FF_personal-advisor',
    false,
  )

  const isRoboRebrandEnabled = featureFlags.variation(
    'FF_robo-portfolio-rebrand',
    false,
  )

  let sortedAccounts = invest.getAllForDropdown(hostData)

  if (isRoboRebrandEnabled) {
    /*
      Once the Robo Portfolio => Automated Investing initiative has been released and we are ready
      to remove the FF, the following code should be removed and appropriately updated in
      @ally/data-selectors-afg-accounts.

      We didn't update libs at this moment as we still need the ability to enable/disable
      the feature flag. So we are making the conversion here until the initiative is complete.
    */
    sortedAccounts = sortedAccounts.map(account =>
      account.accountTypeLabel === AccountTypeLabel.ROBO_PORTFOLIO
        ? {
            ...account,
            accountTypeLabel: 'AUTOMATED INVESTING' as AccountTypeLabel,
          }
        : account,
    )
  }

  const wealthAccounts = sortedAccounts.filter(
    acct => acct.type === AccountType.INVW,
  )

  const allAccountsClosed = wealthAccounts.length
    ? wealthAccounts.every(acc => acc.status.toLowerCase() === 'closed')
    : false

  const wealthTotalBalance = wealthAccounts.reduce(
    (accum, acct) => accum + (acct.value?.total ?? 0),
    0,
  )
  const wealthSummary: AccountForDropdown = {
    id: '',
    accountNumber: '',
    lob: AccountLOB.WEALTH,
    status: allAccountsClosed ? AccountStatus.CLOSED : AccountStatus.ACTIVE,
    type: AccountType.INVW,
    name: 'Wealth Overview',
    nickName: 'Wealth Overview',
    institution: 'ally',
    accountTypeLabel: isPersonalAdviceEnabled
      ? AccountTypeLabel.PERSONAL_ADVICE
      : AccountTypeLabel.WEALTH_MANAGEMENT,
    accountBalanceToShow: wealthTotalBalance,
  }

  // This reduces wealth accounts to one single account in invest accounts array
  let hasCalculatedWealthOverview = false
  const reducedSortedAccounts = sortedAccounts.reduce((accounts, account) => {
    if (account.type === AccountType.INVW && !hasCalculatedWealthOverview) {
      hasCalculatedWealthOverview = true
      return [...accounts, wealthSummary]
    }
    if (account.type !== AccountType.INVW) {
      return [...accounts, account]
    }
    return accounts
  }, [] as AccountForDropdown[])

  const clientStatuses: RelationshipStatusTypes[] = [
    'CLIENT_UNFUNDED',
    'CLIENT_FUNDED',
  ]
  const wealthUserStatus = data?.relationships?.invest?.wealth?.status
  // make sure user does not have a Prospect relationship
  const isWealthClient = !!(
    wealthUserStatus && clientStatuses.includes(wealthUserStatus)
  )

  if (isInvestOutage || (isWealthClient && isWealthOutage)) {
    return <Error accountType="Investments" inNavDrawer={inNavDrawer} />
  }

  const getAccountURL = (account: AccountForDropdown): string => {
    const isINVW = account.type === AccountType.INVW
    const isINVS = account.type === AccountType.INVS
    const subPath = isINVS ? 'accounts/holdings-balances' : 'dashboard'
    if (hasStaleInvestAccounts) {
      return '/error'
    }
    return isINVW
      ? '/ext-wealth/wealth-overview'
      : `/ext-invest-live/${subPath}/${account.id}`
  }

  return (
    <>
      {hasStaleInvestAccounts && <StaleDataNotice inNavDrawer={inNavDrawer} />}
      {mapAccountCards<AccountForDropdown>(
        reducedSortedAccounts,
        getAccountURL,
        mapInvestAccount,
        history,
        inNavDrawer,
      )}
    </>
  )
}

const MaybeDepositAccountCards: React.FC<{ inNavDrawer?: boolean }> = ({
  inNavDrawer,
}) => {
  const history = useHistory()
  const { featureFlags, hostData } = useHostServices()
  const { isBankOutage } = useOutageCheck()
  const isProductNameChangeEnabled = featureFlags.variation(
    'FF_product-name-change',
    false,
  )

  let depositDropdownAccounts = deposit.getAllForDropdown(hostData)

  if (isProductNameChangeEnabled) {
    /*
      Product Name Change
      https://jira.int.ally.com/browse/WEB-58521

      Once the Product Name Change initiative has been released, approved, and we are ready
      to remove the FF, the following code should be removed since the deposit account type names
      will be returned automatically as CHECKING when the `AccountTypeToLabel` object is updated in
      @ally/data-selectors-afg-accounts.

      We didn't update that object at this moment as we still need the ability to enable/disable
      the ProductNameChangeEnabled feature flag. So we are making the conversion here until the initiative is complete.
    */
    depositDropdownAccounts = depositDropdownAccounts.map(account =>
      account.accountTypeLabel === AccountTypeLabel.INTEREST_CHECKING
        ? { ...account, accountTypeLabel: AccountTypeLabel.CHECKING }
        : account,
    )
  }

  const { hasStaleBankAccounts } = useAccountStatus()

  if (isBankOutage) {
    return <Error accountType="Bank Accounts" inNavDrawer={inNavDrawer} />
  }

  const getAccountURL = ({ id, type }: AccountForDropdown): string => {
    const DepositAccountLink = `/account/${id}/details`

    if (hasStaleBankAccounts) {
      return '/error'
    }
    return DepositAccountLink
  }

  return (
    <>
      {hasStaleBankAccounts && depositDropdownAccounts.length > 0 && (
        <StaleDataNotice inNavDrawer={inNavDrawer} />
      )}
      {mapAccountCards<AccountForDropdown>(
        depositDropdownAccounts,
        getAccountURL,
        mapDepositAccount,
        history,
        inNavDrawer,
      )}
    </>
  )
}

const mapMortgageAccount = (
  account: AccountForDropdown,
  inNavDrawer?: boolean,
): React.ReactElement => {
  const { type, id } = account
  const accountColor = mapColors(type)

  return (
    <DetailBar
      account={account}
      accountColor={accountColor}
      key={`detail-${id}`}
      inNavDrawer={inNavDrawer}
    />
  )
}

const MaybeMortgageAccountCards: React.FC<{
  redirectToMortgage: (accountNumber?: string) => void
  inNavDrawer?: boolean
}> = ({ redirectToMortgage, inNavDrawer }) => {
  const history = useHistory()
  const { hostData } = useHostServices()
  const { isMortgageOutage } = useOutageCheck()

  const sortedAccounts = mortgage.getAllForDropdown(hostData)

  if (isMortgageOutage) {
    return <Error accountType="home loans" inNavDrawer={inNavDrawer} />
  }

  return (
    <>
      {mapAccountCards<AccountForDropdown>(
        sortedAccounts,
        () => '',
        mapMortgageAccount,
        history,
        inNavDrawer,
        redirectToMortgage,
      )}
    </>
  )
}

const mapAutoAccount = (
  account: AccountForDropdown,
  inNavDrawer?: boolean,
): React.ReactElement => {
  const { type, id } = account
  const accountColor = mapColors(type)

  window.allytm.event('customEvent', {
    tagName: 'vehicleCard',
    allytmln: 'account-dropdown-VEHICLE FINANCING',
  })

  return (
    <DetailBar
      account={account}
      accountColor={accountColor}
      key={`detail-${id}`}
      inNavDrawer={inNavDrawer}
    />
  )
}

const MaybeAutoAccountCards: React.FC<{
  inNavDrawer?: boolean
}> = ({ inNavDrawer }) => {
  const history = useHistory()
  const { featureFlags, hostData } = useHostServices()
  const isAutoEnabled = featureFlags.variation('FF_SPOG_WEB_UI', true)
  const {
    hasStaleAutoAccounts,
    isAutoScheduledMaintenance,
  } = useAccountStatus()
  const isBankruptcyAccepted = getBankruptcyAcceptedSessionProp()

  useEffect(() => {
    if (isAutoScheduledMaintenance)
      window.allytm.event(
        'customError',
        "We can't show your vehicle accounts right now.",
      )
  }, [isAutoScheduledMaintenance])

  useEffect(() => {
    if (hasStaleAutoAccounts)
      window.allytm.event(
        'customError',
        "We've hit a snag when trying to update your account information. A fix is in the works.",
      )
  }, [hasStaleAutoAccounts])

  const sortedAccounts = useMemo(() => {
    return auto.getAllForDropdown(hostData, isBankruptcyAccepted)
  }, [hostData, isBankruptcyAccepted])

  if (!isAutoEnabled) {
    return <></>
  }

  if (isAutoScheduledMaintenance) {
    return <Error accountType="vehicle accounts" inNavDrawer={inNavDrawer} />
  }

  const getAccountURL = ({ id }: AccountForDropdown): string => {
    return `/sso/auto/accounts/${id}`
  }

  return (
    <>
      {hasStaleAutoAccounts && sortedAccounts.length > 0 && (
        <StaleDataNotice inNavDrawer={inNavDrawer} />
      )}
      {mapAccountCards<AccountForDropdown>(
        sortedAccounts,
        getAccountURL,
        mapAutoAccount,
        history,
        inNavDrawer,
      )}
    </>
  )
}

const mapCreditCardAccount = (
  account: AccountForDropdown,
  inNavDrawer?: boolean,
): React.ReactElement => {
  const { type, id } = account
  const accountColor = mapColors(type)

  return (
    <CreditCardDetailBar
      account={account}
      accountColor={accountColor}
      key={`detail-${id}`}
      inNavDrawer={inNavDrawer}
    />
  )
}

const MaybeCreditCardAccountCards: React.FC<{
  inNavDrawer?: boolean
}> = ({ inNavDrawer }) => {
  const history = useHistory()
  const { featureFlags, hostData } = useHostServices()
  const { isCreditCardOutage } = useOutageCheck()
  const { hasStaleCCAccounts } = useAccountStatus()
  const isCreditCardEnabled = featureFlags.variation(
    'FF_credit-card-snapshot',
    false,
  )

  const isCreditCardMigrationMessageEnabled = featureFlags.variation(
    'FF_credit-card-migration-message',
    false,
  )

  if (!isCreditCardEnabled) {
    return <></>
  }

  if (isCreditCardOutage) {
    return (
      <Error accountType="credit card accounts" inNavDrawer={inNavDrawer} />
    )
  }

  const sortedAccounts = creditCard.getAllForDropdown(hostData)
  const getAccountURL = ({ id }: AccountForDropdown): string => {
    return `/sso/credit-card/Account/Overview?accountProxyId=${id}`
  }

  return (
    <>
      {hasStaleCCAccounts && sortedAccounts.length > 0 && (
        <StaleDataNotice
          inNavDrawer={inNavDrawer}
          isCreditCardMigrationMessageEnabled={
            isCreditCardMigrationMessageEnabled
          }
        />
      )}
      {mapAccountCards<AccountForDropdown>(
        sortedAccounts,
        getAccountURL,
        mapCreditCardAccount,
        history,
        inNavDrawer,
      )}
    </>
  )
}

const AccountsDetails: React.FC<{
  redirectToMortgage: (accountNumber?: string) => void
  inNavDrawer?: boolean
  hasMaxHeight?: boolean
}> = ({ redirectToMortgage, inNavDrawer, hasMaxHeight = true }) => {
  const {
    investOrder,
    depositOrder,
    mortgageOrder,
    autoOrder,
    creditCardOrder,
  } = useCustomization()

  const sortedAccountCards = useMemo(
    () =>
      [
        {
          component: (
            <MaybeInvestAccountCards inNavDrawer={inNavDrawer} key="invest" />
          ),
          order: investOrder,
        },
        {
          component: (
            <MaybeDepositAccountCards inNavDrawer={inNavDrawer} key="deposit" />
          ),
          order: depositOrder,
        },
        {
          component: (
            <MaybeCreditCardAccountCards
              inNavDrawer={inNavDrawer}
              key="creditCard"
            />
          ),
          order: creditCardOrder,
        },
        {
          component: (
            <MaybeAutoAccountCards inNavDrawer={inNavDrawer} key="auto" />
          ),
          order: autoOrder,
        },
        {
          component: (
            <MaybeMortgageAccountCards
              inNavDrawer={inNavDrawer}
              key="mortgage"
              redirectToMortgage={redirectToMortgage}
            />
          ),
          order: mortgageOrder,
        },
      ].sort((a, b) => a.order - b.order),
    [
      investOrder,
      depositOrder,
      mortgageOrder,
      autoOrder,
      creditCardOrder,
      inNavDrawer,
      redirectToMortgage,
    ],
  )

  return (
    // we need an additional ThemeProvider here to override the navigationMenuTheme
    // colorMode that the NAVIGATION Accordion uses. It turns all of the text white.
    <ThemeProvider theme={muiBankTheme}>
      <Box
        overflowY="scroll"
        display="flex"
        flexDirection="column"
        borderTopWidth="1px"
        borderTopStyle="solid"
        borderTopColor="slate-2"
        maxHeight={hasMaxHeight ? '285px' : undefined}
        data-testid="accounts-dropdown-container"
      >
        {sortedAccountCards.map(({ component }) => component)}
      </Box>
    </ThemeProvider>
  )
}

export { AccountsDetails }
