import React from 'react'
import styled from 'styled-components'
import { get } from '@ally/metronome-ui'
import { MaxAppWidth } from '@ally/next-layout'

import { SubNavMenu } from './SubNavMenu'
import { useCoachmarks } from '../providers'
import { useHostServices } from '../HostServices'

const SubNavOuter = styled.div`
  background-color: ${get.colors('blueberry')};
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.1);
  position: relative;
  width: 100%;
  z-index: 2;
`

const SubNavInner = styled.nav`
  align-items: center;
  display: flex;
  height: 35px;
  justify-content: flex-start;
  margin: auto;
  position: relative;
  width: 100%;
`

export const SubNav: React.FC = () => {
  const { subnav, session } = useHostServices()

  const { schema } = subnav
  const { coachmarks } = useCoachmarks()

  if (!schema.length && !coachmarks.length) return null

  return session.status === 'Authenticated' ? (
    <SubNavOuter data-testid="sub-nav-outer">
      <MaxAppWidth aria-label="sub-nav">
        <SubNavInner>
          <SubNavMenu schema={schema} />
        </SubNavInner>
      </MaxAppWidth>
    </SubNavOuter>
  ) : null
}

export default SubNav
