import whichEnv from '@ally/which-env'

export const env = whichEnv(window.location.href)

export const sessionFirstDashboardVisitKey = 'host-isFirstDashboardVisit'

// -------------------------------------------------------------------------- //
// Support contact numbers
// -------------------------------------------------------------------------- //

// NOTE: If these numbers contain the same keys for their respective target
// selecting the correct number can be simplified.
export const bankNumbers = {
  insideUS: '1-877-247-2559',
  outsideUS: '001-757-247-2559',
  fraud: '1-800-971-6037',
  telLink: 'tel:18772472559',
}

export const investNumbers = {
  insideUS: '1-855-880-2559',
  outsideUS: '1-818-459-4591',
  insideUSFax: '1-866-699-0563',
  outsideUSFax: '1-704-457-2205',
  telLink: 'tel:18558802559',
}

export const mortgageNumbers = {
  insideUS: '1-855-256-2559',
  telLink: 'tel:18552562559',
}

export const creditCardNumbers = {
  insideUS: '1-888-366-2559',
  telLink: 'tel:18883662559',
}

export const autoNumbers = {
  insideUS: '1-888-925-2559',
  telLink: 'tel:18889252559',
}

// -------------------------------------------------------------------------- //
// Apigee sts endpoint api keys
// -------------------------------------------------------------------------- //

export const apigeeAPIKey = env.pick({
  dev: 'wU5VdAI4fv7jgsaAOJDREixc3MOaUs1R',
  cap: 'BdWWhZRHCmdNq74CJYfaozM2gZCZqEDs',
  fix: 'BdWWhZRHCmdNq74CJYfaozM2gZCZqEDs',
  qa1: 'BdWWhZRHCmdNq74CJYfaozM2gZCZqEDs',
  qa2: 'BdWWhZRHCmdNq74CJYfaozM2gZCZqEDs',
  qa3: 'BdWWhZRHCmdNq74CJYfaozM2gZCZqEDs',
  prod: 'XhnbsDYmBensf2vqVXAfv0uD7SJBA27u',
  local: 'wU5VdAI4fv7jgsaAOJDREixc3MOaUs1R',
})
