import { useEffect, useRef, useState } from 'react'

import { AllyLaunchDarkly } from '@ally/aardvarkly'
import {
  getDomainLoadState as getCustomerLoadState,
  getCustomer,
} from '@ally/data-selectors-afg-customer'
import { Customer } from '@ally/data-types-afg-customer'
import { LoadState } from '@ally/use-data-domain'

import log from '../../../whisper'
import { useSession, useHostData } from '../../../providers'
import { isDomainReady } from './utils'

function getCustomerType(customer: Customer | null): string {
  if (!customer?.auto) return 'UNKNOWN'
  const { itin, organizationName } = customer.auto
  if (!itin && !organizationName) return 'INDIVIDUAL'
  return 'BUSINESS'
}

export function useAutoLDRegister(instance?: AllyLaunchDarkly): LoadState {
  const isRegistrationPending = useRef(false)
  const [loadState, setRegistrationLoadState] = useState(LoadState.LOADING)

  const { data: sessionData } = useSession()
  const hostData = useHostData()

  useEffect(() => {
    if (!instance || isRegistrationPending.current) return
    if (!sessionData?.aaosId) return
    const isCustomerInfoReady = isDomainReady(getCustomerLoadState(hostData))
    if (!isCustomerInfoReady) return

    isRegistrationPending.current = true

    const customer = getCustomer(hostData)
    const customerType = getCustomerType(customer)

    instance
      .registerUser(sessionData.aaosId, sessionData.userNamePvtEncrypt, {
        customerId: sessionData.aaosId,
        customerType,
      })
      .then(() => {
        setRegistrationLoadState(LoadState.LOADED)
        log.info({ message: '[USER] Registered with Bank LD Instance' })
      })
      .catch(() => {
        // We are setting this to "LOADED" as some consumers are using this to know
        // whether they can continue evaluation of flags for decisioning
        // Ex: @ally/next-subnav
        setRegistrationLoadState(LoadState.LOADED)
        log.error({
          message: '[USER] Failed to register with Bank LD Instance',
        })
      })
  })

  return loadState
}
