import React from 'react'
import styled from 'styled-components'
import { MuiIconMap } from '@ally/metronome-icons'
import { Box, TextBody, Icon, muiBankTheme } from '@ally/metronome-ui'
import {
  bankNumbers,
  creditCardNumbers,
  investNumbers,
  mortgageNumbers,
} from './constants'
import { useHostServices } from './HostServices'
import { getInvestTitle } from './utils'

const customAllyLogo: MuiIconMap = {
  base: {
    paths: [
      {
        path:
          'M22.32 0h5.619v25.049h-5.619v-25.049zM30.623 0h5.619v25.049h-5.619v-25.049zM57.152 6.951l-9.41 25.049h-5.958l2.979-7.21-6.973-17.839h5.991l3.757 10.899h0.068l3.588-10.899h5.958zM19.758 16.085c0-4.237-3.022-9.19-9.315-9.19s-9.315 4.953-9.315 9.19c0 4.032 2.738 8.714 8.428 9.156l4.811-5.211h-3.828c-2.191 0-4.047-1.662-4.047-3.945s1.856-3.945 4.047-3.945 4.047 1.662 4.047 3.945c0 2.12 0 6.941 0 8.845h5.171c0-2.346 0-6.814 0-8.845z',
        fill: muiBankTheme.colors.plum,
      },
    ],
    height: 32,
    width: 58,
    title: 'Ally Logo',
  },
}

const AllyLogo: React.FC = () => {
  return (
    <Icon
      icon={customAllyLogo}
      fill="plum"
      size="xl"
      unsupportedCustomWidth={89}
      unsupportedCustomHeight={89}
      ariaHidden
    />
  )
}

const PhoneNumbers: React.FC = () => {
  const { session, featureFlags } = useHostServices()
  const isAuthenticated = session?.status === 'Authenticated'
  const bank = !!session?.data?.allyUserRole?.bank
  const creditcard = !!session?.data?.allyUserRole?.creditcard
  const investment = !!session?.data?.allyUserRole?.investment
  const mortgage = !!session?.data?.allyUserRole?.mortgage
  const wealth = !!session?.data?.allyUserRole?.wealth

  const isPersonalAdviceEnabled = featureFlags?.variation(
    'FF_personal-advisor',
    false,
  )

  // Invest or Wealth
  const investTitle = getInvestTitle(
    investment || !isAuthenticated,
    wealth || !isAuthenticated,
    isPersonalAdviceEnabled,
  )

  return (
    <Box display="flex" flexDirection="column">
      {(investment || wealth || !isAuthenticated) && (
        <TextBody size="sm" tag="p">
          <TextBody size="sm" tag="span" weight="bold">
            {investTitle}:{' '}
          </TextBody>
          {investNumbers.insideUS}
        </TextBody>
      )}
      {(bank || !isAuthenticated) && (
        <TextBody size="sm" tag="p">
          <TextBody size="sm" tag="span" weight="bold">
            Bank Accounts:{' '}
          </TextBody>
          {bankNumbers.insideUS}
        </TextBody>
      )}
      {(creditcard || !isAuthenticated) && (
        <TextBody size="sm" tag="p">
          <TextBody size="sm" tag="span" weight="bold">
            Credit Card Accounts:{' '}
          </TextBody>
          {creditCardNumbers.insideUS}
        </TextBody>
      )}
      {(mortgage || !isAuthenticated) && (
        <TextBody size="sm" tag="p">
          <TextBody size="sm" tag="span" weight="bold">
            Home Loans:{' '}
          </TextBody>
          {mortgageNumbers.insideUS}
        </TextBody>
      )}
    </Box>
  )
}

const PrintBox = styled(Box)`
  display: none;

  @media print {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`

export const PrintHeader: React.FC = () => {
  return (
    <PrintBox>
      <AllyLogo />
      <PhoneNumbers />
    </PrintBox>
  )
}
