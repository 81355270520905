import React from 'react'
import { useLocation, Link as ReactRouterLink } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Link as MuiLink, Media } from '@ally/metronome-ui'
import { useHostServices } from '../HostServices'

const OpenAccountLink = styled(MuiLink)(
  ({ theme: { space } }) => css`
    a {
      background-color: transparent;
      color: white;
      border-color: white;
      margin-left: ${space.lg};
      white-space: nowrap;

      &:hover {
        background-color: transparent;
      }
    }
  `,
)

export const OpenAccount: React.FC = () => {
  const location = useLocation()
  const routePath = location.pathname
  const isDaoEpr =
    routePath.startsWith('/products-and-rates') ||
    routePath.startsWith('/open-account')

  const { globalnav, session } = useHostServices()
  const { isHidden } = globalnav
  const isAuthenticated = session?.status === 'Authenticated'

  if (!isDaoEpr && !isHidden && isAuthenticated) {
    return (
      <Media.MdUp>
        <OpenAccountLink
          variant="outline"
          text="Open an Account"
          size="sm"
          allytmln="openanaccount"
        >
          <ReactRouterLink to="/products-and-rates" />
        </OpenAccountLink>
      </Media.MdUp>
    )
  }
  return null
}
