import log from '../whisper'

import {
  logRocketAppId,
  logRocketConfig,
  logRocketIsEnabled,
} from '../constants'
import { logRocket } from '../log-rocket'

export default function initLogRocket(): void {
  if (!logRocketIsEnabled) {
    log.warn({ message: '[NOTICE] LOGROCKET IS DISABLED!' })
    return
  }

  logRocket.init(logRocketAppId, logRocketConfig)
  logRocket.getSessionURL(sessionURL => {
    window.LogRocket = { sessionURL }
  })
}
