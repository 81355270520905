import React from 'react'

import { HostServices } from '@ally/federated-types'
import { muiBankTheme, MetronomeProvider } from '@ally/metronome-ui'
import ErrorBoundary from '@ally/next-remote-error-boundary'
import { Router as ReactRouter } from 'react-router-dom'
import styled from 'styled-components'

import { HostServicesContext } from './HostServices'
import { PrintHeader } from './PrintHeader'
import TopNav from './TopNav'
import SubNav from './SubNav'
import ActionBar from './ActionBar'
import { CoachmarksProvider, OverlayProvider } from './providers'

const PrintHidden = styled.div`
  @media print {
    display: none;
  }
`

interface AppProps {
  services: HostServices
}

const App: React.FC<AppProps> = ({ services }) => {
  const { browserHistory } = services
  const name = 'ally-next-remote-header'
  return (
    <ReactRouter history={browserHistory}>
      <MetronomeProvider theme={muiBankTheme}>
        <HostServicesContext.Provider value={services}>
          <ErrorBoundary hostServices={services} name={name}>
            <OverlayProvider>
              <CoachmarksProvider>
                <PrintHeader />
                <PrintHidden>
                  <TopNav />
                  <SubNav />
                  <ActionBar />
                </PrintHidden>
              </CoachmarksProvider>
            </OverlayProvider>
          </ErrorBoundary>
        </HostServicesContext.Provider>
      </MetronomeProvider>
    </ReactRouter>
  )
}

export default App
