/**
 * Please list all feature flags here in this enum alphabetically.
 * Makes refactoring/removing flags easier.
 */
export enum FeatureFlag {
  AfgAccountsVersion = 'FF_afg-accounts-version',
  AosFaqLob = 'FF_aos-faq-lob',
  AutoEnabled = 'FF_SPOG_WEB_UI',
  CallWaitTimeEnabled = 'call-wait-time',
  CPRAEnabled = 'FF_CPRA',
  CreditCardEnabled = 'FF_credit-card-snapshot',
  CreditCardMigrationMessage = 'FF_credit-card-migration-message',
  CreditHealthRemote = 'FF_credit-health-remote',
  CustomizationEnabled = 'FF_snapshot-customization',
  CustomizationVersion = 'FF_afg-customization-version',
  DebtPlanEnabled = 'FF_debt-plan-snapshot',
  DisableSTSWhenTransmitV2 = 'FF_disable-sts-when-transmit-v2',
  FederatedHeaderEnabled = 'FF_ally-next-federated-header',
  FeedbackVendor = 'FF_aos-voc-vendor',
  FilterDomains = 'FF_logrocket-filter-domains',
  GuestExperience = 'FF_guest-experience',
  JumpToDropdownEnabled = 'FF_jump-to-dropdown-text',
  KeyCodesEnabled = 'FF_ally-next-key-codes',
  PersonalAdviceEnabled = 'FF_personal-advisor',
  ProductNameChangeEnabled = 'FF_product-name-change',
  RoboPortfolioRebrand = 'FF_robo-portfolio-rebrand',
  ReactEtinEnabled = 'FF_react-etin',
  DebounceLimit = 'FF_aos-faq-search-debounce',
}
