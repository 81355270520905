import { HostServices } from '@ally/federated-types'
import { noop } from '@ally/utilitarian'

export function getLogout(services: HostServices): () => Promise<void> {
  const isAutoUser = !!services.session.data?.allyUserRole.auto
  const autoLogout = isAutoUser
    ? services.autoLogin.logout
    : Promise.resolve.bind(Promise)
  return (): Promise<void> =>
    autoLogout().catch(noop).then(services.session.delSession)
}
