import { useMemo } from 'react'
import { castFunction } from '@ally/utilitarian'
import { useActionBarContext } from '../ActionBarContext'
import { CallUsSchema, CallUsSchemaInput, ProcessedCallUsSchema } from './types'
import { useCallWaitTimes } from './useCallWaitTimes'
import { useHostServices } from '../../HostServices'

export function getEnabledSchema(
  schema: CallUsSchema[],
  input: CallUsSchemaInput,
): CallUsSchema[] {
  return schema.filter(({ shouldDisplay }) =>
    castFunction(shouldDisplay)(input),
  )
}

export function processSchema(
  schema: CallUsSchema[],
  input: CallUsSchemaInput,
): Omit<ProcessedCallUsSchema, 'callWaitTime'>[] {
  return schema.map(item => ({
    ...item,
    shouldDisplay: castFunction(item.shouldDisplay)(input),
    title: castFunction(item.title)(input),
    allytm:
      item.allytm !== undefined ? castFunction(item.allytm)(input) : undefined,
    phoneNumber: castFunction(item.phoneNumber)(input),
    hours: castFunction(item.hours)(input),
  }))
}

export function useProcessedSchema(
  schema: CallUsSchema[],
): ProcessedCallUsSchema[] {
  const { featureFlags, session } = useHostServices()

  const context = useActionBarContext()

  const isAuthenticated = session?.status === 'Authenticated'

  const input: CallUsSchemaInput = useMemo(
    () => ({
      ...context,
      featureFlags,
      isAuthenticated,
    }),
    [context, featureFlags, isAuthenticated],
  )

  const enabledSchema = useMemo(() => getEnabledSchema(schema, input), [
    schema,
    input,
  ])

  const withoutWaitTimes: Omit<
    ProcessedCallUsSchema,
    'callWaitTime'
  >[] = useMemo(() => processSchema(enabledSchema, input), [enabledSchema])

  const waitTimes = useCallWaitTimes(enabledSchema)

  return useMemo(
    () =>
      withoutWaitTimes.map(item => ({
        ...item,
        callWaitTime: waitTimes[item.id] ?? null,
      })),
    [withoutWaitTimes, waitTimes],
  )
}
