import { useCallback } from 'react'
import { useAwaitData } from '@ally/use-data-domain'
import { isError } from '@ally/utilitarian'
import { CoachmarkData } from './utils'
import { useHostServices } from '../../HostServices'

type FetchParams = {
  guid?: string | null
}

type FetchReturn = (params: FetchParams) => Promise<CoachmarkData[]>

export const useCoachmarkDataFetch = (): FetchReturn => {
  const { hostData, log } = useHostServices()

  const { notifications } = hostData

  const getNotificationsData = useAwaitData(notifications)

  const fetchCoachmarkData = useCallback(async (): Promise<CoachmarkData[]> => {
    try {
      const notificationsData = await getNotificationsData()
      const coachmarksArray = (notificationsData?.notifications.slice() ?? [])
        .filter(notification => notification.category === 'COACHMARK')
        .sort((a, b) =>
          a.effectiveFrom && b.effectiveFrom
            ? -a.effectiveFrom.localeCompare(b.effectiveFrom)
            : 0,
        )
        .map(notification => ({
          completed: (notification.metadata?.completed as boolean) ?? false,
          id: notification.notificationId,
          ignored: (notification.metadata?.ignored as boolean) ?? false,
          name: notification.type,
          version: (notification.metadata?.version as string) ?? '1.0',
        }))

      return coachmarksArray
    } catch (e) {
      log.error({
        message: ['Get Coachmarks Failed:', ...(isError(e) ? [e.stack] : [])],
      })
      return []
    }
  }, [fetch])

  return fetchCoachmarkData
}
