import { AllySharedProfileMenu } from '@ally/ally-shared-profile-menu'
import { auto, creditCard, mortgage } from '@ally/data-selectors-afg-accounts'
import { Account, AccountStatus } from '@ally/data-types-afg-accounts'
import React, { useMemo } from 'react'
import {
  useOutageCheck,
  useUnreadMessageCount,
  useAccountStatus,
} from '../../hooks'
import { useHostServices } from '../../HostServices'
import { formatLastLogin, getLogout } from '../../utils'

type ProfileMenuContentProps = {
  focusRef: React.RefObject<HTMLSpanElement | HTMLButtonElement>
  onClose: () => void
  handleMortgageRedirect: () => void
}

const ProfileMenuContent: React.FC<ProfileMenuContentProps> = ({
  focusRef,
  onClose,
  handleMortgageRedirect,
}) => {
  const hostServices = useHostServices()
  const { featureFlags, session, hostData } = hostServices
  const { data } = session
  const logout = getLogout(hostServices)

  const lastLoginTimeStamp = data?.lastLoginTime ?? null

  const formattedLoginTime = useMemo(
    () => (lastLoginTimeStamp ? formatLastLogin(lastLoginTimeStamp) : ''),
    [lastLoginTimeStamp],
  )

  const {
    hasOpenDepositAccounts,
    hasActiveDepositAccounts,
    hasDebitCardEligibleAccounts,
    hasTrusteeOnlyAccounts,
    hasNoAccounts,
    isInvestOnly,
    isMortgageOnly,
    hasOnlyCDorIRA,
  } = useAccountStatus()

  const {
    isBankOutage,
    isInvestOutage,
    isWealthOutage,
    isMortgageOutage,
    isOutage,
    isCreditCardOutage,
    isAutoOutage,
  } = useOutageCheck()

  const isInvestUser = !!data?.allyUserRole.investment
  const isWealthUser = !!data?.allyUserRole.wealth
  const isBankUser = !!data?.allyUserRole.bank
  const isAutoUser = !!data?.allyUserRole.auto
  const isMortgageUser = !!data?.allyUserRole.mortgage
  const isGuestUser = !!data?.allyUserRole.guest
  const isCredicardUser = !!data?.allyUserRole.creditcard

  const wealthUserStatus = data?.relationships?.invest?.wealth?.status
  const wealthClientStatuses = ['CLIENT_UNFUNDED', 'CLIENT_FUNDED']
  const isWealthClient =
    isWealthUser && wealthClientStatuses.includes(wealthUserStatus as string)

  const isDebtPlanCustomer =
    data?.relationships?.tools?.debtReduction?.status === 'ACTIVE' || false

  const flags = {
    isWealthManagement: true,
    isOrderDebitCardEnabled: featureFlags.variation(
      'FF_order-debit-card',
      false,
    ),
    isManageDebitCardEnabled: featureFlags.variation(
      'FF_manage-debit-card',
      false,
    ),
    isReplaceDebitCardEnabled: featureFlags.variation(
      'FF_replace-debit-card',
      false,
    ),
    isMortgageEnabled: true,
    isPrivacyPreferencesEnabled: featureFlags.variation(
      'FF_update-privacy-preferences',
      false,
    ),
    isAutoEnabled: featureFlags.variation('FF_SPOG_WEB_UI', true),
    isDebtPlanEnabled: featureFlags.variation('FF_debt-plan-snapshot', false),
    isCreditCardEnabled: featureFlags.variation(
      'FF_credit-card-snapshot',
      false,
    ),
    isAutoAlertsEnabled: featureFlags.variation('FF_auto-alerts', false),
    isGuestEnabled: featureFlags.variation('FF_guest-experience', false),
    isDirectDepositEnabled: featureFlags.variation(
      'FF_direct-deposit-switch',
      false,
    ),
    isPersonalAdviceEnabled: featureFlags.variation(
      'FF_personal-advisor',
      false,
    ),
    isInvestEnabled: featureFlags.variation('FF_Invest-Secure-Message', false),
  }

  const {
    bankUnreadMessageCount,
    vehicleUnreadMessageCount,
    investUnreadMessageCount,
  } = useUnreadMessageCount()

  const hasBlockedMortgageAccounts = mortgage.hasBlockedAccounts(hostData)

  const restrictedStatuses = ['BANKRUPT', 'STOLEN', 'LOST', 'FROZEN_OR_FRAUD']
  const allRestrictedCCAccounts = creditCard
    .getAll(hostData)
    .map((account: Account) => account.status)
    .every((status: AccountStatus) => restrictedStatuses.includes(status))

  const firstAutoAccountId =
    auto.getAll(hostData).length > 0 ? auto.getAll(hostData)[0].id : undefined

  const { scope } = data || {}
  const isAutoBusiness = Boolean(scope?.includes('auto:business:transact'))

  return (
    <AllySharedProfileMenu
      focusRef={focusRef}
      lastLoginTime={formattedLoginTime}
      closeMenu={onClose}
      isWealthClient={!!isWealthClient}
      isInvest={isInvestUser}
      investOutage={isInvestOutage}
      isBank={isBankUser}
      isMortgage={isMortgageUser && !hasBlockedMortgageAccounts}
      isMortgageOnly={isMortgageOnly}
      isAuto={isAutoUser}
      isAutoBusiness={isAutoBusiness}
      isDebtPlanCustomer={isDebtPlanCustomer}
      mortgageOutage={isMortgageOutage}
      bankOutage={isBankOutage}
      isOutage={isOutage}
      wealthOutage={isWealthOutage}
      handleLogout={logout}
      bankMessageCount={bankUnreadMessageCount}
      investMessageCount={investUnreadMessageCount}
      vehicleMessageCount={vehicleUnreadMessageCount}
      isInvestOnly={isInvestOnly}
      hasOpenDepositAccounts={hasOpenDepositAccounts}
      hasDebitCardEligibleAccounts={hasDebitCardEligibleAccounts}
      hasNoAccounts={hasNoAccounts}
      hasTrusteeOnlyAccounts={hasTrusteeOnlyAccounts}
      isBankOperational={!isBankOutage}
      hasActiveDepositAccounts={hasActiveDepositAccounts}
      featureFlags={flags}
      mortgageTriggerFunction={handleMortgageRedirect}
      isCreditCard={isCredicardUser}
      creditCardOutage={isCreditCardOutage}
      allRestrictedCCAccounts={allRestrictedCCAccounts}
      firstAutoAccountId={firstAutoAccountId}
      autoOutage={isAutoOutage}
      isGuest={isGuestUser && flags.isGuestEnabled}
      hasOnlyCDorIRAAccounts={hasOnlyCDorIRA}
    />
  )
}

export { ProfileMenuContent }
