import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'

dayjs.extend(utc)
dayjs.extend(timezone)

/**
 * Returns a date string formatted as defined by the formatString
 */
export const formatTimeStamp = (
  timeStamp: string,
  formatString: string,
  timeZone?: string,
): string =>
  dayjs(timeStamp)
    .tz(timeZone || 'America/New_York')
    .format(formatString)

/**
 * Formats data and time of Last Login as
 * Jan 01, 2021 (bullet point) 9:02AM ET
 */
export const formatLastLogin = (timeStamp: string): string => {
  return `${formatTimeStamp(
    timeStamp,
    'MMM DD, YYYY',
  )} \u2022 ${formatTimeStamp(timeStamp, 'h:mm A')} ET`
}

/**
 * Converts Date object to UTC date in milliseconds
 */
export const convertDateToUTC = (date: Date): number =>
  Date.UTC(date.getFullYear(), date.getMonth(), date.getDate())

/**
 * Converts array of Date objects to array of UTC date in milliseconds
 */
export const convertDatesToUTC = (...dates: Date[]): number[] =>
  dates.map((date): number => convertDateToUTC(date))

/**
 * Finds the difference between two Dates (in milliseconds) - converted to days
 */
export const dateDiffInDays = (
  dateOneInMillis: number,
  dateTwoInMillis: number,
): number => {
  const dayInMillis = 1000 * 60 * 60 * 24
  const days = Math.floor((dateOneInMillis - dateTwoInMillis) / dayInMillis)
  return days
}
