import React from 'react'
import cookies from 'js-cookie'
import { AutoLoginService } from '@ally/federated-types'
import { flow } from '@ally/utilitarian'

import {
  Box,
  Space,
  Modal,
  Button,
  ButtonGroup,
  TextBody,
  TextHeading,
} from '@ally/metronome-ui'

import log from '../../whisper'
import { track } from '../../tracking'
import { SessionContextValue } from '../../providers'
import { getLogout } from '../../utils/logout'

interface SessionTimeoutModalProps {
  restart: VoidFunction
  session: SessionContextValue
  autoLogin: AutoLoginService
  open: boolean
  setOpen: (v: boolean) => void
}

interface ModalContentProps {
  handleSessionLogout: VoidFunction
  handleSessionExtension: VoidFunction
}

/**
 * Returns a function that will be invoked when the "Continue My Session"
 * button is clicked on the <TimeoutModal />. This will make a POST call to
 * /keepalive in a fire and forget fashion. If the keep alive call fails, the
 * user will **NOT** be logged out.
 *
 * TODO: Remove this when CAPI-GW is **COMPLETELY** deprecated.
 */
function getKeepAliveFetch(session: SessionContextValue) {
  return (): void => {
    // Use the existance of the cookie set by the legacy session call to
    // know wether we need to keep the session alive or not
    const enabled = !!cookies.get('allycapi-gwsid')

    track('ally-next-host-capi-keepalive-init', { skip: !enabled })

    if (!enabled) return

    fetch('/capi-gw/keepalive', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${session.data?.AwsAccessToken ?? ''}`,
        CSRFChallengeToken: session.data?.CSRFChallengeToken ?? '',
      },
    })
      .then(() => track('ally-next-host-capi-keepalive-done'))
      .catch((e: Error) => {
        // DO NOT LOG THE USER OUT - There is a chance where a user starts a
        // session with CAPI-GW enabled and by the time the keepalive is
        // needed CAPI-GW has been shut off
        log.error({ message: ['Keep alive failed:', e.stack] })
        track('ally-next-host-capi-keepalive-error')
      })
  }
}

/**
 * A modal that is displayed when the user's session is about to expire.
 * Provides them the options of extending their session or logging out.
 * Note, after the session expires, the modal will be automatically dismissed
 * and the user will be logged out.
 */
export const ModalContent: React.FC<ModalContentProps> = ({
  handleSessionLogout,
  handleSessionExtension,
}) => {
  return (
    <Box display="flex" flexDirection="column">
      <Space mb="sm">
        <TextHeading tag="h2" size="md" tabIndex={-1}>
          Are you still there?
        </TextHeading>
        <TextBody tag="p" size="sm">
          For your protection, we&apos;ll close your session due to inactivity.
        </TextBody>
      </Space>
      <ButtonGroup>
        <Button
          variant="primary"
          text="Continue My Session"
          onClick={handleSessionExtension}
          data-testid="session-timeout-extend-button"
        />
        <Button
          variant="link"
          text="Log Out"
          onClick={(): void => handleSessionLogout()}
          data-testid="session-timeout-logout-button"
        />
      </ButtonGroup>
    </Box>
  )
}

export const SessionTimeoutModal: React.FC<SessionTimeoutModalProps> = ({
  restart,
  session,
  autoLogin,
  open,
  setOpen,
}) => {
  const keepAlive = getKeepAliveFetch(session)
  const logout = getLogout(session, autoLogin)
  const handleSessionLogout = flow(logout)
  const handleSessionExtension = flow(keepAlive, restart, () => {
    setOpen(false)
  })

  const handleOnOpenChange = (newOpen: boolean): void => {
    if (!newOpen) handleSessionExtension()
  }

  return (
    <>
      <Modal
        content={
          <ModalContent
            handleSessionLogout={handleSessionLogout}
            handleSessionExtension={handleSessionExtension}
          />
        }
        open={open}
        onOpenChange={handleOnOpenChange}
      />
    </>
  )
}
