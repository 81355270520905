/* eslint-disable react/jsx-props-no-spreading */

import React, { useMemo } from 'react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { Link as MuiLink } from '@ally/metronome-ui'
import { SubNavLinkSchema } from '@ally/federated-types'
import styled, { css } from 'styled-components'
import { SubNavListItem, SubNavListItemProps } from './SubNavListItem'
import { onlyText } from '../../utils/react'

interface SubNavLinkProps extends Pick<SubNavListItemProps, '$key'> {
  linkSchema: SubNavLinkSchema
}

const WhiteSubNavLink = styled(MuiLink)(
  () => css`
    a {
      color: white;
      line-height: 1.5;
      white-space: nowrap;
    }
  `,
)

export const SubNavLink = React.forwardRef<HTMLLIElement, SubNavLinkProps>(
  ({ linkSchema: { content, metadata, onClick, url }, ...others }, ref) => {
    const text = useMemo(() => onlyText(content), [content])
    return (
      <SubNavListItem ref={ref} {...others}>
        <WhiteSubNavLink
          variant="link"
          text={text}
          allytmln={`sub-nav-menu-${text}`}
          {...(metadata || {})}
        >
          <ReactRouterLink to={url} />
        </WhiteSubNavLink>
      </SubNavListItem>
    )
  },
)
