/* eslint-disable react/jsx-props-no-spreading */

import React, { useMemo } from 'react'
import styled from 'styled-components'

import { DropdownMenuDEPRECATED, get, TextBody } from '@ally/metronome-ui'

import {
  SubNavButtonSchema,
  SubNavDropdownSchema,
  SubNavLinkSchema,
} from '@ally/federated-types'

import { useHistory } from 'react-router-dom'
import { SubNavListItem, SubNavListItemProps } from '../SubNavListItem'
import { DropdownTrigger } from './DropdownTrigger'
import { useSubNavDropdown } from './use-sub-nav-dropdown'
import { generateKey } from '../utils'
import { onlyText } from '../../../utils'

interface SubNavDropdownProps
  extends Pick<SubNavListItemProps, '$key'>,
    Pick<React.ComponentProps<typeof DropdownMenuDEPRECATED>, 'alignSelf'> {
  dropdownSchema: SubNavDropdownSchema
}

interface DropdownLinkItemProps {
  $key: string
  linkSchema: SubNavLinkSchema
  className?: string
}

interface DropdownButtonItemProps {
  $key: string
  buttonSchema: SubNavButtonSchema
  className?: string
}

interface DropdownSubMenuProps {
  $key: string
  subMenuSchema: SubNavDropdownSchema
  disableCloseOnBlur?: boolean
  ariaLabel?: string
}

const StyledDropdownItemContent = styled(TextBody)`
  line-height: 40px;
  font-weight: bolder;
  color: ${get.colors('bluesuedeshoes')};
`

const DropdownItemContent: React.FC = ({ children }) => (
  <StyledDropdownItemContent tag="span" size="sm">
    {children}
  </StyledDropdownItemContent>
)

const DropdownLinkItem: React.FC<DropdownLinkItemProps> = ({
  $key,
  linkSchema,
  className,
}) => {
  const history = useHistory()
  const { content } = linkSchema
  const text = useMemo(() => onlyText(content), [content])
  return (
    <DropdownMenuDEPRECATED.MenuItemLink
      key={$key}
      href={linkSchema.url}
      content={<DropdownItemContent>{text}</DropdownItemContent>}
      withAllyTM={text}
      useRouterLink
      className={className}
      onSelection={(): void => history.push(linkSchema.url)}
      {...(linkSchema.metadata || {})}
    />
  )
}

const StyledDropdownLinkItem = styled(DropdownLinkItem)`
  padding-left: 16px;
`

const DropdownButtonItem: React.FC<DropdownButtonItemProps> = ({
  $key,
  buttonSchema,
  className,
}) => {
  const { content } = buttonSchema
  const text = useMemo(() => onlyText(content), [content])
  return (
    <DropdownMenuDEPRECATED.MenuItemButton
      key={$key}
      onSelection={buttonSchema.onClick}
      content={<DropdownItemContent>{text}</DropdownItemContent>}
      withAllyTM={text}
      className={className}
      {...(buttonSchema.metadata || {})}
    />
  )
}

const StyledDropdownButtonItem = styled(DropdownButtonItem)`
  padding-left: 16px;
  // override metronome SubMenu StyledMenu margin
  margin-left: 0 !important;
`

const SubMenuItems: React.FC<{ subMenuSchema: SubNavDropdownSchema }> = ({
  subMenuSchema,
}) => {
  return (
    <>
      {subMenuSchema.items.map(menuItem => {
        const key = generateKey(menuItem.key)

        switch (menuItem.type) {
          case 'link':
            return (
              <StyledDropdownLinkItem
                key={key}
                $key={key}
                linkSchema={menuItem}
              />
            )
          case 'button':
            return (
              <StyledDropdownButtonItem
                key={key}
                $key={key}
                buttonSchema={menuItem}
              />
            )
          case 'menu':
          default:
            return null
        }
      })}
    </>
  )
}

const DropdownSubMenu: React.FC<DropdownSubMenuProps> = ({
  $key,
  subMenuSchema,
}) => {
  const { triggerContent: content } = subMenuSchema
  const text = useMemo(() => onlyText(content), [content])
  return (
    <DropdownMenuDEPRECATED.SubMenu
      triggerContent={<DropdownItemContent>{text}</DropdownItemContent>}
      withAllyTM={text}
      disableCloseOnBlur
      ariaLabel={text}
    >
      <SubMenuItems subMenuSchema={subMenuSchema} />
    </DropdownMenuDEPRECATED.SubMenu>
  )
}

const MenuItems: React.FC<{ dropdownSchema: SubNavDropdownSchema }> = ({
  dropdownSchema,
}) => {
  return (
    <>
      {dropdownSchema.items.map(menuItem => {
        const key = generateKey(menuItem.key)

        switch (menuItem.type) {
          case 'link':
            return (
              <DropdownLinkItem key={key} $key={key} linkSchema={menuItem} />
            )
          case 'button':
            return (
              <DropdownButtonItem
                key={key}
                $key={key}
                buttonSchema={menuItem}
              />
            )
          case 'menu':
            return (
              <DropdownSubMenu key={key} $key={key} subMenuSchema={menuItem} />
            )
          default:
            return null
        }
      })}
    </>
  )
}

const StyledMenuContainer = styled(DropdownMenuDEPRECATED.MenuContainer)`
  white-space: nowrap;
`

const NoPaddingTrigger = styled(DropdownMenuDEPRECATED.Trigger)`
  padding: 0;
`

export const SubNavDropdown = React.forwardRef<
  HTMLLIElement,
  SubNavDropdownProps
>(({ dropdownSchema, alignSelf, ...others }, ref) => {
  const {
    triggerBoxRef,
    triggerIconRef,
    dropdownProps,
    onMenuToggle,
  } = useSubNavDropdown({ dropdownSchema })

  const { triggerContent } = dropdownSchema

  const name = typeof triggerContent === 'string' ? triggerContent : ''
  const menuName = name ? `${name} menu` : 'menu'
  const getAriaLabel = (isOpen: boolean): string =>
    isOpen ? `Close ${menuName}` : `Open ${menuName}`

  return (
    <SubNavListItem ref={ref} {...others}>
      <DropdownMenuDEPRECATED
        topPos={33}
        smallCaret
        onMenuToggle={onMenuToggle}
        alignSelf={alignSelf}
        {...dropdownProps}
      >
        <NoPaddingTrigger
          content={
            <DropdownTrigger
              content={triggerContent}
              boxRef={triggerBoxRef}
              iconRef={triggerIconRef}
              metadata={dropdownSchema.metadata}
            />
          }
          ariaLabel={getAriaLabel}
          variant="link"
          withAllyTM={name}
          asButton
        />
        <StyledMenuContainer>
          <MenuItems dropdownSchema={dropdownSchema} />
        </StyledMenuContainer>
      </DropdownMenuDEPRECATED>
    </SubNavListItem>
  )
})
