import { useEffect, useState } from 'react'
import { invokeForever, noop } from '@ally/utilitarian'
import { CallUsSchema, CallWaitTimeResponse } from './types'
import { apigeeAPIKey } from '../../constants'
import { useHostServices } from '../../HostServices'

export type WaitTimesMap = Record<string, number | null>

async function getCallWaitTimes(): Promise<WaitTimesMap> {
  try {
    const res = await fetch('/acs/v1/ewt', {
      headers: { 'api-key': apigeeAPIKey },
    })
    const json = (await res.json()) as CallWaitTimeResponse
    const { ewt } = json.bank.call
    // EWT value -1 indicates error in calculating EWT
    if (ewt < 0) return { bank: null }
    return { bank: ewt }
  } catch (e) {
    return { bank: null }
  }
}

export function useCallWaitTimes(schema: CallUsSchema[]): WaitTimesMap {
  const { featureFlags } = useHostServices()
  const [waitTimes, setWaitTimes] = useState<WaitTimesMap>({})
  const cwtEnabled = featureFlags.variation('call-wait-time', true)

  useEffect(() => {
    // If CWT is disabled set wait times to null (hides UI)
    if (!cwtEnabled) {
      setWaitTimes({ bank: null })
      return noop
    }

    return invokeForever(() => getCallWaitTimes().then(setWaitTimes), {
      delayMs: 30 * 1000,
    })
  }, [cwtEnabled])

  return schema.reduce(
    (acc, { id }) => ({
      ...acc,
      [id]: waitTimes[id] ?? null,
    }),
    {},
  )
}
