import React, {
  useCallback,
  useLayoutEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import styled from 'styled-components'
import { useMediaSize } from '@ally/metronome-ui'
import { SubNavSchema } from '@ally/federated-types'
import {
  SubNavButton,
  SubNavDropdown,
  SubNavLink,
  WhatsNewDropdown,
} from './SubNavItems'
import { useTotalElementsToHide } from './use-total-elements-to-hide'
import { generateKey } from './SubNavItems/utils'
import { useCoachmarks } from '../providers/coachmarks'

type SubNavMenuProps = {
  schema: SubNavSchema
}

type SubNavContentRef = HTMLLIElement | null

const StyledSubNavList = styled.ul.attrs(() => ({
  'data-testid': 'sub-nav-list',
}))`
  display: flex;
  list-style: none;
  padding: 0;
  align-items: center;
  width: 100%;
`

function filterSchema(schema: SubNavSchema, isMobile: boolean): SubNavSchema {
  if (!isMobile) return schema

  return schema.reduce((filtered, item) => {
    if (item.isHiddenMobile) return filtered
    if (item.type === 'menu')
      return [
        ...filtered,
        {
          ...item,
          items: filterSchema(item.items, isMobile),
        },
      ]
    return [...filtered, item]
  }, [] as SubNavSchema)
}

/**
 * Creates the Sub Nav Menu Bar based on the given SubNavSchema[]. Each item
 * will be rendered based on its corresponding `type`.
 *
 * @example
 * const schema = [
 *   {
 *     type: 'link',
 *     content: 'A Link',
 *     url: '/foo/bar',
 *     onClick?: () => {}
 *     metadata?: { ['analytics-prop']: 'analytics-value' }
 *   },
 *   {
 *     type: 'button',
 *     content: 'A Button',
 *     onClick: () => {}
 *     metadata?: { ['analytics-prop']: 'analytics-value' }
 *   },
 *   {
 *     type: 'menu',
 *     triggerContent: 'A dropdown',
 *     items: [
 *        { ...(button or link type) },
 *        { ...(button or link type) },
 *     ],
 *     metadata?: { ['analytics-prop']: 'analytics-value' },
 *   },
 * ]
 *
 * <SubNavMenu schema={schema} />
 *
 */
export const SubNavMenu: React.FC<SubNavMenuProps> = ({ schema }) => {
  const { coachmarks } = useCoachmarks()
  const isSmDown = useMediaSize('SmDown')
  const filteredSchema = React.useMemo(() => filterSchema(schema, isSmDown), [
    schema,
    isSmDown,
  ])

  const [elementWidths, setElementWidths] = useState<number[]>([])

  const subNavContainerRef = useRef<HTMLUListElement>(null)
  const subNavContentRef = useRef<Array<SubNavContentRef>>([])

  const snapToWidth =
    Math.ceil((subNavContainerRef.current?.offsetWidth || 10) / 10) * 10
  const totalElementsToHide = useTotalElementsToHide({
    // estimate what's new menu width if there are coachmarks to display
    elementWidths: [coachmarks.length ? 115 : 0, ...elementWidths],
    containerWidth: snapToWidth,
  })

  useLayoutEffect(() => {
    subNavContentRef.current = subNavContentRef.current.slice(
      0,
      filteredSchema.length,
    )
    const newElementWidths = subNavContentRef.current.map(
      (a: SubNavContentRef) => {
        return a?.offsetWidth ? a.offsetWidth + 30 : 100
      },
    )
    setElementWidths(newElementWidths)
  }, [filteredSchema, subNavContentRef])

  const handleItemRef = useCallback(
    (el, index): SubNavContentRef => (subNavContentRef.current[index] = el),
    [subNavContentRef],
  )

  const moreItems = useMemo(() => {
    return filteredSchema.slice(filteredSchema.length - totalElementsToHide)
  }, [filteredSchema, totalElementsToHide])

  const visibleItems = useMemo(
    () => filteredSchema.slice(0, filteredSchema.length - totalElementsToHide),
    [filteredSchema, totalElementsToHide],
  )

  return (
    <StyledSubNavList ref={subNavContainerRef}>
      {coachmarks.length > 0 && <WhatsNewDropdown coachmarks={coachmarks} />}
      {visibleItems.map((item, index) => {
        let key

        switch (item.type) {
          case 'link':
            key = generateKey(item.key)

            return (
              <SubNavLink
                key={key}
                $key={key}
                ref={(el): SubNavContentRef => handleItemRef(el, index)}
                linkSchema={item}
              />
            )
          case 'button':
            key = generateKey(item.key)

            return (
              <SubNavButton
                key={key}
                $key={key}
                ref={(el): SubNavContentRef => handleItemRef(el, index)}
                buttonSchema={item}
              />
            )
          case 'menu':
            key = generateKey(item.triggerContent)

            return (
              <SubNavDropdown
                key={key}
                $key={key}
                ref={(el): SubNavContentRef => handleItemRef(el, index)}
                dropdownSchema={item}
              />
            )
          default:
            return <></>
        }
      })}
      {totalElementsToHide > 0 && (
        <SubNavDropdown
          dropdownSchema={{
            type: 'menu',
            triggerContent: 'More',
            items: moreItems,
            key: 'sub-nav-more-menu',
          }}
          // TODO: We probably will change this interface in metronome-ui v19.
          // Make sure to update this if and when we do.
          alignSelf={isSmDown ? 'left' : 'auto'}
        />
      )}
    </StyledSubNavList>
  )
}
