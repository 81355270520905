import React, { FC, useMemo } from 'react'
import { deprecated } from '@ally/metronome-icons'
import { Box, TextBody, Icon, get, Link as MuiLink } from '@ally/metronome-ui'
import styled from 'styled-components'
import { MenuItem } from 'react-aria-menubutton'

import {
  bankNumbers,
  investNumbers,
  mortgageNumbers,
  creditCardNumbers,
  autoNumbers,
} from '../../constants'

export interface ErrorProps {
  accountType:
    | 'Investments'
    | 'Bank Accounts'
    | 'home loans'
    | 'credit card accounts'
    | 'vehicle accounts'
  inNavDrawer?: boolean
}

const Message: React.FC<ErrorProps> = ({ accountType }) => {
  const phoneNumber = useMemo(() => {
    switch (accountType) {
      case 'vehicle accounts':
        return autoNumbers
      case 'credit card accounts':
        return creditCardNumbers
      case 'home loans':
        return mortgageNumbers
      case 'Investments':
        return investNumbers
      case 'Bank Accounts':
      default:
        return bankNumbers
    }
  }, [accountType])
  const phoneHours = useMemo(() => {
    switch (accountType) {
      case 'home loans':
        return "We're here Mon - Fri, 8:30 am - 8 pm and Sat, 8:30 am - 1 pm ET"
      case 'vehicle accounts':
        return "We're here Mon - Fri, 8 am - 11 pm ET, and Sat 9 am - 7 pm ET."
      default:
        return "We're here 24/7."
    }
  }, [accountType])

  return (
    <Box textAlign="left" ml="xxs" mt="xxs">
      <TextBody tag="span" size="sm" weight="bold">
        We can&apos;t show your {accountType} right now.
      </TextBody>
      <TextBody tag="p" size="sm">
        Try again later, or call us at{' '}
        <MuiLink text={phoneNumber.insideUS} href={phoneNumber.telLink} /> for
        help. <br />
        {phoneHours}
      </TextBody>
    </Box>
  )
}

// Accordion caret fill color (white) overrides base fill from Icon
const ErrorIcon = styled(Icon)`
  flex-shrink: 0;
  &.error-icon path {
    fill: ${get.colors('error')};
  }
`

export const ErrorCardContents: React.FC<ErrorProps> = ({ accountType }) => {
  const isAutoAccounts = accountType === 'vehicle accounts'
  return (
    <Box
      display="flex"
      alignItems="center"
      py="sm"
      px="xs"
      borderTop="1px solid"
      borderTopColor="slate-2"
      width="100%"
    >
      <ErrorIcon
        icon={
          isAutoAccounts ? deprecated.muiErrorFill : deprecated.muiErrorOutline
        }
        fill="error"
        size="lg"
        mt="xxs"
        mr="xxs"
        customTitle="error"
        className="error-icon"
      />
      <Message accountType={accountType} />
    </Box>
  )
}

const MenuItemWrapper: FC = ({ children }) => (
  <MenuItem tag="div" tabIndex={0}>
    {children}
  </MenuItem>
)

const ErrorCard: React.FC<ErrorProps> = ({ accountType, inNavDrawer }) => {
  const Wrapper: React.FC = inNavDrawer ? Box : MenuItemWrapper
  return (
    <Wrapper>
      <ErrorCardContents accountType={accountType} />
    </Wrapper>
  )
}

export default ErrorCard
