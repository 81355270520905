import { CoachmarkContent, CoachmarkData } from '@ally/whats-new-content'

export type { CoachmarkContent, CoachmarkData }

export type Coachmark = {
  data: CoachmarkData
  content: CoachmarkContent
}
/**
 * Searches through the content and data arrays to find matching
 * coachmark names, then combines those into a single object and
 * pushes them onto the new array.
 */
export const combineCoachmarks = (
  coachmarkData: CoachmarkData[],
  enabledCoachmarks: CoachmarkContent[],
): Coachmark[] => {
  const combinedCoachmarks = []

  for (let i = 0; i < coachmarkData.length; i += 1) {
    const data = coachmarkData[i]

    const content = enabledCoachmarks.find(
      coachmark => coachmark.name === data.name,
    )

    if (content) combinedCoachmarks.push({ data, content })
  }

  return combinedCoachmarks
}

/**
 * Searches through the coachmarks array to find the coachmark that
 * was just updated, and replaces the old one with the updated one.
 */
export const updateExistingCoachmarks = (
  existingCoachmarks: Coachmark[],
  updatedCoachmark: Coachmark,
  updatedDataFromApi: CoachmarkData | null,
): Coachmark[] =>
  // if the server does not update the coachmark state, neither do we
  updatedDataFromApi
    ? existingCoachmarks.map(item =>
        item.data.id === updatedCoachmark.data.id
          ? {
              data: updatedDataFromApi,
              content: updatedCoachmark.content,
            }
          : item,
      )
    : existingCoachmarks
