import React, {
  useMemo,
  useState,
  Dispatch,
  ReactNode,
  useContext,
  createContext,
  SyntheticEvent,
  SetStateAction,
} from 'react'

import { noop } from '@ally/utilitarian'
import styled, { createGlobalStyle } from 'styled-components'

export interface OverlayProps {
  onClick?: (e: SyntheticEvent<HTMLDivElement, MouseEvent>) => void
  children?: ReactNode
  className?: string
  $elevation: OverlayElevation
  hideOverflow?: boolean
}

export type OverlayElevation = -1 | 0 | 1 | 2 | 3

export interface UseOverlayOptions
  extends Pick<OverlayProps, 'onClick' | 'hideOverflow'> {
  elevation: OverlayElevation
}

export interface OverlayActions {
  show: Dispatch<SetStateAction<UseOverlayOptions>>
  hide: VoidFunction
}

const OverlayGlobalStyles = createGlobalStyle<{ hidden: boolean }>(
  ({ hidden }) => `
    body {
      max-height: ${hidden ? '100vh' : 'unset'};
      overflow: ${hidden ? 'hidden !important' : 'auto'};
    }
    html {
      overflow-y: ${hidden ? 'scroll' : 'visible'};
    }
  `,
)

const Overlay = styled.div(({ $elevation }: OverlayProps) => {
  const isVisible = $elevation > -1
  const background = $elevation === 0 ? 'transparent' : 'rgba(0, 0, 0, 0.3)'

  return `
    align-items: center;
    display: flex;
    background-color: ${background};
    height: 100%;
    left: 0;
    justify-content: center;
    opacity: ${isVisible ? 1 : 0};
    position: fixed;
    top: 0;
    visibility: ${isVisible ? 'visible' : 'hidden'};
    width: 100%;
    z-index: ${isVisible ? $elevation : 0};
  `
})

const initialActions = {
  show: noop,
  hide: noop,
}

const initialState = {
  onClick: noop,
  elevation: -1 as OverlayElevation,
  hideOverflow: false,
}

export const OverlayContext = createContext<OverlayActions>(initialActions)
export const useOverlay = (): OverlayActions => useContext(OverlayContext)

/**
 * The Overlay Provider.
 * Renders the <Overlay /> component on the page if the current elevation is
 * > -1. This is a pretty dumb component... and overlay settings can be hijacked
 * by other components (you've been warned).
 *
 * Accepted Elevations:
 * -1 - Hidden, nothing displayed
 *  0 - Shows children, but no background displayed (transparent background).
 *  1 - Below the TopNav and SubNav, covering page content and the footer.
 *  2 - Below the TopNav, covering the SubNav and everything else in #1.
 *  3 - Covers everything on the page.
 *
 * @usage
 * const overlay = useOverlay()
 * useEffect(() => overlay.show({ elevation: 1 }), [])
 * return <div onClick={overlay.hide} />...</div>
 *
 * // With an onClick handler, and preventing content from overflowing the
 * // current viewport.
 * overlay.show({
 *   onClick: () => console.log('Overlay Clicked!'),
 *   elevation: 1,
 *   hideOverflow: true,
 * })
 */
export const OverlayProvider: React.FC = ({ children }) => {
  const [current, setState] = useState<UseOverlayOptions>(initialState)
  const { onClick = noop, elevation, hideOverflow } = current

  const value = useMemo(
    () => ({ show: setState, hide: (): void => setState(initialState) }),
    [],
  )

  return (
    <OverlayContext.Provider value={value}>
      {children}
      <Overlay
        id="darkened-overlay"
        onClick={onClick}
        $elevation={elevation}
        data-testid="darkened-overlay"
      />
      <OverlayGlobalStyles hidden={!!hideOverflow} />
    </OverlayContext.Provider>
  )
}
