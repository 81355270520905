import cookies from 'js-cookie'

import { Session } from '@ally/federated-types'
import { invokeWhile, mapValues } from '@ally/utilitarian'
import { getPrivateAttributes } from '@ally/private'
import { auto as autoSelectors } from '@ally/data-selectors-afg-accounts'
import { getDomainData as getCustomerDomainData } from '@ally/data-selectors-afg-customer'

import { HostData } from '@ally/data-types-host'
import log from '../../whisper'
import {
  SDE,
  LivePerson,
  LivePersonChatEntry,
  LivePersonHookPushEventOptions,
  LPIdentity,
} from '../../global-types'
import { isPathMatch } from '../../utils'
import { CHAT_SHARED_PAGES } from './constants'
import { getDefaultUserRole } from '../../hooks'

interface ChatDataSet {
  uri?: string
  controlled?: string
}

interface ChatNode extends Node {
  dataset: ChatDataSet
}

interface ChatNodeEventTarget extends EventTarget {
  dataset?: ChatDataSet
}

interface ChatNodeEvent extends Event {
  target: ChatNodeEventTarget | null
}

interface RichMediaAction {
  uri?: string
  type?: string
  target?: string
}

interface RichMediaContent {
  type: string
  click?: {
    actions?: RichMediaAction[]
  }
}

export type SetRedirect = (x: string) => void

export const getLivePerson = (): LivePerson | null => {
  const { lpTag } = window
  return lpTag && typeof lpTag.newPage === 'function' ? lpTag : null
}

export const getCampaignID = (): string => {
  const { _satellite: satellite } = window
  const value = satellite?.getVar?.('aam_lp_object')
  return value ? value.campaignId : cookies.get('aam_lp') ?? ''
}

export const assembleSDEs = (
  session: Session,
  hostData: HostData,
): SDE[] | null => {
  const { allyUserRole, cif, guid, aaosId } = session.data ?? {}
  const { auto, bank, creditcard, investment, mortgage, wealth } = mapValues(
    {
      ...getDefaultUserRole(),
      ...allyUserRole,
    },
    Number,
  )
  const userRolesString = `cc:${creditcard},wealth:${wealth},bank:${bank},mortgage:${mortgage},invest:${investment},aaos:${auto}`
  // Get auto-specific SDE data from AFG Accounts data
  const autoAccounts = hostData ? autoSelectors.getAll(hostData) : []
  const customerData = hostData ? getCustomerDomainData(hostData) : null
  const hasAuto: boolean = autoAccounts.length > 0
  const cstatus = hasAuto ? customerData?.data?.auto?.address?.state : undefined
  const code = hasAuto
    ? [
        ...new Set(
          autoAccounts
            .flatMap(account => account.meta?.liveChatAlertCodes)
            .filter(Boolean),
        ),
      ].toString()
    : undefined
  const age = hasAuto
    ? Math.max(
        ...autoAccounts.map(
          account => account.meta?.pastDuePayment?.daysPastDue || 0,
        ),
      )
    : undefined
  const autoSDEs: SDE[] = hasAuto
    ? [
        {
          type: 'personal',
          personal: {
            age: {
              age,
            },
          },
        },
        {
          type: 'error',
          error: {
            code,
          },
        },
      ]
    : []
  // Start of generic SDE info for all customers
  const allSDEs: SDE[] = [
    {
      type: 'service',
      service: {
        topic: 'ConversationStage',
        status: 0,
      },
    },
    {
      type: 'ctmrinfo',
      info: {
        ctype: 'aob',
        customerId: guid || aaosId || '',
        ...(cif && { socialId: cif.toString() }),
        storeNumber: userRolesString,
        cstatus, // Auto specific field. Will be undefined if no auto accounts exist
      },
    },
    {
      type: 'mrktInfo',
      info: {
        campaignId: getCampaignID(),
      },
    },
  ]
  return hasAuto ? allSDEs.concat(autoSDEs) : allSDEs
}

export function cloneAndReplaceDOMNode(node: ChatNode): ChatNode {
  const clone = node.cloneNode(true) as ChatNode
  node.parentNode?.replaceChild(clone, node)
  return clone
}

function findStructuredContent(
  type: string,
  content: LivePersonChatEntry,
): RichMediaContent | undefined {
  return content.json?.elements?.find?.(
    (element: { type: string }) => element.type === type,
  )
}

function findButtonContentURI(
  structuredContent: RichMediaContent,
): string | void {
  const buttonContentLinkAction = structuredContent.click?.actions?.find(
    (action: RichMediaAction) => action?.type === 'link',
  )

  return buttonContentLinkAction?.uri
}

export function checkForNewTabAction(
  structuredContent: RichMediaContent,
): boolean {
  const newTabAction = structuredContent.click?.actions?.find(
    (action: RichMediaAction) => action?.target === 'blank',
  )

  return !!newTabAction
}

/**
 * Given a button link's URI, will look for the latest chat line and find a
 * button. If the button exists (and isn't already "controlled"), it will be
 * "controlled". That is, it will be cloned and a click event listener will be
 * added that will set the redirect target and redirect the user to the button's
 * URI.
 */
export function handleButtonClickHijack(
  setRedirectUri: SetRedirect,
  entry: LivePersonChatEntry,
  buttonLinkURI: string,
): void {
  if (!entry.localId) return

  // Rich Media Chat Entry DOM Structure
  // <div id="lp_line_#">
  //   <div class="lp_rich_content_line">
  //     <img /> OR <button />
  const lineNode = document.querySelector(`#lp_line_${entry.localId}`)
  const richContentNode = lineNode?.querySelector('.lp_rich_content_line')
  const buttonLink =
    richContentNode?.querySelector('button') ||
    richContentNode?.querySelector('img')

  if (buttonLink && !buttonLink.dataset.controlled) {
    const newButtonLink = cloneAndReplaceDOMNode(buttonLink)
    newButtonLink.addEventListener('click', (event: ChatNodeEvent) => {
      const uri = event.target?.dataset?.uri
      return uri && setRedirectUri(uri)
    })

    newButtonLink.dataset.controlled = 'true'
    newButtonLink.dataset.uri = buttonLinkURI
  }
}

const testURIHost = (pattern: RegExp) => (uri: string): boolean => {
  try {
    return pattern.test(new URL(uri).host)
  } catch {
    return false
  }
}

const isSecureHost = testURIHost(/^secure(-.*)?\.ally\.com/)
const isAutoHost = testURIHost(/^secureauto(-.*)?\.ally\.com/)
const isInvestHost = testURIHost(/^live(-.*)?\.invest(-.*)?(.*)\.ally\.com/)

/**
 * Takes in a "Chat Entry" which represents a JSON configuration of a message
 * shown to the customer. This JSON can come in many formats so we "sanitize"
 * it to have a common "json" property.
 */
function sanitizeChatEntry(entry: LivePersonChatEntry): LivePersonChatEntry {
  if (entry.json) return entry // Entry already has a "json" property
  if (!entry.text) return entry // Entry does not have a "text" property we can convert
  // Use entry's "text" property
  if (typeof entry.text === 'string') {
    return {
      ...entry,
      json: JSON.parse(entry.text),
    }
  }
  return {
    ...entry,
    json: entry.text,
  }
}

function isRichMediaEntry(entry: LivePersonChatEntry): boolean {
  return entry.textType === 'rich-content' || entry.type === 'richContent'
}

/**
 * The callback that's invoked by LivePerson each time a new chat line is
 * entered. This will lookup "rich media content" (like a link) and find the
 * an associated button. If found, a click handler will be added to the button
 * that sets the internal redirect state when clicked (and therefore redirecting
 * the user to the url).
 */
function getLPInsertionHookCallback(setRedirect: SetRedirect) {
  return (options: LivePersonHookPushEventOptions): void => {
    const { lines = [] } = options?.data ?? {}
    const richMediaLines = lines.filter(isRichMediaEntry).map(sanitizeChatEntry)

    richMediaLines.forEach(richMediaLine => {
      const structuredContent = findStructuredContent('image', richMediaLine)

      if (!structuredContent) return

      const hasNewTabAction = checkForNewTabAction(structuredContent)

      if (hasNewTabAction) return

      const buttonLinkURI = findButtonContentURI(structuredContent)

      if (!buttonLinkURI) return

      if (isSecureHost(buttonLinkURI)) {
        const baseUrl = new URL(buttonLinkURI).origin
        const relativeURI = buttonLinkURI.replace(baseUrl, '')
        setTimeout(
          () =>
            handleButtonClickHijack(setRedirect, richMediaLine, relativeURI),
          200,
        )
      } else if (isAutoHost(buttonLinkURI)) {
        const baseUrl = new URL(buttonLinkURI).origin
        const autoSSOPath = buttonLinkURI
          .replace(baseUrl, '')
          .replace(/^\/#(\/auto)?/, '/sso/auto')
        setTimeout(
          () =>
            handleButtonClickHijack(setRedirect, richMediaLine, autoSSOPath),
          200,
        )
      } else if (isInvestHost(buttonLinkURI)) {
        const baseUrl = new URL(buttonLinkURI).origin
        const investSSOPath = buttonLinkURI.replace(baseUrl, '/sso/invest')
        setTimeout(
          () =>
            handleButtonClickHijack(setRedirect, richMediaLine, investSSOPath),
          200,
        )
      }
    })
  }
}

/**
 * Adds a hook through the `window.lpTag.hooks` API that is called after each
 * new chat message (line) is submitted in the active chat window.
 *
 * This allows us to capture rich-media content, which could potentially contain
 * links within the application, and ensure they navigate the user through the
 * proper SPA router methods vs. reloading the application (the default
 * LivePerson action).
 *
 * This is achieved by relying on the lpTag API to call our hook callback when a
 * new message is added to the chat. We use this callback to query the DOM
 * within the chat window and, if a rich-media message is submitted, swap out
 * the default button link element for our own, cloned button with the proper
 * event listener attached to it. We only do this for rich-media content that is
 * linking to a URI within the application and same origin, otherwise the
 * default LivePerson action will remain.
 */
function injectLivePersonInsertionHook(
  livePerson: LivePerson,
  setRedirect: SetRedirect,
): void {
  log.info({
    message: 'Registering LivePerson insertion hook...',
  })

  try {
    livePerson.hooks.push({
      name: 'AFTER_GET_LINES',
      callback: getLPInsertionHookCallback(setRedirect),
    })
  } catch (e) {
    log.error({
      message: [
        'Failure attempting to add LivePerson hooks:',
        (e as Error).stack,
      ],
    })
  }
}

/**
 * Waits for `window.lpTag` to be available (retrying every second, for up to 30
 * seconds). If/when available, injects a hook using `window.lpTag.hooks.push`
 * that will redirect the user to the given page when the associated button in
 * the chat window is clicked (as described in the comment above).
 */
export async function delayUntilLivePersonReady(
  setRedirect: SetRedirect,
): Promise<LivePerson> {
  const isLivePersonPending = await invokeWhile(() => !getLivePerson(), {
    delayMs: 1000,
    maxAttempts: 30,
  })

  if (!isLivePersonPending) {
    const livePerson = getLivePerson() as LivePerson
    injectLivePersonInsertionHook(livePerson, setRedirect)
    return livePerson
  }

  throw new Error('Missing/malformed LivePerson Tag')
}

export function getIdentity(
  chatId: string | undefined | null,
): LPIdentity | null {
  if (chatId) {
    return {
      iss: 'https://www.ally.com',
      sub: chatId,
      acr: 'loa1',
    }
  }
  return null
}

/**
 * Add the private data attributes to the #lpChat element to prevent PII in logging tools
 */
export function chatPII(): void {
  const privateAttributes: Record<string, string> = getPrivateAttributes()
  Object.keys(privateAttributes).forEach(key =>
    (document.getElementById('lpChat') as HTMLElement).setAttribute(
      key,
      privateAttributes[key],
    ),
  )
}

/**
 * Returns whether or not the given location is considered a "shared" page
 * for chat purposes.This is currently being declared in the Host app since
 * this is where Chat code currently is centralized but ideally this will
 * be abstracted when we can find a way to determine "shared pages" dynamically.
 * See ticket: ANT-860
 */
export const isSharedPage = isPathMatch(CHAT_SHARED_PAGES)
