/* eslint-disable react/jsx-props-no-spreading */

import React from 'react'
import styled from 'styled-components'
import { deprecated } from '@ally/metronome-icons'
import { Box, get, Icon, TextBody, Space } from '@ally/metronome-ui'

const StyledBox = styled(Box)<{ $showBadge: boolean }>`
  ${({ $showBadge, theme: { colors, fontSizes } }): string => `
    cursor: pointer;
    position: relative;
    ${
      $showBadge
        ? `::before {
      color: ${colors.error};
      font-size: ${fontSizes.xs};
      content: '';
      background-color: ${colors.error};
      position: absolute;
      left: -5px;
      top: 2px;
      width: 8px;
      border-radius: 50%;
      height: 8px;`
        : ''
    }
    }
  `}
`

const WhiteTextBody = styled(TextBody)`
  color: ${get.colors('white')};
`

const DropdownTrigger: React.FC<{
  content: React.ReactNode
  boxRef: React.RefObject<HTMLDivElement> | null
  iconRef: React.RefObject<SVGSVGElement> | null
  metadata?: Record<string, string>
  showBadge?: boolean
}> = ({ content, boxRef, iconRef, metadata, showBadge }) => {
  return (
    <StyledBox
      as="div"
      display="flex"
      alignItems="center"
      $showBadge={!!showBadge}
      ref={boxRef}
    >
      <Box display="flex" flex="0 0 auto" alignItems="center">
        <WhiteTextBody
          tag="span"
          size="sm"
          weight="bold"
          data-track-trigger="subdrawer"
          {...(metadata || {})}
        >
          {content}
        </WhiteTextBody>
        <Space mt="xxs">
          <Icon
            ref={iconRef}
            icon={deprecated.muiChevronDown}
            size="xs"
            fill="white"
            ml="xxs"
            ariaHidden
          />
        </Space>
      </Box>
    </StyledBox>
  )
}

export { DropdownTrigger }
