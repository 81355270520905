import {
  AfgAccountsDomain,
  AfgCustomerDomain,
  BadWordsDomain,
  CustomizationDomain,
  NotificationsDomain,
  RestrictedDatesDomain,
} from '@ally/data-types-host'
import { SessionData } from '@ally/federated-types'
import { FetchErrorPayload, handleResponse } from '@ally/fido'
import { DomainFetch } from '@ally/use-data-domain'
import { apigeeAPIKey } from '../constants'

type Headers = Record<string, string>
type AuthHeadersFn = (sessionData: SessionData | null) => Headers

export const defaultHeaders: Record<string, string> = {
  Accept: 'application/json',
  ApplicationId: 'ALLYUSBOLB',
  ApplicationName: 'AOB',
  ApplicationVersion: '1.0',
  'api-key': apigeeAPIKey,
  'Content-Type': 'application/json',
}

export function fetchWithAuthHeaders(getHeaders: AuthHeadersFn) {
  return <ResponseType extends FetchErrorPayload>(
    url: string,
    opts?: RequestInit,
  ) => {
    return async (
      sessionData: SessionData | null,
    ): Promise<DomainFetch<ResponseType>> => {
      const headers = {
        ...defaultHeaders,
        ...getHeaders(sessionData),
        ...(opts && opts?.headers),
      }

      const response = await fetch(url, {
        ...opts,
        headers,
      })
      const payload = await handleResponse<ResponseType>(response)

      return {
        response,
        payload,
      }
    }
  }
}

const getLegacyAuthHeaders: AuthHeadersFn = sessionData => ({
  ...(sessionData?.AwsAccessToken && {
    Authorization: `Bearer ${sessionData?.AwsAccessToken}`,
  }),
  ...(sessionData?.CSRFChallengeToken && {
    CSRFChallengeToken: sessionData?.CSRFChallengeToken,
  }),
})

export const fetchWithLegacyAuth = fetchWithAuthHeaders(getLegacyAuthHeaders)

const getTransmitAuthHeaders: AuthHeadersFn = sessionData => ({
  ...(sessionData?.access_token && {
    Authorization: `Bearer ${sessionData?.access_token}`,
  }),
})

export const fetchWithTransmitAuth = fetchWithAuthHeaders(
  getTransmitAuthHeaders,
)

/**
 * Given session data this will call the AFG Customer API and return the response
 */
export const fetchAfgCustomer = fetchWithTransmitAuth<AfgCustomerDomain>(
  '/acs/v3/customers/self?addFields=etin',
)

/**
 * Given the version of the AFG Accounts API to use, this will return a new function that takes in session data and will call the API and return the response
 */
export const fetchAfgAccounts = (version: number) => (
  sessionData: SessionData | null,
): Promise<DomainFetch<AfgAccountsDomain>> => {
  const { guid, allyUserRole, tpn } = sessionData ?? {}
  const id = guid || tpn // guid can be an empty string
  const url = `/acs/v${version}/customers/${id}/accounts`

  const headers = {
    ...(allyUserRole && { allyuserrole: JSON.stringify(allyUserRole) }),
    ...(tpn && { AutoThirdPartyNumber: tpn }),
  }

  const fetcher = fetchWithTransmitAuth<AfgAccountsDomain>(url, {
    headers,
  })

  return fetcher(sessionData)
}

/**
 * Given the version of the Customization API to use, this will return a new function that takes in session data and will call the API and return the response
 */
export const fetchCustomization = (version: number) => (
  sessionData: SessionData | null,
): Promise<DomainFetch<CustomizationDomain & FetchErrorPayload>> => {
  const { guid } = sessionData ?? {}
  const url = `/acs/v${version}/customers/${guid}/customizations`

  const fetcher = fetchWithTransmitAuth<
    CustomizationDomain & FetchErrorPayload
  >(url)

  return fetcher(sessionData)
}

export const fetchNotifications = (
  sessionData: SessionData | null,
): Promise<DomainFetch<NotificationsDomain>> => {
  const { guid, tpn, aaosId } = sessionData ?? {}

  const queryObj = {
    ...(guid && { customerId: guid }),
    ...(tpn && { tpn }),
    ...(aaosId && { profileId: aaosId }),
    channel: 'AOS',
  }

  const queryParams = new URLSearchParams(queryObj)
  const url = `/acs/v1/notifications?${queryParams.toString()}`
  const fetcher = fetchWithTransmitAuth<NotificationsDomain>(url)

  return fetcher(sessionData)
}

interface CPRAData {
  optIn: 'Y' | 'N'
  deviceIds: string[]
}

interface PrivacyPreferences {
  sessionId: string
  cpra: CPRAData
}

export const fetchPrivacyPreferences = (
  sessionData: SessionData | null,
  query?: any,
): Promise<DomainFetch<PrivacyPreferences & FetchErrorPayload>> => {
  const { guid, tpn } = sessionData ?? {}

  const queryObj = {
    ...(guid && { customerId: guid }),
    ...(tpn && { tpn }),
    ...query,
  }

  const queryParams = new URLSearchParams(queryObj)
  const url = `/acs/v1/preferences/privacy?${queryParams.toString()}`
  const fetcher = fetchWithTransmitAuth<PrivacyPreferences & FetchErrorPayload>(
    url,
  )

  return fetcher(sessionData)
}

export const updateCpraPreference = (
  sessionData: SessionData | null,
  optIn: 'Y' | 'N',
  sessionId: string,
): Promise<DomainFetch<PrivacyPreferences & FetchErrorPayload>> => {
  const { guid, tpn } = sessionData ?? {}

  const queryObj = {
    ...(guid && { customerId: guid }),
    ...(tpn && { tpn }),
  }

  const queryParams = new URLSearchParams(queryObj)
  const url = `/acs/v1/preferences/privacy?${queryParams.toString()}`
  const fetcher = fetchWithTransmitAuth<PrivacyPreferences & FetchErrorPayload>(
    url,
    {
      method: 'PUT',
      body: JSON.stringify({
        sessionId,
        cpra: {
          optIn,
          deviceIds: ['unused'],
        },
      }),
    },
  )

  return fetcher(sessionData)
}

export const fetchBadWords = async (): Promise<DomainFetch<BadWordsDomain>> => {
  const response = await fetch(`/assets/json/invalid-strings.json`)
  const payload = await response.json()

  return {
    response,
    payload,
  }
}

export const fetchRestrictedDates = async (): Promise<
  DomainFetch<RestrictedDatesDomain>
> => {
  const response = await fetch(`/assets/json/RestrictedDatesNotForced.json`)
  const payload = await response.json()

  return {
    response,
    payload,
  }
}
