/* eslint-disable react/jsx-props-no-spreading */

import React, { useState, useMemo, useRef, useCallback } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import {
  Box,
  DropdownMenuDEPRECATED,
  useDropdownContextDEPRECATED,
} from '@ally/metronome-ui'
import { applicationMaxWidth } from '@ally/next-layout'
import { CoachmarkList } from './CoachmarkList'
import { SubNavListItem } from '../../SubNavListItem'
import { DropdownTrigger } from '../DropdownTrigger'
import { useSubNavDropdown } from '../use-sub-nav-dropdown'
import { useCoachmarks, Coachmark } from '../../../../providers'
import { dropdownTriggerAriaLabel } from '../../../../utils'
import { useHostServices } from '../../../../HostServices'

interface WhatsNewDropdownProps {
  coachmarks: Coachmark[]
}

const NoPaddingTrigger = styled(DropdownMenuDEPRECATED.Trigger)`
  padding: 0;
`

export const WhatsNewDropdown = React.forwardRef<
  HTMLLIElement,
  WhatsNewDropdownProps
>(({ coachmarks }, ref) => {
  const { hostData, utils } = useHostServices()

  const history = useHistory()

  const [detailViewId, setDetailViewId] = useState<number | string | null>(null)

  const menuContainerRef = useRef<HTMLDivElement>(null)

  const {
    openMenuOnMount,
    onCoachmarkInteraction,
    onCoachmarkCompleted,
  } = useCoachmarks()

  const context = useDropdownContextDEPRECATED()

  const {
    triggerBoxRef,
    triggerIconRef,
    dropdownProps,
    onMenuToggle,
  } = useSubNavDropdown({ hideOverflow: false })

  const onMenuToggleWrapper = useCallback(
    async (open: boolean) => {
      onMenuToggle(open)
      if (!open) {
        await onCoachmarkInteraction(detailViewId)
        setDetailViewId(null)
      }
    },
    [detailViewId, onMenuToggle, onCoachmarkInteraction, setDetailViewId],
  )

  const selectedCoachmark = useMemo(
    () => coachmarks.find(item => item.data.id === detailViewId),
    [coachmarks, detailViewId],
  )

  const DetailViewComponent = selectedCoachmark?.content.detailView?.Component

  const unreadCount = useMemo(
    () => coachmarks.filter(coachmark => !coachmark.data.completed).length || 0,
    [coachmarks],
  )

  const scrollbarWidth = window.innerWidth - document.body.clientWidth

  return (
    <SubNavListItem ref={ref}>
      <DropdownMenuDEPRECATED
        topPos={33}
        smallCaret
        onMenuToggle={onMenuToggleWrapper}
        context={context}
        showCloseButton
        openOnMount={openMenuOnMount}
        {...dropdownProps}
      >
        <NoPaddingTrigger
          ariaLabel={(isOpen: boolean): string =>
            dropdownTriggerAriaLabel({
              menuName: "What's New",
              unreadCount,
              isOpen,
            })
          }
          content={
            <DropdownTrigger
              content="What's New"
              boxRef={triggerBoxRef}
              iconRef={triggerIconRef}
              metadata={{ 'data-track-trigger': 'whats-new-menu' }}
              showBadge={unreadCount > 0}
            />
          }
          variant="link"
          withAllyTM="whatsNew"
          asButton
        />
        <DropdownMenuDEPRECATED.MenuContainer>
          <Box
            ref={menuContainerRef}
            tabIndex={-1}
            minWidth="100%"
            maxWidth="100%"
            minHeight="100%"
            width={
              detailViewId
                ? `min(calc(100vw - 48px - ${scrollbarWidth}px), ${applicationMaxWidth.px})`
                : `min(calc(100vw - 48px - ${scrollbarWidth}px), 750px)`
            }
            p="md"
          >
            {detailViewId === null && (
              <CoachmarkList
                coachmarks={coachmarks}
                onCtaLinkClick={async (coachmark: Coachmark): Promise<void> => {
                  const {
                    content: {
                      summary: { ctaLink },
                      detailView,
                    },
                  } = coachmark

                  if (ctaLink.url) {
                    await onCoachmarkCompleted(coachmark.data.id)
                    history.push(ctaLink.url)
                  } else if (detailView !== undefined) {
                    // maintains focus inside of the menu during this transition
                    // we have to do this to ensure that the menu doesn't close on blur
                    menuContainerRef.current?.focus()
                    onCoachmarkInteraction(coachmark.data.id)
                    setDetailViewId(coachmark.data.id)
                  } else {
                    // No url or detail view, just complete coachmark
                    await onCoachmarkCompleted(coachmark.data.id)
                    context.closeMenu()
                  }
                }}
              />
            )}
            {!!selectedCoachmark && !!DetailViewComponent && (
              <DetailViewComponent
                utils={utils}
                hostData={hostData}
                coachmarkData={selectedCoachmark.data}
                onCtaLinkClicked={async (
                  redirectUrl?: string,
                ): Promise<void> => {
                  await onCoachmarkCompleted(selectedCoachmark.data.id)
                  if (redirectUrl) history.push(redirectUrl)
                  context.closeMenu()
                }}
                onCloseDropdown={(): void => {
                  context.closeMenu()
                }}
              />
            )}
          </Box>
        </DropdownMenuDEPRECATED.MenuContainer>
      </DropdownMenuDEPRECATED>
    </SubNavListItem>
  )
})
